import {ScheduleViewModel} from "@viewModels/Schedule.viewModel";
import {InvitationCounterApiResponse} from "@resources/InvitationCounter.apiResponse";
import {InvitationLockerApiResponse} from "@resources/InvitationLocker.apiResponse";

export class InvitationLockerViewModel {
  id: string;
  name: string;
  schedule: Array<ScheduleViewModel>;

  constructor(invitationLockerApiResponse: InvitationLockerApiResponse) {
    this.id = invitationLockerApiResponse.id;
    this.name = invitationLockerApiResponse.name;
    this.schedule = invitationLockerApiResponse.schedule.map(
      scheduleApiResponse => new ScheduleViewModel(scheduleApiResponse),
    );
  }
}
