import {theme} from '@config/Theme';
import {getDefaultBoxShadowStyle} from '@infrastructure/Utils';
import {
  normalizeFont,
  normalizeHorizontal,
  normalizeVertical,
} from '@utils/normalize';
import {StyleSheet} from 'react-native';

const {
  uikit: {colors},
} = theme;

export const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: normalizeHorizontal(12),
    width: '100%',
    ...getDefaultBoxShadowStyle(),
  },
  headerContainer: {
    paddingHorizontal: normalizeHorizontal(16),
    paddingBottom: normalizeVertical(14),
  },
  divider: {
    borderTopColor: colors.coolGray200,
    borderTopWidth: 1,
    marginBottom: normalizeVertical(8),
  },
  contentContainer: {
    paddingHorizontal: normalizeHorizontal(8),
  },
});
