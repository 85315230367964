import React, {useCallback, useEffect, useState} from 'react';
import {ReceiveDeliveryResource} from './resources/ReceiveDelivery.resource';
import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';
import {ProgressBar} from 'react-native-paper';
import {useEvent} from '@events/hooks/useEvent';
import {EventTypes} from '@events/Event.types';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {ParcelStatus} from '@config/Parcel.status';
import {ParcelApiResponse} from '@resources/Parcel.apiResponse';
import {useNavigation} from '@react-navigation/native';
import {QrStep} from '@modules/recipient/features/receive-delivery/components/QrStep';
import {ParcelListValidation} from '@modules/recipient/features/receive-delivery/components/ParcelListValidation';

interface Props {
  id: string;
  back: () => void;
}

export const ReceiveDeliveryFeature = ({id}: Props) => {
  const navigation = useNavigation();
  const {t} = useTranslation('recipient');
  const [loading, setLoading] = useState<boolean>(true);
  const [groupId, setGroupId] = useState<string>('');
  const [parcels, setParcels] = useState<ParcelViewModel[]>([]);
  const [step, setStep] = useState<number>(0);
  const [force, setForce] = useState<boolean>(false);

  const refresh = useCallback(() => {
    if (groupId === '') {
      return;
    }
    ReceiveDeliveryResource.list(groupId)
      .then(listRes => {
        if (
          listRes.data.items.every(p => p.status === ParcelStatus.Collected)
        ) {
          // @ts-ignore
          navigation.navigate('Confirm', {groupId});
          return;
        }
        setParcels(
          listRes.data.items.map(
            (p: ParcelApiResponse) => new ParcelViewModel(p),
          ),
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, [groupId]);

  useEvent(EventTypes.PARCEL.CONFIRM_PARCELS, () => {
    setStep(1);
  });
  useEvent(EventTypes.PARCEL.COLLECT, (data: any) => {
    const newParcels = parcels.map(p => {
      if (p.id === data.parcelId) {
        p.status = ParcelStatus.Collected;
      }
      return p;
    });
    if (newParcels.every(p => p.status === ParcelStatus.Collected)) {
      // @ts-ignore
      navigation.navigate('Confirm', {groupId});
      return;
    }
    setParcels(newParcels);
  });
  useEffect(() => {
    ReceiveDeliveryResource.get(id)
      .then((res: AxiosResponse<{groupId: string}>) => {
        setGroupId(res.data.groupId);
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    refresh();
  }, [groupId, refresh]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (step === 0) {
      timer = setTimeout(() => {
        setForce(true);
      }, 30000);
    }
    if (step === 1) {
      timer = setInterval(() => {
        refresh();
      }, 20000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [refresh, step]);

  if (loading) {
    return <ProgressBar indeterminate />;
  }

  return (
    <>
      {step === 0 ? (
        <QrStep
          value={groupId}
          subtitle={t('receiveDelivery_page_subtitle')!}
          force={force}
          onForce={() => {
            setForce(false);
            setStep(1);
            refresh();
          }}
        />
      ) : (
        <ParcelListValidation loading={loading} data={parcels} />
      )}
    </>
  );
};
