import {Text} from 'react-native-paper';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';
import React from 'react';
import {ParcelItem} from "@components/ParcelItem";
import {ParcelStatus} from "@config/Parcel.status";

interface Props {
  parcelsCount: number;
}

export const InvitationParcelsFeature = ({parcelsCount}: Props) => {
  const {t} = useTranslation('invitation');
  const text = `${parcelsCount} ${t('invitationParcels_ean_unit')}`;

  return (
    <>
      <Text style={styles.title}>
        {t('invitationParcels_title')}
      </Text>
      <View style={styles.container}>
        <ParcelItem
          ean={text}
          status={ParcelStatus.DroppedOff}
        />
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    gap: 15, display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
  },
  title: {
    padding: 20,
    fontSize: 24,
    fontWeight: '600',
    fontFamily: 'Poppins-Regular'
  }
});
