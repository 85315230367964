import {StyleSheet, View} from 'react-native';
import {LoginFeature} from '../features/login/Login.feature';
import {useTranslation} from 'react-i18next';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {AuthStackParamList} from '@modules/auth/Auth.stack';
import React from 'react';
import {theme} from '@config/Theme';

type Props = NativeStackScreenProps<AuthStackParamList, 'LOGIN'>;
export const LoginPage = ({}: Props) => {
  const {t} = useTranslation('auth');
  return (
    <>
      <View style={styles.container}>
        <LoginFeature />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.background,
  },
});
