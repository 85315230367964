import {useEffect, useState} from 'react';
import {useGeoLocation} from './GeoLocation.context';
import Geolocation from '@react-native-community/geolocation';

const useGeoLocationTracker = () => {
  const {updateCoordinates} = useGeoLocation();
  const [permissionGranted, setPermissionGranted] = useState(true);

  /*useEffect(() => {
    Geolocation.requestAuthorization(
      () => {
        setPermissionGranted(true);
      },
      error => {
        console.error('Error getting location:', error);
        setPermissionGranted(false);
      },
    );
  }, []);*/

  useEffect(() => {
    if (!permissionGranted) {
      return;
    }
    const watchId = Geolocation.watchPosition(
      position => {
        const {latitude, longitude} = position.coords;
        updateCoordinates(latitude, longitude);
      },
      error => {
        console.error('Error getting location:', error);
      },
      {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
    );

    return () => {
      Geolocation.clearWatch(watchId);
    };
  }, [permissionGranted, updateCoordinates]);

  useEffect(() => {
    if (!permissionGranted) {
      return;
    }

    Geolocation.getCurrentPosition(
      position => {
        const {latitude, longitude} = position.coords;
        updateCoordinates(latitude, longitude);
      },
      error => {
        console.error('Error getting location:', error);
      },
      {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
    );
  }, [updateCoordinates, permissionGranted]);
};

export default useGeoLocationTracker;
