import { Image, StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {Card} from '../Card';
import {TodaySchedule} from '@components/TodaySchedule';
import {Distance} from '@components/Distance';
import {useKpMainImage} from '@components/useKPMainImage';
import {ParcelActionPanel} from '@components/ParcelActionPanel';
import React from 'react';
import {CurrentKanguroPointViewModel} from "@viewModels/CurrentKanguroPoint.viewModel";
import { normalize } from '@utils/normalize';

interface Props {
  onPress: (delivery: boolean | void) => void;
  kanguroPoint: CurrentKanguroPointViewModel;
  type: 'recipient' | 'deliveryDriver' | 'toDeliver';
}

export const KanguroPointCard = ({kanguroPoint, onPress, type}: Props) => {
  const image = useKpMainImage(kanguroPoint);

  if (!kanguroPoint) {
    return null;
  }

  return (
    <Card extraStyle={styles.card}>
      <Image key={1} source={image} style={styles.image} />
      <View>
        <View style={{backgroundColor: 'white'}}>
          <View style={styles.counterName}>
            <Text
              style={{paddingHorizontal: 10, fontSize: 14}}
              variant="headlineSmall">
              {kanguroPoint.counter
                ? kanguroPoint.counter.name
                : kanguroPoint.locker?.name}{' '}
            </Text>
          </View>
          <View style={styles.rowItems}>
            <TodaySchedule schedule={kanguroPoint.counter?.schedule} />
          </View>
          <View style={[styles.rowItems, { marginTop: normalize(12)}]}>
            <Distance distance={kanguroPoint.distanceObject} />
          </View>
        </View>
        <View style={styles.box}>
          <ParcelActionPanel
            parcel={kanguroPoint}
            onPress={delivery => onPress(delivery)}
          />
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    gap: 5,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  rowItems: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 5,
  },
  box: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
  },
  boxItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 16,
    width: '100%',
  },
  image: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    minHeight: 175,
    width: '100%',
    resizeMode: 'stretch',
  },
  counterInfo: {
    paddingHorizontal: 10,
    fontSize: normalize(16)
  },
  counterName: { paddingVertical: normalize(4) }
});

