import {styles} from '@modules/auth/features/login/Login.styles';
import {Portal, Text} from 'react-native-paper';
import {TouchableOpacity, useWindowDimensions, View} from 'react-native';
import {
  CountryButton,
  countryCodes,
  CountryItem,
  CountryPicker,
} from 'react-native-country-codes-picker';
import {Key, useMemo, useState} from 'react';
import extractOpacity from "react-native-svg/lib/typescript/lib/extract/extractOpacity";

interface Props {
  onChange: (code: string) => void;
  value: string;
  disabled?: boolean;
}

export const CustomCountryCode = ({onChange, value, disabled = false}: Props) => {
  const {height} = useWindowDimensions();
  const [show, setShow] = useState(false);

  const flag = useMemo(() => {
    const country = countryCodes.find(item => item.dial_code === value);
    return country?.flag;
  }, [value]);

  const shouldShow = () => {
    if (!disabled) {
      setShow(true);
    }
  };

  return (
    <>
      <TouchableOpacity style={[styles.flag, {opacity: disabled ? 0.5 : 1 }]} onPress={() => setShow(!disabled)}>
        <Text>
          {flag}
          {value}
        </Text>
      </TouchableOpacity>
      <Portal>
        <CountryPicker
          show={show}
          inputPlaceholderTextColor="black"
          lang="es"
          // when picker button press you will get the country object with dial code
          pickerButtonOnPress={item => {
            onChange(item.dial_code);
            setShow(false);
          }}
          enableModalAvoiding
          inputPlaceholder="Busca tu extensión"
          searchMessage="No se encontraron resultados"
          ListHeaderComponent={ListHeaderComponent}
          popularCountries={['es', 'EN', 'hl']}
          onBackdropPress={() => setShow(false)}
          style={{
            // Styles for whole modal [View]
            modal: {
              height: height / 2,
            },
            // Styles for modal backdrop [View]
            backdrop: {},
            // Styles for bottom input line [View]
            line: {},
            // Styles for list of countries [FlatList]
            itemsList: {},
            // Styles for input [TextInput]
            textInput: {
              height: 50,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              paddingLeft: 16,
              color: 'black',
            },
            // Styles for country button [TouchableOpacity]
            countryButtonStyles: {
              height: 50,
            },
            // Styles for search message [Text]
            searchMessageText: {},
            // Styles for search message container [View]
            countryMessageContainer: {},
            // Flag styles [Text]
            flag: {},
            // Dial code styles [Text]
            dialCode: {
              color: 'black',
            },
            // Country name styles [Text]
            countryName: {
              color: 'black',
            },
          }}
        />
      </Portal>
    </>
  );
};

// @ts-ignore
function ListHeaderComponent({countries, lang, onPress}) {
  return (
    <View>
      {countries?.map((country: CountryItem, index: Key | null | undefined) => {
        return (
          <CountryButton
            key={index}
            item={country}
            name={country?.name?.[lang || 'en']}
            onPress={() => onPress(country)}
            style={{
              dialCode: {
                color: 'black',
              },
              countryName: {
                color: 'black',
              },
            }}
          />
        );
      })}
    </View>
  );
}
