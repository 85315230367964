import {StyleSheet} from 'react-native';
import {theme} from '@config/Theme';

export const styles = StyleSheet.create({
  textlink: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  entry: {
    marginRight: 4,
    transform: [{translateY: -3}],
    flex: 1,
    height: '100%',
  },
  entryInput: {
    borderColor: theme.colors.gray,
    borderWidth: 1,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid',
  },
  pre: {
    // width: '30%',
  },
  flag: {
    minWidth: '25%',
    borderColor: 'transparent',
    borderWidth: 1,
    borderRightWidth: 0,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  elipse: {
    position: 'absolute',
  },
  error: {
    color: 'red',
    alignSelf: 'center',
    marginTop: 10,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 200,
  },
  keyboard: {
    justifyContent: 'space-between',
    flex: 1,
  },
  box: {
    justifyContent: 'space-between',
    flex: 1,
  },
  form: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    marginBottom: 50,
  },
});
