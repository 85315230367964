import {StyleSheet} from 'react-native';
import {CheckboxProps} from 'react-native-paper';

export interface ICheckBoxProps extends CheckboxProps {
  defaultState?: CheckBoxStatus;
  onValue: (data: {[key: string]: boolean}) => void;
  label: string;
  name: string;
}

export type CheckBoxStatus = 'unchecked' | 'checked';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
