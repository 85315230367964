import React from 'react';
import {
  Animated,
  ImageBackground,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import {IconButton} from 'react-native-paper';

const CustomHeader = ({title, scrollY, navigation, image}: any) => {
  if (scrollY.interpolate === undefined) {
    return null;
  }
  const {width} = useWindowDimensions();
  // Define the animation configuration
  const headerHeight = scrollY.interpolate({
    inputRange: [0, 100], // The range of scroll values to apply the animation
    outputRange: [200, title.length > 15 ? 150 : 100], // The range of header heights corresponding to the scroll values
    extrapolate: 'clamp', // Keep the animation within the defined output range
  });

  const titleColor = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: ['black', 'white'],
    extrapolate: 'clamp',
  });

  const layerColor = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: ['rgba(0,0,0,0)', 'rgba(0,0,0,0.4)'],
    extrapolate: 'clamp',
  });

  const buttonBackgroundColor = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: ['rgba(0,0,0,0.4)', 'rgba(0,0,0,0)'],
    extrapolate: 'clamp',
  });

  const titleTranslateY = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: [0, title.length > 15 ? -55 : -15], // Move the title from below to the top of the header
    extrapolate: 'clamp',
  });

  const titleWidth = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: [width, width / 1.5],
    extrapolate: 'clamp',
  });

  const titleTranslateX = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: [-20, 80],
    extrapolate: 'clamp',
  });

  const titlePaddingTop = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: [0, 0],
    extrapolate: 'clamp',
  });
  const titlePadding = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: [40, 0],
    extrapolate: 'clamp',
  });

  const headerBottomMargin = scrollY.interpolate({
    inputRange: [0, 100],
    outputRange: [0, -50],
    extrapolate: 'clamp',
  });

  return (
    <>
      <Animated.View
        style={[
          styles.headerContainer,
          {height: headerHeight, marginBottom: headerBottomMargin},
        ]}>
        <ImageBackground
          source={image} // Replace with the actual image path
          style={[StyleSheet.absoluteFillObject, styles.headerImage]}
          resizeMode="cover">
          <Animated.View
            style={{
              backgroundColor: layerColor,
              height: headerHeight,
              width,
            }}
          />
          {/* Back Button */}
          <IconButton
            icon="arrow-left"
            iconColor="#FFFFFF"
            size={24}
            onPress={() => navigation.goBack()}
            style={[
              styles.backButton,
              {backgroundColor: buttonBackgroundColor},
            ]}
          />

          {/* Title */}
        </ImageBackground>
        {/* Add any other components you want to include in the header */}
      </Animated.View>
      <Animated.Text
        numberOfLines={2}
        style={[
          styles.title,
          {
            color: titleColor,
            paddingTop: 0,
            paddingRight: titlePadding,
            paddingBottom: titlePadding,
            paddingLeft: titlePadding,
            width: titleWidth,
            transform: [
              {translateX: titleTranslateX},
              {translateY: titleTranslateY},
            ],
          },
        ]}>
        {title}
      </Animated.Text>
    </>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    overflow: 'visible',
  },
  backButton: {
    marginHorizontal: 8,
    position: 'absolute',
    top: 40,
    left: 25,
  },
  title: {
    textAlign: 'left',
    backgroundColor: 'transparent',
    color: '#000',
    fontFamily: 'Poppins-bold',
    fontSize: 32,
    fontWeight: 'bold',
  },
  headerImage: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
});

export default CustomHeader;
