import {Dimensions, ImageBackground, StyleSheet, View} from 'react-native';
import {IconButton, Text} from 'react-native-paper';
import LibreriaPaquita from '../../../assets/img/libreria-paquita-1.png';
import Degradado from '../../../assets/img/degradado.png';
import {useNavigation} from '@react-navigation/native';

interface Props {
  image: string;
  name: string;
  withBack: boolean;
}

const {width} = Dimensions.get('screen');
export const KanguroPointHeader = ({name, image, withBack}: Props) => {
  const navigation = useNavigation();
  const back = () => {
    navigation.goBack();
  };
  return (
    <ImageBackground
      source={image ? image : LibreriaPaquita}
      style={{width, height: 170}}
      resizeMode="cover">
      <View>
        <ImageBackground source={Degradado} style={styles.degraded}>
          {
            withBack && (
              <IconButton
                icon="chevron-left"
                style={styles.iconButton}
                onPress={back}
              />
            )
          }
        </ImageBackground>
        <Text style={styles.title}>
          {name}
        </Text>
      </View>
    </ImageBackground>
  );
};
const styles = StyleSheet.create({
  degraded: {
    width: '100%',
    height: 170,
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  title: {
    position: 'absolute',
    bottom: 0,
    color: 'white',
    fontWeight: '700',
    fontSize: 19,
    padding: 20,
  },
  iconButton: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 10,
    top: 50,
    left: 15,
  }
});
