import {Image, ScrollView, StyleSheet} from "react-native";
import React, {useEffect} from "react";
import {useKpImages} from "@components/useKPImages";
import {KanguroPointViewModel} from "@viewModels/KanguroPoint.viewModel";
import {Text} from "react-native-paper";
import {useTranslation} from "react-i18next";
import DefaultImage from "@assets/tmp/placeholder3.png";

interface Props {
  kanguroPoint: KanguroPointViewModel;
}

export const KPImagesCarousel = ({kanguroPoint}: Props) => {
  const {t} = useTranslation('common');
  const [images, setImages] = React.useState(useKpImages(kanguroPoint));
  const [errors, setErrors] = React.useState(Array<boolean>());

  useEffect(() => {
    images.map((image, index) => {
      errors.push(false)
    })
  }, [])

  if (images.length === 0) return null;

  return (
    <>
      <Text style={styles.title} variant="headlineSmall">
        {t('kpImagesCarousel_title')}
      </Text>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          // @ts-ignore
          gap: 3,
          paddingHorizontal: 24,
        }}
        keyboardShouldPersistTaps="always">
        {images.map((image, index) => (
          <Image
            key={index}
            source={image}
            style={styles.image}
            resizeMethod={'resize'}
            resizeMode="cover"
            onError={() => {
              images.splice(index, 1, DefaultImage)
              setErrors(errors.splice(index, 1, true))
            }}
          />
        ))}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    padding: 20 ,
    fontSize: 24,
    fontWeight: '600',
    fontFamily: 'Poppins-Regular'
  },
  image: {
    width: 112,
    height: 112,
    borderRadius: 10,
  }
});
