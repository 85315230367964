import { theme } from '@config/Theme';
import { Linking, Platform, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

interface Props {
  address: string;
  text: string
  lat: number;
  lng: number;
}

export const MapTo = ({address, text, lat, lng}: Props) => {
  const mapTo = async () => {
    const destination = encodeURIComponent(`${address}`);
    const provider = Platform.OS === 'ios' ? 'apple' : 'google';
    let link = `https://maps.${provider}.com/?daddr=${destination}`;

    if (lat !== 0 && lng !== 0) {
      link = `https://maps.${provider}.com/?z=12&t=m&q=loc:${lat}+${lng}`;
    }

    try {
      const supported = await Linking.canOpenURL(link);

      if (supported) {
        Linking.openURL(link);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      icon="directions"
      onPress={mapTo}
      mode="outlined"
      labelStyle={{color: theme.colors.onBackground}}
      style={styles.button}
    >
      {text}
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    borderWidth: 1,
    paddingHorizontal: 15,
  },
});
