import React from 'react';
import {Text} from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Stack} from '@components/Stack';
import {Image, StyleSheet, View} from 'react-native';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {useTranslation} from 'react-i18next';
import {Divider} from '@components/Divider';
import Logo from '@assets/img/logo.png';
import {ProfileCard} from '@components/delivery/Profile.card';

export const Courier = ({parcel}: {parcel: ParcelViewModel}) => {
  const {t} = useTranslation('common');

  return (
    <>
      <Divider height={2} />
      <Stack spacing={2} style={{paddingHorizontal: 20}}>
        {!!parcel.deliveryDriver?.courier?.name && (
          <View>
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              // @ts-ignore
              gap: 10
            }}>
              <MaterialCommunityIcons name={'truck-outline'} size={20} />
              <Text style={styles.label}>{t('courier_companyName')}</Text>
            </View>
            <View style={styles.centeredRow}>
              <Image
                source={
                  parcel.deliveryDriver!.courier.logo
                    ? {uri: parcel.deliveryDriver!.courier.logo}
                    : Logo
                }
                resizeMode="contain"
                style={{width: 50, height: 50, marginRight: 15}}
              />
              <Text style={styles.text}>
                {parcel.deliveryDriver!.courier.name}
              </Text>
            </View>
          </View>
        )}
        {!!parcel.deliveryDriver && (
          <ProfileCard
            label={t('courier_deliveryDriverName')}
            profile={parcel.deliveryDriver.profile}
          />
        )}
      </Stack>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
  centeredRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
});
