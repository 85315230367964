import {theme} from '@config/Theme';
import {normalizeHorizontal} from '@utils/normalize';
import {StyleSheet} from 'react-native';

const {
  uikit: {tag},
} = theme;

export interface ITagProps {
  type: 'primary' | 'success' | 'warning' | 'danger';
  dotIcon?: 'normal' | 'border';
  size: 'small' | 'medium';
  text: string;
}

interface IStateProps {
  [key: string]: string;
}

export const states: IStateProps = {
  primary: tag.primary,
  success: tag.success,
  warning: tag.warning,
  danger: tag.danger,
};

const sizes = {
  small: normalizeHorizontal(12),
  medium: normalizeHorizontal(18),
};

export const styles = (props: ITagProps) => {
  const color = states[props.type];
  const size = sizes[props.size];
  return StyleSheet.create({
    borderCircle: {
      borderWidth: 1.5,
      borderColor: props.dotIcon === 'normal' ? 'transparent' : color,
      width: size,
      height: size,
      borderRadius: size / 2,
      padding: 4,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 4,
    },
    circle: {
      width: size - 8,
      height: size - 8,
      borderRadius: size / 2,
      backgroundColor: color,
    },
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};
