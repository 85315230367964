import {LockerApiResponse} from '@resources/Locker.apiResponse';
import {ScheduleViewModel} from '@viewModels/Schedule.viewModel';

export class LockerViewModel {
  id: string;
  name: string;
  schedule: Array<ScheduleViewModel>;

  constructor(lockerApiResponse: LockerApiResponse) {
    this.id = lockerApiResponse.id;
    this.name = lockerApiResponse.name;
    this.schedule = lockerApiResponse.schedule.map(
      scheduleApiResponse => new ScheduleViewModel(scheduleApiResponse),
    );
  }
}
