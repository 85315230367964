import {AttachmentApiResponse} from '../resources/Attachment.apiResponse';
import {SERVER_URL} from '../../config/Endpoints';

export class AttachmentViewModel {
  id: string;
  type: string;
  url: string;
  canDelete: boolean;

  constructor(attachment: AttachmentApiResponse) {
    this.id = attachment.id;
    this.type = attachment.type;
    this.url = attachment.url;
    this.canDelete = attachment.canDelete;

    //TODO Temproary fix
    if (attachment.url.indexOf('http') < 0) {
      this.url = SERVER_URL + attachment.url;
    }
  }
}
