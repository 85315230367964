import LogoBlancGroc from '@assets/img/logo-blanc-groc.png';


export type ROLE = 'recipient';
export class MultiAppConfig {

  public static readonly APP_NAME: string = 'Kanguro';
  public static readonly PRIMARY_COLOR: string = ''
  public static readonly PRIMARY_TEXT_COLOR: string = ''
  public static readonly ROLE: string = 'Recipient';
  public static readonly ONESIGNAL_APP_ID: string =
    '402adee3-f65f-41db-86b3-40773dba915d'
  public static readonly LOGO = LogoBlancGroc;

  public static readonly HELP_PHONE: string = '666666666';
  public static readonly HELP_WHATSAPP: string = '666666666';
}
