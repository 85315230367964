import {Pressable, View} from 'react-native';
import React from 'react';
import Icons from '../icons/Icons';
import Text from '../text/Text';
import {IPillProps, styles} from './Pill.presets';
import {theme} from '@config/Theme';
import { normalize } from '@utils/normalize';


const {
  uikit: {
    pill: {light, dark},
  },
} = theme;

export default function Pill(props: IPillProps) {
  const {text, icon, size = 'small', type = 'light', onPress} = props;
  const pillStyle = styles(props);
  const textType = size === 'small' ? 'caption' : 'body';
  const textColor = type === 'light' ? light.text : dark.text;

  return (
    <Pressable onPress={onPress}>
      <View style={pillStyle.container}>
        {icon &&
           <View style={{ marginRight: normalize(4)}}>
            <Icons icon={icon} color={textColor} />
           </View>
         }
        <Text text={text} type={textType} weight="medium" color={textColor} />
      </View>
    </Pressable>
  );
}
