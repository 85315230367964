import {useMqtt} from './useMqtt';
import {useAuth} from '@auth/useAuth';
import {MultiAppConfig} from '@config/MultiAppConfig';

export const Mqtt = () => {
  const role = MultiAppConfig.ROLE.toLowerCase();
  const {user} = useAuth();
  // @ts-ignore
  useMqtt(role, user?.id);
  return null;
};
