import {Text} from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {View} from 'react-native';
import {hexToRgba} from '../Utils';

interface Props {
  text: string;
  color: string;
  icon?: string;
}

export const Tag = ({text, icon, color}: Props) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: hexToRgba(color, 0.08),
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 10,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '40%',
        width: '100%',
      }}>
      <Text
        style={{
          textTransform: 'uppercase',
          color,
          fontWeight: 'bold',
          fontSize: 13,
          textAlign: 'center'
        }}>
        {text}
      </Text>
      {icon && <MaterialCommunityIcons name={icon} size={20} color={color} />}
    </View>
  );
};
