export const TYPE_ERRORS = {
  CONCURRENT_VERIFICATION: 'CONCURRENT_VERIFICATION',
}

export const isTypeError = (error: any, type: any) => {

  switch (type) {
    case TYPE_ERRORS.CONCURRENT_VERIFICATION:
      return error.response.data.status === 400 && error.response.data.message === 'Concurrent verifications to the same number are not allowed';
  }
}
