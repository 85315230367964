import {normalizeHorizontal} from '@utils/normalize';

export interface ISpinnerProps {
  size?: 'small' | 'large';
}

export const sizes = {
  small: normalizeHorizontal(24),
  large: normalizeHorizontal(36),
};
