import {View} from 'react-native';
import {TopBar} from '@components/TopBar';
import {theme} from '@config/Theme';
import {HistoryFeature} from '@modules/recipient/features/history/History.feature';

export const HistoryPage = ({navigation}: any) => {
  const goTo = (screen: string) => (id: string, phone: string) => {
    navigation.navigate(screen, {id, phone});
  };
  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <TopBar />
      <View style={{flex: 1}}>
        <HistoryFeature goToDelivery={goTo('DeliveryDetail')} />
      </View>
    </View>
  );
};
