import {DefaultTFuncReturn, TFuncReturn} from 'i18next';
import {StyleSheet, View} from 'react-native';
import {Avatar, Text} from 'react-native-paper';
import React from 'react';
import {ProfileViewModel} from '@viewModels/Profile.viewModel';

export const ProfileCard = ({
  label,
  profile,
}: {
  label: TFuncReturn<'common', string, DefaultTFuncReturn, undefined>;
  profile: ProfileViewModel;
}) => {
  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
        }}>
        {profile.imageUrl ? (
          <Avatar.Image source={{uri: profile.imageUrl}} size={50} />
        ) : (
          <Avatar.Text size={50} label={profile.name?.charAt(0) || ''} />
        )}
        <View style={{alignContent: 'space-between', paddingLeft: 10}}>
          <Text style={styles.text}>{profile.name}</Text>
          <Text style={styles.text}>
            {profile.countryCode
              ? `${profile.countryCode}-${profile.phone}`
              : profile.phone}
          </Text>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
});
