import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {AxiosResponse} from 'axios';
import {ListApiResponse} from '@infrastructure/api/List.apiResponse';
import {ParcelApiResponse} from '@resources/Parcel.apiResponse';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';

export const HistoryResource = {
  list: async () => {
    const res: AxiosResponse<ListApiResponse<ParcelApiResponse>> =
      await customAxios.get<ListApiResponse<ParcelApiResponse>>(
        Endpoints.parcels.history + '?pageNumber=0&pageSize=10',
      );
    return res.data.items.map(delivery => new ParcelViewModel(delivery));
  },
};
