import {Text} from 'react-native-paper';

export const BodyText = ({children, ...rest}: any) => {
  return (
    <Text
      style={{
        ...rest.style,
        fontSize: 16,
      }}>
      {children}
    </Text>
  );
};
