import React, {useEffect, useState} from "react";
import {StyleSheet, View} from "react-native";
import {ParcelItem} from "@components/ParcelItem";
import {ParcelViewModel} from "@viewModels/Parcel.viewModel";
import {customAxios} from "@infrastructure/api/CustomAxios";
import {Endpoints} from "@config/Endpoints";
import {Text} from "react-native-paper";
import {useTranslation} from "react-i18next";

interface Props {
  kanguroPointId: string;
}

export const ParcelList = ({kanguroPointId}: Props) => {
  const {t} = useTranslation('common');
  const [parcels, setParcels] = useState<Array<ParcelViewModel>>([]);

  useEffect(() => {
    customAxios
      .get(
        `${Endpoints.parcels.current}?kanguroPointId=${kanguroPointId}&pageSize=20&pageNumber=0`,
      )
      .then(res => {
        setParcels(
          res.data.items.map((delivery: any) => new ParcelViewModel(delivery)),
        );
      })
      .catch(err => console.log(err));
  }, [kanguroPointId]);

  return (
    <>
      {
        parcels.length !== 0 && (
          <>
            <Text style={styles.title}>
              {t('parcelList_title')}
            </Text>
            <View style={styles.container}>
              {
                parcels.map((parcel, index) => (
                  <ParcelItem
                    key={index}
                    ean={parcel.EAN}
                    status={parcel.status}
                  />
                ))
              }
            </View>
          </>
        )
      }
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 15, display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
  },
  title: {
    padding: 20,
    fontSize: 24,
    fontWeight: '600',
    fontFamily: 'Poppins-Regular'
  }
});
