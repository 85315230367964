import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {HistoryPage} from './History.page';
import {DeliveryDetailPage} from '@modules/recipient/pages/history/DeliveryDetail.page';

export const HistoryStack = () => {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="History" component={HistoryPage} />
      <Stack.Screen name="DeliveryDetail" component={DeliveryDetailPage} />
    </Stack.Navigator>
  );
};
