import {StyleSheet, View, Text} from 'react-native';
import {Box} from "@components/Box";
import {ParcelBorder} from "@components/ParcelBorder";
import {ParcelData, ParcelStatus} from "@config/Parcel.status";

export interface StatusProps {
  ean: string;
  status: ParcelStatus;
}

export function ParcelItem(props: StatusProps) {
  const { primary, secondary, label } = ParcelData(props.status);

  return (
    <View style={styles.root}>
      <ParcelBorder statusColor={primary} />
      <View style={styles.iconEan}>
        <View style={styles.ean}>
          <Box/>

          <View style={styles.number}>
            <Text style={styles.numerEan}>
              {props.ean}
            </Text>
          </View>
        </View>
        <View style={styles.statusChevron}>
          <View style={[styles.status2, {backgroundColor: secondary }]}>
            <Text style={[styles.status, { color: primary }]}>
              {label}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  numerEan: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
  },
  iconEan: {
    alignItems: 'center',
    // @ts-ignore
    gap: 16,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 0,
    backgroundColor: '#FFF',
    flexDirection: 'row',
    paddingVertical: 0,
    paddingHorizontal: 8,
  },
  ean: {
    alignItems: 'center',
    // @ts-ignore
    gap: 8,
    flexDirection: 'row',
  },
  number: {
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    gap: 10,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 0,
  },
  status: {
    //color: theme.colors.success,
    textAlign: 'right',
    fontFamily: 'Poppins-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  statusProperty1EnMostrador: {
    backgroundColor: '#98ecff',
  },
  statusProperty1Expirado: {
    color: '#FE724C',
  },
  statusChevron: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    // @ts-ignore
    gap: 8,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 0,
  },
  status2: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    // @ts-ignore
    gap: 10,
    flexDirection: 'row',
    padding: 10,
    borderRadius: 7,
  },
});
