import {View, Text as RNText} from 'react-native';
import React from 'react';
import {styles, ITextProps} from './Text.presets';

export default function Text(props: ITextProps) {
  const {
    text,
    color = '#1F2937',
    type = 'caption',
    weight = 'regular',
    align = 'left',
  } = props;
  const stylesText = styles(color);
  return (
    <RNText
      style={{
        ...stylesText[type],
        ...stylesText.colorText,
        ...stylesText[weight],
        ...stylesText[align],
      }}
      {...props}>
      {text}
    </RNText>
  );
}
