import {BottomButton} from '@components/BottomButton';
import {
  KanguroPointDetailsFeature
} from '@modules/recipient/features/kanguroPoint-details/KanguroPointDetails.feature';
import {useTranslation} from "react-i18next";

export const KanguroPointReceiveDetailsPage = ({navigation, route}: any) => {
  const { t } = useTranslation('recipient');
  const receive = () => {
    navigation.navigate('Receive', {
      id: route.params.id,
      counterId: route.params.counterId,
    });
  };

  return (
    <>
      <KanguroPointDetailsFeature id={route.params.id} />
      <BottomButton onPress={receive} text={t('kanguroPointReceiveDetails_page_submit')} />
    </>
  );
};
