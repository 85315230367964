import {CounterApiResponse} from '@resources/Counter.apiResponse';
import {ScheduleViewModel} from './Schedule.viewModel';
import {ProfileViewModel} from '@viewModels/Profile.viewModel';

export class CounterViewModel {
  id: string;
  name: string;
  schedule: Array<ScheduleViewModel>;
  profile: ProfileViewModel;
  phone: string;

  constructor(apiResponse: CounterApiResponse) {
    this.id = apiResponse.id;
    this.name = apiResponse.name;
    this.schedule = apiResponse.schedule
      ? apiResponse.schedule.map(s => new ScheduleViewModel(s))
      : [];
    this.profile = new ProfileViewModel(apiResponse.profile);
    this.phone = apiResponse.phone;
  }
}
