import React, {useState} from 'react';
import {changeLanguage, getLanguage} from '@config/i18n';
import {KDropdown} from '@components/KDropdown';

export const LanguageSelector = () => {
  const [value, setValue] = useState(getLanguage());
  const [items, setItems] = useState([
    {label: 'English', value: 'en'},
    {label: 'Español', value: 'es'},
  ]);

  return <KDropdown items={items} value={value} onSelect={changeLanguage} />;
};
