import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';

interface RequestCodeData {
  phone: string;
  email?: null;
  title: string;
  description: string;
}

export const IncidenceResource = {
  sendIncidenceOtp: async (
    phone: string,
    title: string,
    description: string,
  ) => {
    const data: RequestCodeData = {
      phone,
      title,
      description,
    };
    return customAxios.post(Endpoints.incidences.otp, data);
  },
};
