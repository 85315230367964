import {StyleSheet, View} from 'react-native';

export const ParcelBorder = ({statusColor}: { statusColor: string }) => {
  return (
    <View style={styles.root}>
      <View style={[styles.rectangle, {backgroundColor: statusColor}]}/>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 10,
    height: 57,
  },
  rectangle: {
    width: 10,
    height: 57,
    flexShrink: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 10,
  },
});
