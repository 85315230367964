import {ProfileViewModel} from './Profile.viewModel';
import {DeliveryDriverApiResponse} from '@resources/DeliveryDriver.apiResponse';
import {CourierViewModel} from './Courier.viewModel';

export class DeliveryDriverViewModel {
  id: string;
  profile: ProfileViewModel;
  courier: CourierViewModel | null;

  constructor(apiResponse: DeliveryDriverApiResponse) {
    this.id = apiResponse.id;
    this.profile = new ProfileViewModel(apiResponse.profile);
    this.courier = apiResponse.courier
      ? new CourierViewModel(apiResponse.courier)
      : null;
  }
}
