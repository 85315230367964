import {StyleSheet} from 'react-native';
import {theme} from '@config/Theme';

export const ReceiveDeliveryStyles = StyleSheet.create({
  scrollContainer: {
    paddingBottom: 100,
  },
  scrollView: {
    flex: 1,
  },
  shadowContainer: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flex: 1,
  },
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: 20,
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 20,
  },
  containerList: {
    flex: 0.9,
  },
  cardContainer: {
    padding: 20,
  },
  center: {
    textAlign: 'center',
  },
  cardContent: {
    paddingBottom: 20,
    paddingTop: 10,
  },
  code: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  eanText: {
    color: theme.colors.onBackground,
    fontWeight: '400',
    fontFamily: 'Poppins-Regular',
  },
});
