import {StyleSheet, View} from 'react-native';
import Path3392 from '../../assets/vectors/Path3392.svg';

export function Arrow({rotate = '0deg'}: {rotate?: string}) {
  return (
    <View style={[styles.root, {transform: [{rotate}]}]}>
      <Path3392 />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 5,
    height: 12.684,
  },
});
