import {CustomBack} from './CustomBack';
import {Appbar} from 'react-native-paper';

interface Props {
  text: string;
  back: () => void;
}

export const BackTopBar = ({text, back}: Props) => {
  return (
    <Appbar.Header style={{backgroundColor: 'transparent', elevation: 0}}>
      <CustomBack onPress={back} />
      <Appbar.Content
        title={text}
        titleStyle={{
          fontSize: 21,
          fontWeight: '700',
          fontFamily: 'Poppins-Bold',
          lineHeight: 31.5,
          textAlign: 'center',
          transform: [{translateX: -25}],
        }}
      />
    </Appbar.Header>
  );
};
