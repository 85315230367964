import {View} from 'react-native';
import {ReceiveDeliveryStyles as styles} from '@modules/recipient/features/receive-delivery/ReceiveDelivery.styles';
import {Shadow} from 'react-native-shadow-2';
import QRCode from 'react-native-qrcode-svg';
import {ActivityIndicator, Text} from 'react-native-paper';
import {BodyGray} from '@components/texts/BodyGray';
import React from 'react';
import Clipboard from '@react-native-clipboard/clipboard';
import {useTranslation} from 'react-i18next';
import { Button } from '@components/UIKit';

interface Props {
  value: string;
  subtitle: string;
  force?: boolean;
  onForce?: () => void;
}

export function QrStep({value, subtitle, force, onForce}: Props) {
  const {t} = useTranslation('recipient');
  const onLongPress = () => {
    Clipboard.setString(value);
  };
  return (
    <View style={styles.shadowContainer}>
      <Shadow>
        <View style={styles.container}>
          <QRCode value={value} size={200} />
          <Text onLongPress={onLongPress}>{value}</Text>
        </View>
      </Shadow>
      <View style={{padding: 20}}>
        <BodyGray>{subtitle}</BodyGray>
      </View>
      {force ? (
        <View style={{padding: 20}}>
          <Button
            onPress={onForce!}
            text={t('receiveDeliveryFeature_force_button')!}
          />
        </View>
      ) : (
        <ActivityIndicator />
      )}
    </View>
  );
}
