import {customAxios} from "@infrastructure/api/CustomAxios";
import {Endpoints} from "@config/Endpoints";

export const RatingResource = {
  rate: (rate: number, groupId?: string) => {
    return customAxios.post(Endpoints.rating.rate, {
      rate,
      ...(groupId && {groupId})
    })
  }
}
