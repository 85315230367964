import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {ActivityIndicator, Text} from 'react-native-paper';
import {useAppTheme} from '../../config/Theme';
import {Shadow} from 'react-native-shadow-2';

interface CustomButtomProps {
  onPress: () => void;
  text: string | JSX.Element;
  bgcolor?: string;
  color?: string;
  disabled?: boolean;
  style?: any;
  loading?: boolean;
  icon?: JSX.Element;
}

export const CustomButtom = ({
                               onPress,
                               text,
                               color,
                               bgcolor,
                               disabled,
                               style,
                               loading,
                               icon,
                             }: CustomButtomProps) => {
  const theme = useAppTheme();
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={{justifyContent: 'center', flexDirection: 'row', ...style}}>
      <Shadow distance={5}>
        <View
          style={{
            borderRadius: 20,
            paddingVertical: 5,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            minWidth: 200,
            ...(disabled && {opacity: 0.5}),
            backgroundColor: disabled
              ? theme.colors.outline
              : bgcolor || theme.colors.primary,
            flexDirection: 'row',
          }}>
          {loading ? (
            <ActivityIndicator
              color={disabled ? theme.colors.primary : 'black'}
            />
          ) : (
            <>
              {!!icon && <View style={{paddingHorizontal: 10}}>{icon}</View>}
              <Text
                style={{
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingVertical: 7,
                  ...(!!icon
                    ? {paddingLeft: 20, paddingRight: 50}
                    : {paddingHorizontal: 50}),
                  color: color || theme.colors.onBackground,
                }}>
                {text}
              </Text>
            </>
          )}
        </View>
      </Shadow>
    </TouchableOpacity>
  );
};
