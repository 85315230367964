import {theme} from '../../../config/Theme';
import {ScrollView, View} from 'react-native';
import {BackTopBar} from '../../../infrastructure/components/BackTopBar';
import {ListFaqsFeature} from '../features/list-faqs/ListFaqs.feature';

interface Props {
  navigation: any;
}

export const FAQPage = ({navigation}: Props) => {
  const back = () => {
    navigation.goBack();
  };
  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <BackTopBar text="Preguntas frequentes" back={back} />
      <ScrollView style={{paddingHorizontal: 20, paddingTop: 20}}>
        <ListFaqsFeature />
      </ScrollView>
    </View>
  );
};
