import {Linking, StyleSheet, View} from 'react-native';
import {Button, Text} from 'react-native-paper';
import {MapTo} from '../MapTo';
import {useTranslation} from 'react-i18next';
import {BodyGray} from '@components/texts/BodyGray';
import {theme} from '@config/Theme';
import {Distance} from "@components/Distance";
import Counter from '../../../assets/vectors/Counter.svg';

interface Props {
  address: string;
  phone?: string;
  latitude: number;
  longitude: number;
}

export const KanguroPointDirection = ({
                                        address,
                                        phone,
                                        latitude,
                                        longitude,
                                      }: Props) => {
  const {t} = useTranslation('common');
  const call = () => {
    if (!phone) {
      return;
    }
    Linking.openURL(`tel:${phone}`);
  };
  return (
    <View style={styles.container}>
      <View style={{ marginBottom:10 }}>
        <BodyGray>{t('kanguropointDirection_address')}</BodyGray>
        <Text variant="bodySmall">{address}</Text>
        {/*<Distance distance={distance} forDetails />*/}
        <View style={{ marginTop: 10 }}>
          <BodyGray>{t('kanguropointDirection_collection')}</BodyGray>
          <View style={styles.deliverSection} >
            <Counter width={21} height={18} />
            <Text variant="bodySmall">{t('kanguropointDirection_counter')}</Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingVertical: 15,
          justifyContent: 'space-between',
          // @ts-ignore
          gap: 15,
        }}>
        <MapTo
          address={address}
          lat={latitude}
          lng={longitude}
          text={t('kanguropointDirection_directions')}
        />
        {!!phone && (
          <Button
            icon="phone"
            onPress={call}
            mode="outlined"
            labelStyle={{color: theme.colors.onBackground}}
            style={styles.button}>
            {t('kanguropointDirection_call')}
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 5,
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  deliverSection: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5
  }
});
