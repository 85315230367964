import React from 'react';
import {Stack} from '../Stack';
import {Text} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {ParcelStatus} from '@config/Parcel.status';

interface Props {
  parcel: ParcelViewModel;
}

export const ParcelDates = ({parcel}: Props) => {
  const {t} = useTranslation('common');
  if (parcel.status === ParcelStatus.Cancelled) {
    return (
      <View style={styles.container}>
        <Stack spacing={1}>
          <Text style={styles.label}>
            {t('parcelDates_cancelation_date_label')}
          </Text>
          <Text style={styles.text}>
            {parcel.cancelDate?.toFormat('dd LLL yy')}
          </Text>
        </Stack>
      </View>
    );
  }
  return (
    <View>
      <View style={styles.container}>
        {!!parcel.putInDate && (
          <Stack spacing={0.1}>
            <Text style={styles.label}>
              {t('parcelDates_delivery_date_label')}
            </Text>
            <Text style={styles.text}>
              {parcel.putInDate?.toFormat('dd LLL yy')}
            </Text>
          </Stack>
        )}
        {parcel.status === ParcelStatus.Collected && (
          <Stack spacing={0.1}>
            <Text style={styles.label}>
              {t('parcelDates_collect_date_label')}
            </Text>
            <Text style={styles.text}>
              {parcel.putOutDate?.toFormat('dd LLL yy')}
            </Text>
          </Stack>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
});
