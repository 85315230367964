/*
newParcel,PARCEL-CREATED
counterIsRequestedToValidate,CONFIRM-PARCELS
counterValidateToDeliveryDriver,PARCEL-VALID
counterValidateToClient,PARCEL-VALID-DKP
counterInvalidateToDeliveryDriver,PARCEL-INVALID
counterInvalidateToClient,PARCEL-INVALID-DKP
counterGetParcelByCode,PARCEL-VALIDATION
recipientIsRequestedToValidate,CONFIRM-PARCELS
recipientValidateToCounter,PARCEL-VALID
recipientValidateToDeliveryDriver,PARCEL-VALID-RECIPIENT
recipientInvalidateToCounter,PARCEL-INVALID
recipientInvalidateToDeliveryDriver,PARCEL-INVALID-RECIPIENT
cancelParcel,PARCEL-CANCELLED

 */
/*
PARCEL-CREATED
PARCEL-VALID
PARCEL-VALID-COUNTER
PARCEL-INVALID
PARCEL-INVALID-COUNTER
PARCEL-CANCELLED
PARCEL-COLLECT
PARCEL-EXPIRATION-WARNING
PARCEL-EXPIRATION
PARCEL-VALID-RETURN
PARCEL-INVALID-RETURN
CONFIRM-PARCELS
 */
export const EventTypes = {
  PARCEL: {
    CREATED: 'PARCEL-CREATED',
    VALID: 'PARCEL-VALID',
    INVALID: 'PARCEL-INVALID',
    VALID_COUNTER: 'PARCEL-VALID-COUNTER',
    INVALID_COUNTER: 'PARCEL-INVALID-COUNTER',
    CANCELLED: 'PARCEL-CANCELLED',
    COLLECT: 'PARCEL-COLLECT',
    EXPIRATION_WARNING: 'PARCEL-EXPIRATION-WARNING',
    EXPIRATION: 'PARCEL-EXPIRATION',
    VALID_RETURN: 'PARCEL-VALID-RETURN',
    INVALID_RETURN: 'PARCEL-INVALID-RETURN',
    CONFIRM_PARCELS: 'CONFIRM-PARCELS',

    CANCEL_VALIDATION: 'CANCEL-VALIDATION',
    UPDATED: 'PARCEL-UPDATED',
  },

  APP: {
    DOWNLOAD_PROGRESS: 'APP-DOWNLOAD-PROGRESS',
  },

  PROFILE: {
    LOGOUT: 'PROFILE-LOGOUT',
    UPDATE_SCHEDULE: 'PROFILE-UPDATE-SCHEDULE',
  },
};
