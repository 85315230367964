import * as Yup from 'yup';

export const SignatureRequest = {
  getValidationSchema() {
    return Yup.object({
      name: Yup.string().required('Name is required'),
      dni: Yup.string().required('NIF is required'),
      phone: Yup.string().required('Phone is required'),
      signaturePoints: Yup.array()
        .of(
          Yup.object().shape({
            x: Yup.array().min(1, 'Signature is required'),
            y: Yup.array().min(1, 'Signature is required'),
          })
        )
        .min(1, 'Signature is required'),
    });
  },
  getInitialValues({name = '', email = '', dni = '', phone = '', }) {
    return {
      name,
      email,
      dni,
      phone,
      signaturePoints: [],
    };
  }
};
