import {View, Pressable, StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Text, Button, Modal, Icons, Spinner} from '@components/UIKit';
import {theme} from '@config/Theme';
import {useTranslation} from 'react-i18next';
import {IncidenceResource} from './Incidence.resource';
import {
  normalize,
  normalizeHorizontal,
  normalizeVertical,
} from '@utils/normalize';

interface IIncidenceOtpProps {
  active: boolean;
  onClose: () => void;
  userPhone: string;
}

export default function IncidenceOtp(props: IIncidenceOtpProps) {
  const {t} = useTranslation('incidences');
  const {active = false, onClose, userPhone} = props;

  const data = [
    {
      title: t('incidence_item_1_otp_title'),
      description: t('incidence_item_1_otp_description'),
    },
    {
      title: t('incidence_item_2_otp_title'),
      description: t('incidence_item_2_otp_description'),
    },
  ];

  const [visible, setVisible] = useState(active);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setVisible(active);
    if (!active) {
      setSuccess(false);
      setLoading(false);
    }
  }, [active]);

  const sendIncidenceOtp = (title: string, description: string) => {
    setLoading(true);
    IncidenceResource.sendIncidenceOtp(userPhone, title, description)
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
      });
  };

  const renderItem = (item: any, index: number) => {
    const {title, description} = item;
    return (
      <Pressable
        onPress={() => sendIncidenceOtp(title, description)}
        key={index}>
        <View
          style={[styles.itemContainer, {marginBottom: index === 0 ? 18 : 0}]}>
          <View style={styles.itemInfoContainer}>
            <Text type="overline" text={item.title} weight="medium" />
            <Text
              type="overline"
              text={item.description}
              weight="regular"
              color={theme.uikit.colors.coolGray500}
            />
          </View>

          <View style={styles.itemIconContainer}>
            <Icons icon="chevronRight" />
          </View>
        </View>
      </Pressable>
    );
  };

  const renderItemIncidence = () => {
    if (loading) {
      return (
        <View style={styles.spinnerContainer}>
          <Spinner />
        </View>
      );
    }
    return (
      <>
        <View>
          <Text
            type="body"
            text={t('incidence_otp_item_title')}
            weight="bold"
          />
        </View>
        <View style={styles.frameItems}>
          {data.map((element, index) => {
            return renderItem(element, index);
          })}
        </View>
      </>
    );
  };

  const renderSuccessIncidence = () => {
    return (
      <>
        <View>
          <View style={styles.titleSuccess}>
            <Text
              type="body"
              text={t('incidence_otp_success_title')}
              weight="bold"
            />
          </View>
          <Text
            type="overline"
            text={t('incidence_otp_success_text')}
            weight="regular"
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            type="primary"
            size="medium"
            text={t('incidence_otp_success_btn')!}
            onPress={() => onClose()}
            textSize="caption"
          />
        </View>
      </>
    );
  };

  return (
    <Modal visible={visible} onClose={onClose} swipeable>
      <View style={styles.container}>
        {!success ? renderItemIncidence() : renderSuccessIncidence()}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    minHeight: normalizeVertical(40),
  },
  itemInfoContainer: {
    flex: 0.9,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  itemIconContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 0.1,
  },
  frameItems: {
    borderWidth: 1,
    borderColor: theme.uikit.colors.coolGray200,
    borderRadius: normalize(16),
    marginTop: normalize(24),
    padding: normalize(16),
  },
  spinnerContainer: {
    height: normalizeVertical(200),
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: normalizeHorizontal(180),
    alignSelf: 'center',
    marginTop: normalize(24),
  },
  container: {padding: normalize(18)},
  titleSuccess: {marginVertical: normalize(18)},
});
