import React from 'react';
import {ActivityIndicator} from 'react-native-paper';
import {ScrollView, View} from 'react-native';
import {theme} from '@config/Theme';
import {KanguroPointDirection} from '@components/kanguroPoint/KanguroPoint.direction';
import {InvitationParcelsFeature} from "@modules/auth/features/invitation/InvitationParcels.feature";
import {InvitationViewModel} from "@viewModels/Invitation.viewModel";
import {KanguroPointHeader} from "@components/kanguroPoint/KanguroPoint.header";
import {useKpMainImage} from "@components/useKPMainImage";
import {KanguroPointViewModel} from "@viewModels/KanguroPoint.viewModel";
import {KanguroPointSchedule} from "@components/KanguroPointSchedule";

interface Props {
  kanguroPoint: InvitationViewModel;
}

export const InvitationKanguroPointDetailsFeature = ({kanguroPoint}: Props) => {

  if (!kanguroPoint) {
    return <ActivityIndicator />;
  }

  const counterName = kanguroPoint.counter ? kanguroPoint.counter.name : kanguroPoint.locker?.name || 'errorName';
  const image = useKpMainImage(kanguroPoint as unknown as  KanguroPointViewModel);
  return (
    <View style={{flex: 1}}>
      <KanguroPointHeader name={counterName} image={image} withBack={false}/>
      <ScrollView
        // @ts-ignore
        style={{flexGrow: 1, backgroundColor: theme.colors.background, gap: 10, paddingBottom: 150}} >
          <KanguroPointDirection
            latitude={kanguroPoint?.latitude}
            longitude={kanguroPoint?.longitude}
            address={kanguroPoint?.address}
            phone={kanguroPoint?.counter?.phone}
          />
        <KanguroPointSchedule schedule={kanguroPoint?.counter?.schedule} />
        <InvitationParcelsFeature parcelsCount={kanguroPoint.parcelsCount} />
      </ScrollView>
    </View>
  );
};
