import {ProfileApiResponse} from '@resources/Profile.apiResponse';

export class ProfileViewModel {
  name: string;
  phone: string;
  email: string;
  imageUrl: string;
  countryCode: string;

  constructor(apiResponse: ProfileApiResponse) {
    this.name = apiResponse.name;
    this.phone = apiResponse.phone.split('-')[1];
    this.email = apiResponse.email;
    this.imageUrl = apiResponse.imageUrl;
    this.countryCode = apiResponse.phone.split('-')[0];
  }
}
