import React, {createContext} from "react";
import {LinePoints} from "@components/signature/Signature.provider";

export interface SignatureContextType {
  canvasRef: React.Ref<any>;
  contextRef: React.Ref<any>;
  prepareCanvas: () => void;
  startDrawingMobile: (event: any) => void;
  finishDrawing: () => void;
  drawMobile: (event: any) => void;
  clearCanvas: () => void;
  isDrawing: boolean;
  file: File | null;
  signaturePoints: Array<LinePoints>;
}

export const SignatureContext = createContext<SignatureContextType>({
  canvasRef: null,
  contextRef: null,
  prepareCanvas: () => {},
  startDrawingMobile: () => {},
  finishDrawing: () => {},
  drawMobile: () => {},
  clearCanvas: () => {},
  isDrawing: false,
  file: null,
  signaturePoints: [],
})
