import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {ParcelApiResponse} from '@resources/Parcel.apiResponse';

export const DeliveryDetailResource = {
  get: (id: string) => {
    return customAxios.get<ParcelApiResponse>(Endpoints.parcels.get(id));
  },
  getByCode: (code: string) => {
    return customAxios.get<ParcelApiResponse>(
      Endpoints.parcels.getByCode(code),
    );
  },
  removeParcelAttachment: (id: string) => {
    return customAxios.delete(Endpoints.attachments.removeParcel(id));
  },
  uploadImage: (formData: FormData) => {
    return customAxios.post(Endpoints.upload.parcel, formData);
  },
};
