import {ScrollView, StyleSheet, View} from 'react-native';
import {SignatureFeature} from "@modules/invitation/features/Signature.feature";
import {theme} from "@config/Theme";
import React from "react";
import {Header} from "@components/Header";
import {useTranslation} from "react-i18next";
import {useEvent} from "@events/hooks/useEvent";

export const RegisterPage = ({navigation, route}: any) => {
  const {t} = useTranslation('auth');
  const [isDrawing, setIsDrawing] = React.useState(false);
  const back = () => {
    navigation.goBack();
  };
  useEvent('startDrawing', () => {
    setIsDrawing(true)
  });
  useEvent('finishDrawing', () => {
    setIsDrawing(false)
  });

  const description = 'register_page_description';

  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <Header
        back={{
          onPress: () => navigation.goBack(),
          title: t('register_page_title')!,
        }}
      />
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps="always"
        scrollEnabled={!isDrawing}
      >
        <SignatureFeature phone={route.params.phone} title={t(description)}  />
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
  },
});
