import React from 'react';
import {styles} from '@modules/auth/features/login/Login.styles';
import {CustomCountryCode} from '@components/country-code/CustomCountryCode';
import {KTextInput} from '@components/KTextInput';
import {View} from 'react-native';
import {useTranslation} from 'react-i18next';

interface Props {
  value: {countryCode: string; phone: string};
  onChange: (value: {countryCode: string; phone: string}) => void;

  [key: string]: any;
}

export const PhoneInput = ({value, onChange, ...args}: Props) => {
  const {t} = useTranslation('common');
  return (
    <>
      <View style={styles.form}>
        <CustomCountryCode
          value={value.countryCode}
          onChange={code => onChange({countryCode: code, phone: value.phone})}
          {...args}
        />
        <KTextInput
          placeholder={t('phone_input_label')!}
          returnKeyType="done"
          maxLength={15}
          minLength={4}
          value={value.phone}
          keyboardType={'phone-pad'}
          //@ts-ignore
          onChangeText={(text: string) => {
            onChange({countryCode: value.countryCode, phone: text});
          }}
          style={styles.entry}
          outlineStyle={styles.entryInput}
          {...args}
        />
      </View>
    </>
  );
};
