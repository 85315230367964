
export const isInvitationURL = () => {
  return window.location.pathname.includes('invitation');
}

export const getInvitationId = () => {
  const url = window.location.pathname;
  const urlCheck = url.split('/')

  if(!isInvitationURL()) return null;

  return urlCheck[2];
}

export const isDownloadURL = () => {
  return window.location.pathname.includes('download');
}

export const setDownloadURL = () => {
  window.location.replace('/download');
}

export const setHomeURL = () => {
  window.location.replace('/');
}
