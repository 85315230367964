import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {ParcelApiResponse} from '@resources/Parcel.apiResponse';

export const ReceiveDeliveryResource = {
  get: (id: string) => {
    return customAxios.patch<{groupId: string}>(
      Endpoints.parcels.requestConfirmDelivery(id),
    );
  },
  list: (groupId: string) => {
    return customAxios.get<{
      count: number;
      items: Array<ParcelApiResponse>;
    }>(Endpoints.parcels.byGroup(groupId));
  },
};
