import {StyleSheet} from 'react-native';
import {IconNames} from '../icons/Icons.presets';
import {theme} from '@config/Theme';
import { normalizeVertical } from '@utils/normalize';

const {
  uikit: {
    pill: { bg: backgroundPill },
  },
} = theme;

export interface IPillProps {
  type?: 'dark' | 'light';
  size?: 'small' | 'medium';
  bg?: 'danger' | 'success' | 'info' | 'warning';
  icon?: IconNames;
  text: string;
  onPress?: () => void;
}

export const styles = (props: IPillProps) => {
  const {type, size, bg } = props;
  const backgroundColor = {
    danger: backgroundPill.danger,
    success: backgroundPill.success,
    info: backgroundPill.info,
    warning: backgroundPill.warning,
  }

  const height =
    size === 'small' ? normalizeVertical(26) : normalizeVertical(32);
  return StyleSheet.create({
    container: {
      backgroundColor:  bg ? backgroundColor[bg] : backgroundColor.info,
      borderRadius: 360,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      alignSelf: 'flex-start',
      minWidth: 32,
      paddingHorizontal: 8,
      minHeight: height,
    },
  });
};
