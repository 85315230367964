import React from 'react';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {StyleSheet, Vibration, View} from 'react-native';
import {Divider} from '@components/Divider';
import {DeliveryDetailResource} from '@modules/common/features/delivery-detail/resources/DeliveryDetail.resource';
import {useTranslation} from 'react-i18next';
import {ProfileCard} from '@components/delivery/Profile.card';

interface Props {
  parcel: ParcelViewModel;
}

export const Recipient = ({parcel}: Props) => {
  const {t} = useTranslation('common');
  if (!parcel.recipient) {
    return null;
  }
  const removePicture = (paquet: ParcelViewModel) => (attachmentId: string) => {
    DeliveryDetailResource.removeParcelAttachment(attachmentId).then(() => {
      paquet.attachments = paquet.attachments.filter(
        a => a.id !== attachmentId,
      );
      //setParcel({...paquet});
      Vibration.vibrate(100);
      return;
    });
  };
  return (
    <>
      <Divider height={2} />
      <View style={{paddingHorizontal: 20, paddingTop: 10}}>
        <ProfileCard
          profile={parcel.recipient!.profile}
          label={t('deliveryDetail_feature_recipient_label')}
        />
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
});
