import {Platform} from 'react-native';

export const generateBoxShadowStyle = (
  xOffset: number,
  yOffset: number,
  shadowColorIos: string,
  shadowOpacity: number,
  shadowRadius: number,
  elevation: number,
  shadowColorAndroid: string,
) => {
  if (Platform.OS === 'ios') {
    return {
      shadowColor: shadowColorIos,
      shadowOffset: {width: xOffset, height: yOffset},
      shadowOpacity,
      shadowRadius,
    };
  } else if (Platform.OS === 'android') {
    return {
      elevation,
      shadowColor: shadowColorAndroid,
    };
  }
};

export const getDefaultBoxShadowStyle = () => {
  return generateBoxShadowStyle(1, 1, '#91A2CF', 1, 3, 3, '#91A2CF');
};

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const createFormData = (
  photo: {fileName: any; type: any; uri: string},
  body: any = {},
) => {
  const data = new FormData();

  data.append('file', {
    // @ts-ignore
    name: photo.fileName,
    type: photo.type,
    uri: Platform.OS === 'ios' ? photo.uri.replace('file://', '') : photo.uri,
  });

  Object.keys(body).forEach(key => {
    data.append(key, body[key]);
  });

  return data;
};

export const createPhotoFromUri = async (imageUri: string) => {
  const response = await fetch(imageUri);
  const dat = await response.blob();
  const fileName = imageUri.split('/').pop();
  const ext = imageUri.split('.').pop();
  const metadata = `image/${ext}`;
  const uri = URL.createObjectURL(dat);
  return {uri, fileName, type: metadata};
};

export function fetchFromObject(obj: {[x: string]: any}, prop: string): any {
  if (typeof obj === 'undefined') {
    return false;
  }

  var _index = prop.indexOf('.');
  if (_index > -1) {
    return fetchFromObject(
      obj[prop.substring(0, _index)],
      prop.substring(_index + 1),
    );
  }

  return obj[prop];
}

export const getFormattedNumber = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

export const getWeekDay = (date: Date): string => {
  const weekDays = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  return weekDays[date.getDay()];
};
