import {KanguroPointDetailsFeature} from '../../features/kanguroPoint-details/KanguroPointDetails.feature';
import {BottomButton} from '@components/BottomButton';
import {useEffect, useState} from 'react';
import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';

export const KanguroPointLockerDetailsPage = ({navigation, route}: any) => {
  const [loading, setLoading] = useState(true);
  const [parcel, setParcel] = useState<null | ParcelViewModel>(null);
  const receive = () => {
    if (!parcel) {
      return;
    }
    navigation.navigate('OpenLocker', {id: parcel?.id});
  };
  useEffect(() => {
    customAxios
      .get(
        Endpoints.parcels.current +
          '?pageSize=1&pageNumber=0&deliver=true&kanguroPointId=' +
          route.params.id,
      )
      .then(response => {
        setParcel(response.data.items[0]);
      });
  }, [route.params.id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <KanguroPointDetailsFeature id={route.params.id} />
      <BottomButton
        onPress={receive}
        text="Abrir locker"
        disabled={loading}
        loading={loading}
      />
    </>
  );
};
