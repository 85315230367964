import {Image, View} from 'react-native';
import {Header} from '@components/Header';
import CounterLogo from '@assets/img/hands_box4.svg';
import Locker from '@assets/new/img/locker.webp';
import {CustomButtom} from '@components/CustomButton';
import {theme} from '@config/Theme';
import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {StackActions} from '@react-navigation/native';

export const OpenLockerPage = ({navigation, route}: any) => {
  const openLocker = () => {
    customAxios.patch(Endpoints.parcels.validate(route.params.id)).then(() => {
      navigation.dispatch(StackActions.replace('DeliveryFinished'));
    });
  };
  return (
    <>
      <Header
        back={{
          title: 'Introduce paquete',
          onPress: navigation.goBack,
        }}
        backgroundColor={theme.colors.gray}
        title="Recoge el paquete del locker"
        subtitle="Cierra la puerta una vez tengas el paquete"
      />
      <View style={{flex: 0.8, justifyContent: 'center'}}>
        <View
          style={{
            marginTop: 100,
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}>
          <CounterLogo width={150} height={200} />
          <Image
            source={Locker}
            resizeMode="contain"
            style={{width: 100, height: 150}}
          />
        </View>
      </View>
      <View style={{
        paddingBottom: 20,
        // @ts-ignore
        gap: 20,
        flex: 0.2
      }}>
        <CustomButtom onPress={openLocker} text="Ya lo tengo" />
      </View>
    </>
  );
};
