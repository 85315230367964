import React, {forwardRef, Ref} from 'react';
import {TextInput as MTextInput} from 'react-native-paper';
import {useAppTheme} from '@config/Theme';
import {fetchFromObject} from '@infrastructure/Utils';

const KTextInputComponent = (props: any, ref: Ref<unknown> | undefined) => {
  const theme = useAppTheme();
  const customProps: any = {
    value: props.value,
    onChangeText: props.onChangeText,
    left: props.left,
    onBlur: props.onBlur,
    error: props.error,
    onSubmitEditing: props.onSubmitEditing ? props.onSubmitEditing : () => {},
    returnKeyType: props.returnKeyType,
  };
  if (props.icon) {
    customProps.left = <MTextInput.Icon icon={props.icon} color="gray" />;
  }
  if (props.form) {
    customProps.value = fetchFromObject(props.form.values, props.name);
    customProps.onChangeText = props.form.handleChange(props.name);
    customProps.onBlur = props.form.handleBlur(props.name);
    customProps.error = Boolean(
      props.form.touched[props.name] && props.form.errors[props.name],
    );
  }
  if (props.next) {
    customProps.onSubmitEditing = () => {
      props.next.current.focus();
    };
    customProps.returnKeyType = 'next';
  }
  return (
    <MTextInput
      {...props}
      {...customProps}
      ref={ref}
      mode="outlined"
      activeOutlineColor={theme.colors.primary}
      outlineStyle={{
        borderWidth: 0.5,
        borderRadius: 10,
        backgroundColor: theme.colors.contrast,
        ...props.outlineStyle,
      }}
      outlineColor="#EBEBEC"
      blurOnSubmit={false}
    />
  );
};

export const KTextInput = forwardRef(KTextInputComponent);
