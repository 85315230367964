import {CourierApiResponse} from '@resources/Courier.apiResponse';

export class CourierViewModel {
  id: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  logo: string;

  constructor(apiResponse: CourierApiResponse) {
    this.id = apiResponse.id;
    this.name = apiResponse.name;
    this.address = apiResponse.address;
    this.phone = apiResponse.phone;
    this.email = apiResponse.email;
    this.logo = apiResponse.imageUrl;
  }
}
