import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import { Button, Pill } from './UIKit';

interface ParcelActionPanelProps {
  parcel: any;
  onPress: (delivery: boolean | void) => void;
}

export const ParcelActionPanel = ({
  parcel,
  onPress,
}: ParcelActionPanelProps) => {
  const {t} = useTranslation('common');

    return (
      <View style={styles.boxItem}>
        <View style={[styles.rowCenter]}>
          <Pill bg="danger" text={parcel.parcelsCount} icon="box" />
        </View>
        <Button
            onPress={() => onPress()}
            size="medium"
            text={t('parcelActionPanel_recipient_action')!}
        />
      </View>
    );
};

const styles = StyleSheet.create({
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  boxItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 16,
    width: '100%',
  },
});
