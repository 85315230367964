import {StyleSheet, View} from 'react-native';
import Parcel from '../../assets/img/parcel2.svg';

export function Box() {
  return (
    <View style={styles.root}>
      <Parcel/>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 19,
    height: 20,
  },
});
