import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {theme} from '@config/Theme';
import BoxSelected from '../../assets/img/tabs/boxSelected.svg';
import BoxUnselected from '../../assets/img/tabs/boxUnselected.svg';
import ProfileSelected from '../../assets/img/tabs/profileSelected.svg';
import ProfileUnselected from '../../assets/img/tabs/profileUnselected.svg';
import HistorySelected from '../../assets/img/tabs/historialSelected.svg';
import HistoryUnselected from '../../assets/img/tabs/historialUnselected.svg';
import {getFocusedRouteNameFromRoute} from '@react-navigation/native';
import {useLayoutEffect} from 'react';
import {ReceiveDeliveryStack} from './pages/receive-delivery/ReceiveDelivery.stack';
import {ProfileStack} from '@modules/profile/pages/Profile.stack';
import {HistoryStack} from '@modules/recipient/pages/history/History.stack';
import {useTranslation} from "react-i18next";

export const RecipientStack = ({navigation, route}: any) => {
  const { t } = useTranslation('recipient');
  const Stack = createBottomTabNavigator();
  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    if (routeName === 'Scan') {
      navigation.setOptions({tabBarVisible: false});
    } else {
      navigation.setOptions({tabBarVisible: true});
    }
  }, [navigation, route]);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: theme.colors.primary,
      }}>
      <Stack.Screen
        name="ReceiveParcel"
        component={ReceiveDeliveryStack}
        options={{
          title: t('recipient_stack_receive_title')!,
          tabBarIcon: ({focused, color, size}) =>
            focused ? (
              <BoxSelected width={size} height={size} fill={color} />
            ) : (
              <BoxUnselected width={size} height={size} fill={color} />
            ),
        }}
      />
      <Stack.Screen
        name="HistoryStack"
        component={HistoryStack}
        options={{
          title: t('recipient_stack_history_title')!,
          tabBarIcon: ({focused, color, size}) =>
            focused ? (
              <HistorySelected width={size} height={size} fill={color} />
            ) : (
              <HistoryUnselected width={size} height={size} fill={color} />
            ),
        }}
      />
      <Stack.Screen
        name="ProfileStack"
        component={ProfileStack}
        options={{
          title: t('recipient_stack_profile_title')!,
          tabBarIcon: ({focused, color, size}) =>
            focused ? (
              <ProfileSelected width={size} height={size} fill={color} />
            ) : (
              <ProfileUnselected width={size} height={size} fill={color} />
            ),
        }}
      />
    </Stack.Navigator>
  );
};
