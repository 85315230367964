/*
export const parcelStatus = {
	created: 'created',
	cancelled: 'cancelled',
	dropping: 'dropping',
	invalidCounter: 'invalid-counter',
	droppedOff: 'dropped-off',
	expired: 'expired',
	collecting: 'collecting',
	collectingInvalidCounter: 'collecting-invalid-counter',
	collected: 'collected',
	expiredReturning: 'expired-returning',
	expiredReturned: 'expired-returned',
	expiredInvalidCounter: 'expired-invalid-counter',
	unclaimed: 'unclaimed',
};
 */
import {theme} from "@config/Theme";
import {useTranslation} from "react-i18next";
import {Tag} from "@components/Tag";
import {hexToRgba} from "@infrastructure/Utils";

export enum ParcelStatus {
  Created = 'created',
  Cancelled = 'cancelled',
  Dropping = 'dropping',
  InvalidCounter = 'invalid-counter',
  DroppedOff = 'dropped-off',
  Collecting = 'collecting',
  CollectingInvalidCounter = 'collecting-invalid-counter',
  Collected = 'collected',
  Expired = 'expired',
  ExpiredReturned = 'expired-returned',
  ExpiredReturning = 'expired-returning',
  ExpiredInvalidCounter = 'expired-invalid-counter',

  Unclaimed = 'unclaimed',
}

const colorByStatus = {
  [ParcelStatus.Created]: theme.colors.primary,
  [ParcelStatus.Cancelled]: theme.colors.error,
  [ParcelStatus.Dropping]: theme.colors.yellow,
  [ParcelStatus.InvalidCounter]: theme.colors.error,
  [ParcelStatus.DroppedOff]: theme.colors.success,
  [ParcelStatus.Collecting]: theme.colors.warning,
  [ParcelStatus.CollectingInvalidCounter]: theme.colors.error,
  [ParcelStatus.Collected]: theme.colors.success,
  [ParcelStatus.Expired]: theme.colors.error,
  [ParcelStatus.ExpiredReturning]: theme.colors.warning,
  [ParcelStatus.ExpiredReturned]: theme.colors.success,
  [ParcelStatus.ExpiredInvalidCounter]: theme.colors.error,
  [ParcelStatus.Unclaimed]: theme.colors.error,
};

export const ParcelData = (status: string) => {
  const {t} = useTranslation('default');
  const primary = colorByStatus[status as ParcelStatus];
  const secondary = hexToRgba(primary, 0.1)
  return { primary, secondary, label: t(status) };
};
