import {Appbar} from 'react-native-paper';
import {CustomBack} from '@components/CustomBack';
import React from 'react';
import {Dimensions, StyleSheet} from 'react-native';

const {width} = Dimensions.get('window');
export const BackHeader = ({
  title,
  back,
}: {
  title?: string;
  subtitle?: string;
  back: () => void | boolean;
}) => {
  return (
    <Appbar.Header
      mode="center-aligned"
      statusBarHeight={0}
      style={styles.container}>
      <CustomBack onPress={back} />
      {!!title && <Appbar.Content title={title} titleStyle={styles.title} />}
    </Appbar.Header>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    width,
  },
  title: {fontWeight: 'bold', fontSize: 20, color: 'black'},
});
