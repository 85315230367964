import {KeyboardAvoidingView, Platform, StyleSheet, View} from 'react-native';
import {LinearGradient} from 'react-native-gradients';
import { Button } from './UIKit';

interface Props {
  onPress: () => void;
  text: string;
  disabled?: boolean;
  loading?: boolean;
}

const colorList = [
  {offset: '0%', color: '#e0e0e000', opacity: '1'},
  {offset: '100%', color: '#3f434ae6', opacity: '1'},
]

export const BottomButton = ({onPress, text, disabled, loading}: Props) => {

  return (
    <KeyboardAvoidingView
      style={styles.keyboard}
      behavior={Platform.OS === 'ios' ? 'position' : 'height'}>
      <View style={styles.linearGradient}>
        <LinearGradient colorList={colorList} angle={270} />
      </View>
        <View
          style={styles.button}>
          <Button
            loading={loading}
            onPress={onPress}
            text={text}
            disabled={disabled}
          />
        </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  keyboard: {
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  linearGradient: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
  },
  button: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 30,
  }
});
