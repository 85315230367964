import {ScheduleViewModel} from "@viewModels/Schedule.viewModel";
import {InvitationCounterApiResponse} from "@resources/InvitationCounter.apiResponse";

export class InvitationCounterViewModel {
  id: string;
  name: string;
  phone: string;
  schedule: Array<ScheduleViewModel>;

  constructor(invitationCounterApiResponse: InvitationCounterApiResponse) {
    this.id = invitationCounterApiResponse.id;
    this.name = invitationCounterApiResponse.name;
    this.phone = invitationCounterApiResponse.profile.phone;
    this.schedule = invitationCounterApiResponse.schedule.map(
      scheduleApiResponse => new ScheduleViewModel(scheduleApiResponse),
    );
  }
}
