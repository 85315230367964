import {Pressable} from 'react-native';
import React, {useState} from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import {styles, sizes, presetsButtonStyles} from './Button.presets';
import Icons from '../icons/Icons';
import {IconNames} from '../icons/Icons.presets';
import Text from '../text/Text';
import { ActivityIndicator } from 'react-native-paper';
import { normalizeHorizontal } from '@utils/normalize';

interface ButtonProps {
  type?: 'primary' | 'primaryLight' | 'secondary' | 'link' | 'linkDanger' | 'text';
  onPress: (data?: any) => void;
  icon?: IconNames;
  text?: string;
  size?: 'small' | 'medium' | 'large';
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  textSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'caption' | 'overline';
  color?: string;
  loading?: boolean;
}

export default function Button(props: ButtonProps) {
  const [isPress, setIsPress] = useState<boolean>(false);

  const {
    icon,
    iconPosition = 'left',
    onPress,
    text = '',
    type = 'primary',
    size = 'medium',
    disabled = false,
    textSize = 'overline',
    color,
    loading = false,
  } = props;

  const colors = presetsButtonStyles[type];

  const getBgColors = () => {
    if (disabled) {
      return colors.disabled.bgColor;
    } else {
      return isPress ? colors.pressIn : colors.pressOut;
    }
  };

  const getColorText = () => {
    if (disabled) {
      return colors.disabled.text;
    } else {
      if (color) {
        return color;
      }
      return isPress ? colors.textHover : colors.text;
    }
  };

  const renderIcon = icon && (
    <Icons icon={icon} color={getColorText()} position={iconPosition} />
  );

  const minWidth = () => {
    if (type !== 'text' && type !== 'link' && type !== 'linkDanger') {
      return  normalizeHorizontal(180);
    }
    return 0;
  }

  return (
    <Pressable
      {...(icon && {icon})}
      {...(!disabled && {onPress})}
      onPressIn={() => setIsPress(true)}
      onPressOut={() => setIsPress(false)}>
      <LinearGradient
        colors={getBgColors()}
        style={[styles.container, {height: sizes[size],  minWidth: minWidth() }]}>
         
        {iconPosition === 'left' && renderIcon}
        {loading ? (
          <ActivityIndicator
              color={getColorText()}
          />
          ) : (
          <Text color={getColorText()} text={text} type={textSize} />
        )}
        {iconPosition === 'right' && renderIcon}
      </LinearGradient>
    </Pressable>
  );
}
