import {Button, Icons, Modal, Text} from "@components/UIKit";
import React, {useMemo} from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Image, StyleSheet, TouchableOpacity, View} from "react-native";
import Heart from '@assets/new/img/Heart.png';
import {RatingResource} from "@modules/common/features/rating/Rating.resource";

interface Props {
  groupId?: string;
}
export const RatingFeature = ({groupId}: Props) => {
  const {t} = useTranslation('common');
  const [visible, setVisible] = useState(true);
  const [stars, setStars] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const onClose = () => setVisible(false);

  const submit = () => {
    setSubmitted(true);
    RatingResource.rate(stars, groupId).finally(() => {
      setTimeout(() => {
        onClose();
      }, 2000);
    });
  }

  const onStarPress = (pos: number) => () => {
    setStars(pos + 1);
  }

  const renderStars = useMemo(() => {
    const res = [];
    for (let i = 0; i < 5; i++) {
      res.push(<TouchableOpacity onPress={onStarPress(i)} key={i}>
        <Icons key={i} icon={'star'} color={stars > i ? '#1DD6FF' : 'gray'} size={40}/>
      </TouchableOpacity>)
    }
    return res;
  }, [stars]);
  return (
    <Modal visible={visible} onClose={onClose}>
      <View style={styles.container}>
        {submitted ? <>
          <View style={{paddingBottom: 20}}>

            <Image source={Heart} style={{width: 46, height: 46}}/>
          </View>
          <View style={styles.titleContainer}>
            <Text text={t('rating_feature_feedback')} align="center" type="body" weight="bold"/>
          </View>
        </> : <>
          <View style={styles.titleContainer}>
            <Text text={t('rating_feature_title')} align="center" type="body" weight="bold"/>
          </View>
          <View style={styles.starContainer}>
            {renderStars}
          </View>
          <Button onPress={submit} text={t('rating_feature_button')} type="text" disabled={stars < 1}/>
        </>}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
    alignItems: 'center',
  },
  titleContainer: {
    paddingBottom: 20
  },
  starContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 20
  }
})
