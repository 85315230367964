import React from 'react';
import {StyleSheet} from 'react-native';

import {
  TrashIcon,
  PlusIcon,
  ChevronRightIcon,
  XMarkIcon,
  CameraIcon,
  QrCodeIcon,
  HeartIcon,
  StarIcon
} from 'react-native-heroicons/solid';
import { Package } from "react-native-feather";
import {UserCircleIcon, LockClosedIcon} from "react-native-heroicons/outline";
import { normalizeHorizontal } from '@utils/normalize';

export interface IconsProps {
  icon: IconNames;
  color?: string;
  position?: 'left' | 'right';
  size?: number;
}

export type IconNames = 'plus' | 'trash' | 'chevronRight' | 'xMark' | 'star' | 'heart' | 'user-circle' | 'lock-closed' | 'camera' | 'qr' | 'box';

// TODO: icons should be added as necessary
export const getIcon = (props: IconsProps) => {
  const {icon = 'xMark', color = '#1F2937', size = 20} = props;
  const sizeIcon = normalizeHorizontal(size);
  const propsIcons = {
    width: sizeIcon, height: sizeIcon, color,
  };
  switch (icon) {
    case 'plus':
      return <PlusIcon {...propsIcons} />;
    case 'trash':
      return <TrashIcon {...propsIcons} />;
    case 'chevronRight':
      return <ChevronRightIcon {...propsIcons} />;
    case 'camera':
      return <CameraIcon {...propsIcons} />;
    case 'xMark':
      return <XMarkIcon {...propsIcons} />;
    case 'qr':
      return <QrCodeIcon {...propsIcons} />;
    case 'star':
      return <StarIcon {...propsIcons} />;
    case 'heart':
      return <HeartIcon {...propsIcons} />;
    case 'box':
      return <Package  {...propsIcons} />;
    case 'user-circle':
      return <UserCircleIcon {...propsIcons} />;
    case 'lock-closed':
      return <LockClosedIcon {...propsIcons} />;
    default:
      return <PlusIcon {...propsIcons} />;
  }
};

export const styles = StyleSheet.create({
  containerLeft: {
    paddingLeft: normalizeHorizontal(4),
  }, containerRight: {
    paddingRight: normalizeHorizontal(4),
  },
});

