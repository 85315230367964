import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import moment, {Moment} from "moment";
import { Button } from './UIKit';

interface Props {
  onPress: () => void;
  onCompleted?: () => void;
}

const CountdownButton = ({onPress, onCompleted}: Props) => {
  const {t} = useTranslation('auth');
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [then, setThen] = useState(moment());

  const onPressed = () => {
    setButtonDisabled(true);
    setCountdown(120);
    onPress();
  };

  const diffTime = ( then: Moment, now: Moment ) => {
    const ms = moment( now, 'MM/DD/YYYY HH:mm:ss' )
      .diff( moment( then, 'MM/DD/YYYY HH:mm:ss' ) );
    const duration = moment.duration( ms );
    const hours = Math.floor( duration.asHours() );

    const currentDate = moment().format( 'MM/DD/YYYY' );
    const time = `${ hours }:${ moment.utc( ms ).format( 'mm:ss' ) }`;
    setThen(now);

    return `${ currentDate } ${ time }`;
  };

  const count = () => {
    setCountdown(prevCountdown => prevCountdown - 1);
  }

  useEffect(() => {
    let timer: number;
    if (countdown > 0) {
      timer = setInterval(count, 1000, 1); // Update countdown every second
    } else {
      setButtonDisabled(false);
      onCompleted && onCompleted();
    }

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <View style={styles.container}>
      <Button
        type="primary"
        onPress={onPressed}
        disabled={buttonDisabled}
        text={countdown > 0
          ? t('verifyFeature_countdown_disabled', {time: formatTime(countdown)})!
          : t('verifyFeature_countdown_enabled')!}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  button: {
    marginTop: 20, // Adjust the margin as needed
  },
});

export default CountdownButton;
