import {theme} from '@config/Theme';
import {normalizeHorizontal} from '@utils/normalize';
import {ImageSourcePropType, StyleSheet} from 'react-native';

const {
  uikit: {avatar},
} = theme;

export interface IAvatarProps {
  size:
    | 'small'
    | 'xsmall'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';
  type: 'text' | 'image';
  bgColor?: string;
  image?: ImageSourcePropType;
  label?: string;
}

export const size = {
  small: normalizeHorizontal(24),
  xsmall: normalizeHorizontal(28),
  medium: normalizeHorizontal(32),
  xmedium: normalizeHorizontal(40),
  large: normalizeHorizontal(48),
  xlarge: normalizeHorizontal(56),
  xxlarge: normalizeHorizontal(64),
};

export const styles = StyleSheet.create({
  avatar: {
    backgroundColor: avatar.bgColor,
  },
});
