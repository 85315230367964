import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {AuthStack} from '@modules/auth/Auth.stack';
import {useAuth} from '@infrastructure/auth/useAuth';
import {RecipientStack} from '@modules/recipient/Recipient.stack';
import {SplashScreenPage} from '@modules/auth/pages/SplashScreen.page';
import {HelpPage} from '@modules/common/pages/Help.page';
import {isDownloadURL, isInvitationURL} from "../utils/url";
import {InvitationDeliveryStack} from "@modules/invitation/InvitationDelivery.stack";

export const Router = () => {
  const Stack = createNativeStackNavigator();
  const {userToken, isSignout, isLoading} = useAuth();

  const renderScreen = () => {
    if (isLoading) {
      return (
        <Stack.Screen
          name="Splash"
          component={SplashScreenPage}
          options={{headerShown: false}}
        />
      );
    }
    if (isInvitationURL()) {
      return (
        <Stack.Screen
          name="InvitationDeliveryStack"
          component={ InvitationDeliveryStack }
        />
      );
    }
    if (userToken) {
      if(isDownloadURL()) {
        return (
          <Stack.Screen
            name="DownloadApp"
            component={ RecipientStack }
          />
        );
      }
      return (
        <Stack.Screen
          name="App"
          component={ RecipientStack }
        />
      );
    }

    return (
      <Stack.Screen
        name="Auth"
        component={AuthStack}
        options={{
          animationTypeForReplace: isSignout ? 'pop' : 'push',
          headerShown: false,
        }}
      />
    );
  };

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}>
      {renderScreen()}
      <Stack.Screen
        name="Help"
        component={HelpPage}
        options={{
          animation: 'fade_from_bottom',
          presentation: 'fullScreenModal',
        }}
      />
    </Stack.Navigator>
  );
};
