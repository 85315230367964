import {useMemo} from 'react';
import DefaultImage from '@assets/tmp/placeholder3.png';
import {KanguroPointViewModel} from '@viewModels/KanguroPoint.viewModel';

export const useKpImages = (kanguroPoint?: KanguroPointViewModel | null) => {
  return useMemo(() => {
    if (!kanguroPoint) {
      return [DefaultImage];
    }
    if (kanguroPoint.attachments) {
      return kanguroPoint.attachments
        .filter(a => a.type === 'IMAGE')
        .map(attachment => ({
          uri: attachment.url,
        }));
    }
    return [DefaultImage];
  }, [kanguroPoint]);
};
