import {customAxios} from '../../../../../infrastructure/api/CustomAxios';
import {Endpoints} from '../../../../../config/Endpoints';

interface RegisterData {
  phone: string;
  name: string;
  email: string;

  dni: string;
}

export const RegisterResource = {
  register: (data: RegisterData) => {
    return customAxios.post(Endpoints.auth.register, data);
  },
};
