import {CardRow} from '../Card';
import {StyleSheet, View} from 'react-native';
import ParcelImg from '../../../assets/img/parcel.svg';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {Text} from 'react-native-paper';
import {StatusTagByRole} from '@components/StatusTagByRole';

export const ParcelCardHeader = ({
  title,
  parcelStatus,
}: {
  title: string;
  parcelStatus?: string;
}) => {
  return (
    <CardRow>
      <View style={[styles.row, {justifyContent: 'space-between'}]}>
        <View style={styles.row2}>
          <View style={{ paddingLeft: 8 }}>
          <ParcelImg width={responsiveHeight(5)} />
          </View>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            variant="titleSmall"
            >
            {title}
          </Text>
          </View>
        {!!parcelStatus && <StatusTagByRole status={parcelStatus} />}
      </View>
    </CardRow>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  row2: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '50%'
  },
});
