import {View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import React from "react";

export const Loading = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}>
      <ActivityIndicator/>
    </View>
  );
}
