import {ParcelApiResponse} from '@resources/Parcel.apiResponse';
import {DateTime} from 'luxon';
import {AttachmentViewModel} from './Attachment.viewModel';
import {KanguroPointViewModel} from './KanguroPoint.viewModel';
import {RecipientViewModel} from './Recipient.viewModel';
import {DeliveryDriverViewModel} from './DeliveryDriver.viewModel';
import {ParcelStatus} from '@config/Parcel.status';
import {ParcelStatusChangeViewModel} from '@viewModels/ParcelStatusChange.viewModel';

export class ParcelViewModel {
  id: string;
  lockerId: string;
  groupId: string;
  originalAddress: string;
  status: ParcelStatus;
  EAN: string;
  attachments: Array<AttachmentViewModel>;
  kanguroPoint: KanguroPointViewModel;
  recipient: RecipientViewModel | null;
  deliveryDriver: DeliveryDriverViewModel | null;
  createdAtDate: DateTime;
  updatedAtDate: DateTime;
  statusChanges: Array<ParcelStatusChangeViewModel>;

  cancelDate: DateTime | null;
  putInDate: DateTime | null;
  putOutDate: DateTime | null;

  static fromApiResponses(apiResponses: Array<ParcelApiResponse>) {
    return apiResponses.map(apiResponse => new ParcelViewModel(apiResponse));
  }

  constructor(apiResponse: ParcelApiResponse) {
    this.id = apiResponse.id;
    this.lockerId = apiResponse.lockerId;
    this.groupId = apiResponse.groupId;
    this.originalAddress = apiResponse.originalAddress;
    this.status = apiResponse.status as ParcelStatus;
    this.createdAtDate = DateTime.fromISO(apiResponse.createdAt);
    this.updatedAtDate = DateTime.fromISO(apiResponse.updatedAt);
    this.EAN = apiResponse.EAN;
    this.attachments = apiResponse.attachments.map(
        attachment => new AttachmentViewModel(attachment),
    );
    this.kanguroPoint = new KanguroPointViewModel(apiResponse.kanguroPoint);
    this.recipient = apiResponse.recipient
        ? new RecipientViewModel(apiResponse.recipient)
        : null;
    this.deliveryDriver = apiResponse.deliveryDriver
        ? new DeliveryDriverViewModel(apiResponse.deliveryDriver)
        : null;
    this.statusChanges = apiResponse.statusChanges.map(
        statusChange => new ParcelStatusChangeViewModel(statusChange),
    );
    this.cancelDate = this.lastStatusChangeByStatus(ParcelStatus.Cancelled);
    this.putInDate = this.lastStatusChangeByStatus(ParcelStatus.DroppedOff);
    this.putOutDate = this.lastStatusChangeByStatus(ParcelStatus.Collected);
  }

  private lastStatusChangeByStatus(status: ParcelStatus): DateTime | null {
    const statusChanges = this.statusChangesByStatus(status);
    return statusChanges[statusChanges.length - 1]
        ? statusChanges[statusChanges.length - 1].createdAtDate
        : null;
  }

  private statusChangesByStatus(status: ParcelStatus) {
    return this.statusChanges.filter(
        statusChange => statusChange.status === status,
    );
  }
}
