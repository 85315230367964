import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ProfilePage} from './Profile.page';
import {FAQPage} from '../../common/pages/FAQ.page';
import {EditProfilePage} from './EditProfile.page';
import {ConfigurationPage} from "@modules/profile/pages/Configuration.page";

export const ProfileStack = () => {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="Profile" component={ProfilePage} />
      <Stack.Screen name="EditProfile" component={EditProfilePage} />
      <Stack.Screen name="Configuration" component={ConfigurationPage} />
      <Stack.Screen name="FAQ" component={FAQPage} />
    </Stack.Navigator>
  );
};
