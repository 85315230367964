import {View} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {ReactNode} from 'react';
import {Surface} from 'react-native-paper';

export const Card = ({
  children,
  color = 'white',
                       extraStyle = {},
}: {
  children: ReactNode;
  color?: string;
  extraStyle?: any;
}) => {
  return (
    <Surface
      elevation={2}
      style={{
        backgroundColor: color,
        borderRadius: 10,
        ...extraStyle,
      }}>
      {children}
    </Surface>
  );
};

export const CardRow = ({children}: {children: ReactNode}) => (
  <View
    style={{
      width: '100%',
      paddingVertical: responsiveHeight(1),
      paddingHorizontal: responsiveHeight(2),
    }}>
    {children}
  </View>
);
