import {BottomButton} from '@components/BottomButton';
import {Routes} from '@config/Routes';
import {InvitationNotFoundFeature} from '@modules/auth/features/invitation/InvitationNotFound.feature';
import {InvitationResource} from '@modules/auth/features/invitation/resources/Invitation.resource';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LoginResource} from "@modules/auth/features/login/resources/Login.resource";
import {AxiosResponse} from 'axios';
import {
  InvitationKanguroPointDetailsFeature
} from "@modules/auth/features/invitation/InvitationKanguroPointDetails.feature";
import {InvitationViewModel} from "@viewModels/Invitation.viewModel";
import {Loading} from "@components/Loading";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Toast from "react-native-toast-message";
import {isTypeError, TYPE_ERRORS} from "../../../../utils/errors";
import {View} from "react-native";
import {useAuth} from "@auth/useAuth";
import {isInvitationURL} from "@utils/url";

interface Props {
  invitationId: string;
}

export const InvitationFeature = ({invitationId}: Props) => {
  const {t} = useTranslation('invitation');
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [kanguroPoint, setKanguroPoint] =
    useState<InvitationViewModel | null>(null);
  const { userToken, isProfileCompleted , user, signOut} = useAuth();
  const [check, setCheck] = useState(false);

  const goToVerifyPhone = () => {
    if (kanguroPoint?.recipient) {
      LoginResource.requestCode(kanguroPoint.recipient.phone)
        .then((res: AxiosResponse<{ requestId: string }>) => {
          //@ts-ignore
          navigation.navigate(Routes.auth.VERIFY, {
            requestId: res.data.requestId,
            phone: kanguroPoint.recipient.phone,
          });
        })
        .catch(err => {
          if (isTypeError(err, TYPE_ERRORS.CONCURRENT_VERIFICATION)) {
            Toast.show({
              type: 'error',
              text1: t('invitation_error')!,
              text2: t('invitation_error_description')!,
            });
            return;
          }
        })
        .finally(() => setLoading(false));
    }
  }

  const handleNext = () => {
    if (userToken) {
      const userId = user.id;
      const invitationId = kanguroPoint?.recipient.id
      if (userId !== invitationId) {
        signOut();
        goToVerifyPhone();
      } else {
        setCheck(true)
      }
    } else {
      goToVerifyPhone();
    }
  }

  useEffect(() => {
    if (userToken && isInvitationURL() && check) {
      const nav = isProfileCompleted ? "Receive" : "Signature";
      // @ts-ignore
      navigation.navigate(nav, {
        phone: `${kanguroPoint?.recipient.phone}`,
      });
    }
  }, [isProfileCompleted, userToken, check]);

  useEffect(() => {
    InvitationResource.getInvitation(invitationId)
      .then((response: any) => {
        const kpPoint = new InvitationViewModel(response.data);
        setKanguroPoint(kpPoint);
        setLoading(false)

        AsyncStorage.setItem('invitation_ids', JSON.stringify({
          id: kpPoint.id,
          counterId: kpPoint.counter?.id
        }))
      })
      .catch(() => {
        setLoading(false)
      });
  }, [invitationId]);

  if(loading) {
    return (<Loading/>)
  }

  if (!kanguroPoint) {
    return (<InvitationNotFoundFeature />);
  }
  const isLocker = !!kanguroPoint.locker
  const buttonCaption = isLocker ? t('invitation_locker_submit') : t('invitation_counter_submit');

  return (
    <View style={{ height: '100%' }}>
      <InvitationKanguroPointDetailsFeature kanguroPoint={kanguroPoint} />
      <BottomButton onPress={handleNext} text={buttonCaption} />
    </View>
  );
};
