import {Image, View} from 'react-native';
import {MultiAppConfig} from '@config/MultiAppConfig';

export const SplashScreenPage = () => {
  return (<View
    style={{
      flex: 1,
      backgroundColor: MultiAppConfig.PRIMARY_COLOR,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    }}>
    <Image source={MultiAppConfig.LOGO} resizeMode="center"/>
  </View>);
};
