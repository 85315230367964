import {theme} from '@config/Theme';
import {
  normalize,
  normalizeFont,
  normalizeVertical,
} from '@utils/normalize';
import {StyleSheet} from 'react-native';

const {
  uikit: {
    button: {primary, primaryLight, secondary, text, link, linkDanger},
  },
} = theme;

interface PresetsType {
  [key: string]: {
    pressOut: string[];
    pressIn: string[];
    text: string;
    textHover: string;
    disabled: {
      text: string;
      bgColor: string[];
    };
  };
}

export const styles = StyleSheet.create({
  container: {
    borderRadius: 360,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    paddingHorizontal: normalize(12)
  },
  text: {
    fontSize: normalizeFont(16),
    fontFamily: 'Poppins-Regular',
  },
});

export const sizes = {
  small: normalizeVertical(36),
  medium: normalizeVertical(44),
  large: normalizeVertical(52),
};

export const presetsButtonStyles: PresetsType = {
  primary,
  primaryLight,
  linkDanger,
  secondary,
  link,
  text,
};