import {useAuth} from '@auth/useAuth';
import {StyleSheet, View} from 'react-native';
import KanguroIcon from '@assets/new/svg/yellow.svg';
import {Text} from 'react-native-paper';
import React from 'react';

export const Greeting = () => {
  const {user} = useAuth();
  return (
    <View style={styles.container}>
      <KanguroIcon height={25} width={25} style={styles.image} />
      <Text style={styles.text}>Hola, {user.profile.name}!</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {fontWeight: '700', fontFamily: 'Poppins-Regular'},
  image: {marginRight: 20},
});
