import {getFormattedNumber, getWeekDay} from '@infrastructure/Utils';
import {Text} from 'react-native-paper';
import {theme} from '@config/Theme';
import {KanguroPointViewModel} from '@viewModels/KanguroPoint.viewModel';
import {StyleSheet, View} from "react-native";
import {ScheduleViewModel} from "@viewModels/Schedule.viewModel";
import {useTranslation} from "react-i18next";

interface Schedule {
  dayOfWeek: number;
  open: string;
  close: string;
}

interface Props {
  schedule?: Array<ScheduleViewModel> | null;
  forDetails?: boolean;
}

export const getTodaysSchedule = (
  schedule: Array<Schedule>,
): null | {
  open: Date;
  close: Date;
  nextOpen: Date | null;
} => {
  const today = new Date();
  const day = today.getDay();
  if (!schedule.length) {
    return null;
  }
  // Find the current schedule
  const currentSchedule = schedule.find(s => s.dayOfWeek + 1 === day);
  if (!currentSchedule) {
    // If there is no schedule for the current day, find the next available schedule
    let nextAvailableSchedule = null;
    let nextDayOffset = 1;
    while (!nextAvailableSchedule) {
      const nextDay = (day + nextDayOffset) % 7;
      const scheduleForNextDay = schedule.find(
        s => s.dayOfWeek + 1 === nextDay,
      );
      if (scheduleForNextDay) {
        nextAvailableSchedule = scheduleForNextDay;
      }
      nextDayOffset++;
    }

    const open = new Date();
    const close = new Date();
    open.setHours(Number(nextAvailableSchedule.open.split(':')[0]));
    open.setMinutes(Number(nextAvailableSchedule.open.split(':')[1]));
    close.setHours(Number(nextAvailableSchedule.close.split(':')[0]));
    close.setMinutes(Number(nextAvailableSchedule.close.split(':')[1]));

    const nextOpen = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + nextDayOffset - 1,
    );
    nextOpen.setHours(Number(nextAvailableSchedule.open.split(':')[0]));
    nextOpen.setMinutes(Number(nextAvailableSchedule.open.split(':')[1]));

    return {
      open,
      close,
      nextOpen,
    };
  }

  const intervals = schedule.filter(s => s.dayOfWeek + 1 === day);
  const now = new Date();
  for (const interval of intervals) {
    const open = new Date();
    const close = new Date();
    open.setHours(Number(interval.open.split(':')[0]));
    open.setMinutes(Number(interval.open.split(':')[1]));
    close.setHours(Number(interval.close.split(':')[0]));
    close.setMinutes(Number(interval.close.split(':')[1]));
    if (now < open) {
      return {
        open,
        close,
        nextOpen: open,
      };
    } else if (now >= open && now < close) {
      return {
        open,
        close,
        nextOpen: null,
      };
    }
  }
  // Find the next available schedule for the next day
  let nextDayOffset = 1;
  let nextAvailableSchedule = null;
  while (!nextAvailableSchedule) {
    const nextDay = (day + nextDayOffset) % 7;
    const scheduleForNextDay = schedule.find(s => s.dayOfWeek + 1 === nextDay);
    if (scheduleForNextDay) {
      nextAvailableSchedule = scheduleForNextDay;
    }
    nextDayOffset++;
  }

  const nextOpen = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + nextDayOffset - 1,
  );
  nextOpen.setHours(Number(nextAvailableSchedule.open.split(':')[0]));
  nextOpen.setMinutes(Number(nextAvailableSchedule.open.split(':')[1]));

  return {
    open: new Date(), //intervals[0].open,
    close: new Date(), //, intervals[intervals.length - 1].close,
    nextOpen,
  };
};

export const TodaySchedule = ({schedule, forDetails = false}: Props) => {
  const { t } = useTranslation('common');

  if (!schedule) {
    return null;
  }

  const openClose = getTodaysSchedule(schedule);
  if (!openClose) {
    return <Text style={{color: theme.colors.error}}>{t('todaySchedule_closed')}</Text>;
  }

  const fontSize = forDetails ? 16 : 12;
  const textSchedule = openClose.nextOpen
    ? t('todaySchedule_opens', { weekday: getWeekDay(openClose.nextOpen), hour: getFormattedNumber(openClose.nextOpen.getHours()), minute: getFormattedNumber(openClose.nextOpen.getMinutes()) })
    : t('todaySchedule_closes', { hour: getFormattedNumber(openClose.close?.getHours()!), minute: getFormattedNumber(openClose.close?.getMinutes()!) })

  return (
    <View style={styles.container}>
      <Text style={[{ fontSize }, openClose.nextOpen ? styles.close : styles.open ]}>{ openClose.nextOpen ? t('todaySchedule_closed') : t('todaySchedule_opened') }</Text>
      <Text>-</Text>
      <Text style={{ fontSize }}>
        {textSchedule}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5
  },
  open: {
    color: theme.colors.success
  },
  close: {
    color: theme.colors.error
  }
})
