import {Text} from 'react-native-paper';
import {theme} from '@config/Theme';
import {View} from 'react-native';
import {useTranslation} from 'react-i18next';

export const Version = () => {
  const {t} = useTranslation();
  const packagejson = require('../../../package.json');
  const service = packagejson.name;
  const version = packagejson.version;


  return (
    <View>
      <Text style={{textAlign: 'center', color: theme.colors.backdrop}}>
        {t('Version')} {service} {version}
      </Text>
    </View>
  );
};
