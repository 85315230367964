import * as Yup from 'yup';
import {ROLE} from '@config/MultiAppConfig';

const emailRegex =
  /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(?!.*\.{2})[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~\.]*[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+@(?![\-])[a-zA-Z0-9](?:[a-zA-Z0-9\-]*[a-zA-Z0-9])?(\.[a-zA-Z]{2,63})+$/;

export const EditProfileRequest = {
  getValidationSchema() {
    return Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().matches(emailRegex, 'Invalid email format'),
      phone: Yup.string().required('Phone is required'),
      countryCode: Yup.string().required('Country Code is required'),
      dni: Yup.string().required('DNI is required'),
      imageUrl: Yup.string(),
    });
  },
  getInitialValues(
    name = '',
    email = '',
    dni = '',
    countryCode = '+34',
    phone = '',
    imageUrl = '',
  ) {
    return {name, email, dni, countryCode, phone, imageUrl};
  },
};
