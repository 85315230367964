import React, {useContext, useMemo, useReducer, useRef} from "react";
import {SignatureContext, SignatureContextType} from "@components/signature/Signature.context";
import {useEventDispatch} from "@events/hooks/useEventDispatch";

enum SignatureActionType {
  SET_START_DRAWING = 'SET_START_DRAWING',
  SET_DRAWING = 'SET_DRAWING',
  SET_CONTEXT_REF = 'SET_CONTEXT_REF',
  SET_FILE = 'SET_FILE',
}

interface SignatureAction {
  type: SignatureActionType;
  payload: any;
}

export interface LinePoints {
  x: Array<number>;
  y: Array<number>;
}

interface State extends Partial<SignatureContextType> {
  canvasRef: React.Ref<any>;
  contextRef: React.Ref<any>;
  signatureRef: React.Ref<Array<LinePoints>>;
  isDrawing: boolean;
  UICoordinates: object;
  file: File | null;
  signaturePoints: Array<LinePoints>;
}

const signatureReducer = (state: State, action: SignatureAction): State => {
  const {type, payload} = action;
  const actions = {
    [SignatureActionType.SET_START_DRAWING]: {
      ...state,
      isDrawing: true,
      UICoordinates: payload.coords,
      signatureRef: { current: payload.signaturePoints },
    },
    [SignatureActionType.SET_CONTEXT_REF]: {
      ...state,
      contextRef: payload.context,
      UICoordinates: payload.coords,
    },
    [SignatureActionType.SET_FILE]: {
      ...state,
      file: payload.file,
      signaturePoints: payload.signaturePoints,
      isDrawing: false,
    },
  };
  return actions[type as keyof typeof actions] || state;
};

interface Props {
  children: any;
}

export const SignatureProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(signatureReducer, {
    canvasRef: useRef(null),
    contextRef: useRef(null),
    signatureRef: useRef([]),
    isDrawing: false,
    UICoordinates: {},
    file: null,
    signaturePoints: [],
  });
  const dispatchEvent = useEventDispatch();

  const signatureContextValue: SignatureContextType = useMemo(
    () => ({
      ...state,
      prepareCanvas: () => {
        // @ts-ignore
        const canvas = state.canvasRef?.current;
        const coords = canvas.getBoundingClientRect()

        canvas.width = coords.width;
        canvas.height = 200;
        canvas.style.width = `${coords.width}px`;
        canvas.style.height = `${200}px`;
        canvas.style.backgroundColor = "white";
        canvas.borderRadius = 10;
        canvas.style.borderRadius = "10px";

        const context = canvas.getContext("2d");
        context.lineCap = "round";
        context.strokeStyle = "black";
        context.lineJoin='round';
        context.lineWidth = 3;
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);

        dispatch({
          type: SignatureActionType.SET_CONTEXT_REF,
          payload: {context, coords},
        });
      },
      startDrawingMobile: ({ nativeEvent }: any) => {
        dispatchEvent('startDrawing');
        // @ts-ignore
        const canvas = state.canvasRef?.current;
        const coords = canvas.getBoundingClientRect()

        // @ts-ignore
        const clientX = nativeEvent.touches[0].clientX - coords.x
        // @ts-ignore
        const clientY = nativeEvent.touches[0].clientY - coords.y

        // @ts-ignore
        state.contextRef?.beginPath();
        // @ts-ignore
        state.contextRef?.moveTo(clientX, clientY);

        // @ts-ignore
        const signaturePoints = state.signatureRef?.current;
        signaturePoints.push({x: [clientX], y: [clientY]})

        dispatch({
          type: SignatureActionType.SET_START_DRAWING,
          payload: {coords, signaturePoints},
        });
      },
      drawMobile: ({ nativeEvent }: any) => {
        if (!state.isDrawing) {
          return;
        }
        // @ts-ignore
        const offsetX = nativeEvent.touches[0].clientX - state.UICoordinates.x
        // @ts-ignore
        const offsetY = nativeEvent.touches[0].clientY - state.UICoordinates.y

        // @ts-ignore
        const canvas = state.canvasRef?.current;
        const coords = canvas.getBoundingClientRect()

        // @ts-ignore
        const signaturePoints = state.signatureRef?.current;
        if (offsetX > coords.width || offsetX < 0 || offsetY > 200 || offsetY < 0) {
          return
        } else {
          signaturePoints[signaturePoints.length - 1].x.push(offsetX)
          signaturePoints[signaturePoints.length - 1].y.push(offsetY)
        }

        // @ts-ignore
        state.contextRef?.lineTo(offsetX, offsetY);
        // @ts-ignore
        state.contextRef?.stroke();
      },
      finishDrawing: () => {
        dispatchEvent('finishDrawing');
        // @ts-ignore
        state.contextRef?.closePath();
        // @ts-ignore
        const canvas = state.canvasRef?.current;
        // @ts-ignore
        const signaturePoints = state.signatureRef?.current;

        canvas.toBlob((blob: Blob) => {
          let file = new File([blob], "fileName.jpg", { type: "image/jpeg" })

          dispatch({
            type: SignatureActionType.SET_FILE,
            payload: {file, signaturePoints},
          });
        }, 'image/jpeg');
      },
      clearCanvas: () => {
        // @ts-ignore
        const canvas = state.canvasRef?.current;
        const context = canvas.getContext("2d");

        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);

        // @ts-ignore
        const signaturePoints = state.signatureRef?.current;
        signaturePoints.splice(0, signaturePoints.length)

        dispatch({
          type: SignatureActionType.SET_FILE,
          payload: {file: null},
        });
      },
    }),
    [state],
  );

  return (
    <SignatureContext.Provider value={signatureContextValue}>
      {children}
    </SignatureContext.Provider>
  );
};

export const useSignature = () => useContext(SignatureContext);
