import {Text} from 'react-native-paper';
import {TouchableOpacity} from 'react-native';

interface Props {
  text: string;
  color?: string;
  onPress: () => void;
}
export const TextButton = ({onPress, text, color}: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Text
        style={{
          textDecorationLine: 'underline',
          textAlign: 'center',
          color: color,
        }}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};
