import {LinePoints} from "@components/signature/Signature.provider";

export class EditProfileApiRequest {
  name: string;
  surname: string;
  email: string;
  imageUrl: string | null;
  dni: string;
  signature: string | null;
  signaturePoints: Array<LinePoints> | null = []

  constructor(data: any) {
    this.name = data.name;
    this.surname = data.surname;
    this.email = data.email;
    this.imageUrl = data.imageUrl;
    this.dni = data.dni;
    this.signature = data.signature;
    this.signaturePoints = data.signaturePoints;
  }
}
