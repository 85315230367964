import {ReceiveDeliveryPage} from '../recipient/pages/receive-delivery/ReceiveDelivery.page';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ParcelDeliveredPage} from "@modules/recipient/pages/receive-delivery/ParcelDelivered.page";
import {SignaturePage} from "@modules/invitation/pages/Signature.page";
import {InvitationPage} from "@modules/auth/pages/Invitation.page";
import {VerifyPage} from "@modules/auth/pages/Verify.page";

export type InvitationDeliveryStack = {
  VERIFY: { requestId: string; phone: string; countryCode: string };
  Invitation: { invitationId: string };
  Receive: { id: string, counterId: string };
  Signature: undefined;
  Confirm: undefined;
}
export const InvitationDeliveryStack = ({route}: any) => {
  const Stack = createNativeStackNavigator<InvitationDeliveryStack>();

  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={ "Invitation" }
    >
      <Stack.Screen name={'Invitation'} component={InvitationPage}/>
      <Stack.Screen
        name="Receive"
        component={ReceiveDeliveryPage}
        initialParams={{id: '0', counterId: '0'}}
      />
      <Stack.Screen name="Signature" component={SignaturePage} />
      <Stack.Screen name="Confirm" component={ParcelDeliveredPage} />
      <Stack.Screen name={'VERIFY'} component={VerifyPage}/>
    </Stack.Navigator>
  );
};
