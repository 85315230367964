import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  codeFieldRoot: {
    marginTop: 20,
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    flex: 1,
  },
  input: {
    // @ts-ignore
    outlineStyle: 'none',
  },
  cellCard: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  cell: {
    width: 50,
    height: 50,
    lineHeight: 50,
    fontSize: 24,
    borderWidth: 0,
    textAlign: 'center',
    alignSelf: 'center',
    textAlignVertical: 'center',
    color: 'black',
  },
  focusCell: {
    borderColor: '#000',
    borderWidth: 0.5,
    borderRadius: 10,
  },
});
