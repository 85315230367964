import {List, ProgressBar, Text} from 'react-native-paper';
import {View} from 'react-native';
import {Card} from '@components/Card';
import {useQuery} from 'react-query';
import {ListFaqsResource} from './resources/ListFaqs.resource';
import {ListFaqsApiResponse} from './resources/ListFaqs.apiResponse';
import {AxiosResponse} from 'axios';
import {Divider} from '@components/Divider';

export const ListFaqsFeature = () => {
  const {data, isLoading, error} = useQuery<
    AxiosResponse<ListFaqsApiResponse>,
    Error
  >('faqs', ListFaqsResource.list);
  if (isLoading) {
    return <ProgressBar indeterminate />;
  }
  if (error) {
    return <Text>{error.message}</Text>;
  }
  if (!data) {
    return <Text>No data</Text>;
  }
  return (
    <>
      <Card>
        <List.AccordionGroup>
          {data.data.items
            .map(faq => (
              <List.Accordion
                id={faq.id}
                key={faq.id}
                title={faq.question}
                titleStyle={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'black',
                }}
                style={{paddingVertical: 0}}
                titleNumberOfLines={4}
                right={props => (
                  <List.Icon
                    icon={props.isExpanded ? 'chevron-down' : 'chevron-right'}
                    {...props}
                  />
                )}>
                <View style={{padding: 20}}>
                  <Text>{faq.answer}</Text>
                </View>
              </List.Accordion>
            ))
            // @ts-ignore
            .reduce((prev, curr, i) => [prev, <Divider key={i} />, curr])}
        </List.AccordionGroup>
      </Card>
    </>
  );
};
