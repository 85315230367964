import {RecipientApiResponse} from '@resources/Recipient.apiResponse';
import {ProfileViewModel} from './Profile.viewModel';

export class RecipientViewModel {
  public id: string;
  public profile: ProfileViewModel;

  constructor(apiResponse: RecipientApiResponse) {
    this.id = apiResponse.id;
    this.profile = new ProfileViewModel(apiResponse.profile);
  }
}
