import { KanguroPointCard } from '@components/delivery/KanguroPoint.card';
import { Header } from '@components/Header';
import { CurrentKanguroPointViewModel } from '@viewModels/CurrentKanguroPoint.viewModel';
import React, {useEffect} from 'react';
import {Image, RefreshControl, ScrollView, View} from 'react-native';
import {ProgressBar, Text} from 'react-native-paper';
import {useQuery} from 'react-query';
import {ListCurrentKanguroPointsResource} from './resources/ListCurrentKanguroPoints.resource';
import {theme} from '@config/Theme';
import {useTranslation} from "react-i18next";
import UserParcelsEmpty from '@assets/img/user-parcels-empty.png';
import Geolocation from "@react-native-community/geolocation";

interface Props {
  openDelivery: (currentKanguroPoint: CurrentKanguroPointViewModel) => void;
}

export const ListCurrentKanguroPointsFeature = ({openDelivery}: Props) => {
  const {t} = useTranslation('recipient');
  const [coordinates, setCoordinates] = React.useState({latitude: 0, longitude: 0});
  const {
    data: kanguroPoints,
    isLoading,
    error,
    refetch,
  } = useQuery(['parcels', coordinates], ListCurrentKanguroPointsResource.list(coordinates));

  useEffect(() => {
    Geolocation.getCurrentPosition((info => {
      setCoordinates({
        latitude: info.coords.latitude,
        longitude: info.coords.longitude,
      });
    }));
  }, []);

  if (isLoading || !kanguroPoints) {
    return <ProgressBar indeterminate />;
  }
  const parcelNumber = kanguroPoints
    .map((kanguroPoint: any) => kanguroPoint.parcelsCount)
    .reduce((a: any, b: any) => a + b, 0);

  if (parcelNumber === 0) {
    return (
      <>
        <Header
          greeting
          title={t('listCurrentKanguroPoints_feature_title')!}
          backgroundColor={theme.colors.gray}
        />
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            paddingTop: 20,
            backgroundColor: theme.colors.gray,
          }}>
          <View>
            <Text variant="bodyMedium" style={{paddingHorizontal: 20}}>
              {t('listCurrentKanguroPoints_feature_description')!}
            </Text>
          </View>
          <Image source={UserParcelsEmpty} style={{bottom: 0, width: '100%', height: '100%', resizeMode: 'contain'}} />
        </View>
      </>
    );
  }

  return (
    <>
      <Header
        greeting
        subtitle={t('listCurrentKanguroPoints_feature_subtitle')!}
        backgroundColor={theme.colors.gray}
      />
      <ScrollView
        style={{flex: 1}}
        contentContainerStyle={{
          paddingHorizontal: 20,
          position: 'relative',
          backgroundColor: theme.colors.gray,
        }}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }>
        {kanguroPoints.map((kanguroPoint: any) => (
          <View key={kanguroPoint.id} style={{marginTop: 15}}>
            <KanguroPointCard
              onPress={() => openDelivery(kanguroPoint)}
              kanguroPoint={kanguroPoint}
              type={'recipient'}
            />
          </View>
        ))}
      </ScrollView>
    </>
  );
};
