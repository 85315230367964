import {View} from 'react-native';
import {ReactNode} from 'react';
import {responsiveHeight} from 'react-native-responsive-dimensions';

interface StackProps {
  children: Array<ReactNode>;
  spacing: number;
  style?: any;
  direction?: 'row' | 'column';
}
export const Stack = ({
  children,
  spacing,
  style = {},
  direction = 'column',
}: StackProps) => {
  return (
    <View style={{flexDirection: direction}}>
      {children.map((child, index) => (
        <View
          key={index}
          style={{
            marginTop: index > 0 ? responsiveHeight(spacing) : 0,
            ...style,
          }}>
          {child}
        </View>
      ))}
    </View>
  );
};
