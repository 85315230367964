import {Image, useWindowDimensions, View} from 'react-native';
import {theme} from '@config/Theme';
import React from 'react';
import SmallLogo from '@assets/img/logo-blanc-groc.png';

export const TempHeader = () => {
  const {height, width} = useWindowDimensions();
  return (
    <View style={[styles.container, {height: height * 0.15}]}>
      <Image source={SmallLogo} style={styles.image} resizeMode="contain" />
    </View>
  );
};

const styles = {
  container: {
    width: '100%',
    justifyContent: 'flex-end' as 'flex-end',
    alignItems: 'center' as 'center',
    overflow: 'hidden' as 'hidden',
    position: 'relative' as 'relative',
    zIndex: 0,
    backgroundColor: theme.colors.primary,
  },
  image: {
    width: 75,
    height: 75,
    top: '-20%',
  },
};
