import {Linking, StyleSheet, View} from 'react-native';
import {theme} from '../../../config/Theme';
import {useAuth} from '@auth/useAuth';
import {Card} from '@components/Card';
import {Appbar, List} from 'react-native-paper';
import {Stack} from '@components/Stack';
import {useTranslation} from 'react-i18next';
import {Version} from '@components/Version';
import {useEventDispatch} from '@events/hooks/useEventDispatch';
import {EventTypes} from '@events/Event.types';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';

interface Props {
  navigation: any;
}

export const ProfilePage = ({navigation}: Props) => {
  const {user, signOut} = useAuth();
  const {t} = useTranslation('profile');
  const dispatch = useEventDispatch();
  const goTo = (screen: string) => () => {
    navigation.navigate(screen);
  };

  const openAbout = () => {
    Linking.openURL('https://kanguro.com');
  };
  const logout = () => {
    signOut();
    dispatch(EventTypes.PROFILE.LOGOUT);
  };
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: theme.colors.background}}>
      <Appbar.Header
        mode="center-aligned"
        statusBarHeight={0}
        style={{backgroundColor: 'transparent'}}>
        <Appbar.Content
          title={t('profile_page_title')!}
          titleStyle={styles.title}
        />
      </Appbar.Header>
      <View
        style={{
          flex: 1,
          paddingTop: 20,
          paddingHorizontal: 20,
          justifyContent: 'space-between',
        }}>
        <Stack spacing={2}>
          <Card>
            <List.Item
              onPress={goTo('EditProfile')}
              title={t('profile_page_edit_profile')}
              left={props => <List.Icon icon="account" {...props} />}
              right={props => <List.Icon icon="chevron-right" {...props} />}
            />
          </Card>
          <View>
            <Card>
              {/*<List.Item
                onPress={goTo('FAQ')}
                title={'Preguntas frequentes'}
                left={props => <List.Icon icon="chat" {...props} />}
                right={props => <List.Icon icon="chevron-right" {...props} />}
              />
              <Divider />*/}
              <List.Item
                onPress={openAbout}
                title={t('profile_page_about')!}
                left={props => <List.Icon icon="information" {...props} />}
                right={props => <List.Icon icon="chevron-right" {...props} />}
              />
            </Card>
          </View>
          <Card>
            <List.Item
              onPress={goTo('Configuration')}
              title={t('profile_page_config')!}
              left={props => <List.Icon icon="cog" {...props} />}
              right={props => <List.Icon icon="chevron-right" {...props} />}
            />
          </Card>
          <Card>
            <List.Item
              onPress={logout}
              title={t('profile_page_logout')!}
              left={props => <List.Icon icon="logout" {...props} />}
              right={props => <List.Icon icon="chevron-right" {...props} />}
            />
          </Card>
        </Stack>
        <View style={{paddingBottom: 20}}>
          <Version />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  title: {fontWeight: 'bold', fontSize: 20, color: 'black'},
});
