import {Stack} from '@components/Stack';
import {ProgressBar, Text} from 'react-native-paper';
import {Linking, TouchableWithoutFeedback, View, StyleSheet} from 'react-native';
import {useAuth} from '@auth/useAuth';
import React, {useState} from 'react';
import Toast from 'react-native-toast-message';
import {useTranslation} from 'react-i18next';
import {BodyGray} from '@components/texts/BodyGray';
import {KTextInput} from '@components/KTextInput';
import {Formik, useFormik} from "formik";
import {SignatureRequest} from "@modules/invitation/features/resources/Signature.request";
import {Signature} from "@components/signature/Signature";
import {RegisterResource} from "@modules/auth/features/register/resources/Register.resource";
import {Routes} from "@config/Routes";
import {useNavigation} from "@react-navigation/native";
import {isInvitationURL} from "../../../utils/url";
import {EditProfileApiRequest} from "@modules/profile/features/edit-profile/resources/EditProfile.apiRequest";
import {EditProfileResource} from "@modules/profile/features/edit-profile/resources/EditProfile.resource";
import {SignatureResource} from "@modules/invitation/features/resources/Signature.resource";
import {LinePoints, SignatureProvider} from "@components/signature/Signature.provider";
import {theme} from "@config/Theme";
import {BottomButton} from "@components/BottomButton";

interface Props {
  title: string;
  phone?: string;
}

export const SignatureFeature = ({phone, title}: Props) => {
  const {t} = useTranslation('invitation');
  const {user} = useAuth();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  const form = useFormik({
    initialValues: SignatureRequest.getInitialValues({phone: phone || user.profile.phone, ...user?.profile}),
    validationSchema: SignatureRequest.getValidationSchema(),
    onSubmit<Values>(values: Values) {
      setLoading(true);
      submit(values);
    },
  });

  const openTermsAndConditions = () => {
    Linking.openURL('https://kanguro.com/terminos-y-condiciones/');
  };

  const openPrivacyPolicy = () => {
    Linking.openURL('https://kanguro.com/politica-de-privacidad/');
  };

  const submit = (values: any) => {
    setLoading(true);
    isInvitationURL() ? submitUpdate(values) : submitRegister(values)
  };

  const getSignature = (file: File | null, signaturePoints: Array<LinePoints>) => {
    form.setFieldValue('signature', file);
    form.setFieldValue('signaturePoints', signaturePoints);
  }

  const showTooManyRequests = () => {
    Toast.show({
      type: 'error',
      text1: t('signature_error')!,
      text2: t('signature_requirement')!,
    });
  }

  const submitRegister = (values: any) => {
    const formData = new FormData();
    if (values.signature === null) {
      showTooManyRequests();
      return;
    }
    formData.append('file', values.signature);
    SignatureResource.uploadSignature(formData)
      .then((SignatureResponse) => {
        const data = {...values, signature: SignatureResponse.data.url};
        RegisterResource.register(data)
          .then((response) => {
            //@ts-ignore
            navigation.navigate(Routes.auth.VERIFY, {
              requestId: response.data.requestId,
              phone,
            });
          })
          .catch(() => {
            Toast.show({
              type: 'error',
              text1: t('signature_error')!,
              text2: t('signature_error_message')!,
            });
          })
          .finally(() => setLoading(false));
      })
  }

  const submitUpdate = (values: any) => {
    const formData = new FormData();
    if (values.signature === null) {
      showTooManyRequests();
      return;
    }
    formData.append('file', values.signature);
    SignatureResource.uploadSignature(formData)
      .then((SignatureResponse) => {
        const data = new EditProfileApiRequest({...values, signature: SignatureResponse.data.url});
        EditProfileResource.save(data)
          .then(() => {
            //@ts-ignore
            navigation.navigate('Receive');
          })
          .catch(() => {
            Toast.show({
              type: 'error',
              text1: t('signature_error')!,
              text2: t('signature_error_message')!,
            });
          })
          .finally(() => setLoading(false));
      })
  }

  return (
    <>
      {(loading && <ProgressBar indeterminate />)}
      <Formik
        initialValues={form}
        onSubmit={values => {
          setLoading(true);
          submit(values);
        }}>
        <TouchableWithoutFeedback>
          <View style={{justifyContent: 'space-between', flex: 1}}>
            <View
              style={{
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}>
              <Stack spacing={2}>
                <BodyGray>
                  {title}
                </BodyGray>
                <KTextInput
                  label="Nombre"
                  name="name"
                  form={form}
                />
                <KTextInput
                  label="Email"
                  textContentType="emailAddress"
                  autoCapitalize="none"
                  name="email"
                  form={form}
                />
                <KTextInput
                  label="NIF"
                  name="dni"
                  form={form}
                />
                <SignatureProvider>
                  <Signature getSignatureFile={getSignature} />
                </SignatureProvider>
              </Stack>
              <Text style={styles.information}>
                {t('signature_form_agreements_1')} {' '}
                <Text style={styles.textlink} onPress={openTermsAndConditions}>
                  {t('signature_form_agreements_2')}
                </Text>{' '}
                {t('signature_form_agreements_3')}
                <Text style={styles.textlink} onPress={openPrivacyPolicy}>
                  {' '}
                  {t('signature_form_agreements_4')}
                </Text>
              </Text>
            </View>
            <BottomButton onPress={form.handleSubmit} text={t('signature_form_submit')} />
          </View>
        </TouchableWithoutFeedback>
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  information: {
    color: theme.colors.info,
    fontSize: 12,
    fontFamily: 'Poppins-Regular',
  },
  textlink: {
    fontSize: 11,
    color: '#9796A1',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  }
});
