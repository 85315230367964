import {ParcelStatus} from '@config/Parcel.status';
import {CounterViewModel} from '@viewModels/Counter.viewModel';
import {DeliveryDriverViewModel} from '@viewModels/DeliveryDriver.viewModel';
import {RecipientViewModel} from '@viewModels/Recipient.viewModel';
import {ParcelStatusChangeApiResponse} from '@resources/ParcelStatusChange.apiResponse';
import {DateTime} from 'luxon';

export class ParcelStatusChangeViewModel {
  id: string;
  admin: string | null;
  counter: CounterViewModel | null;
  createdAtDate: DateTime;
  deliveryDriver: DeliveryDriverViewModel | null;
  recipient: RecipientViewModel | null;
  status: ParcelStatus;

  constructor(apiResponse: ParcelStatusChangeApiResponse) {
    this.id = apiResponse.id;
    this.admin = apiResponse.admin;
    this.counter = apiResponse.counter
      ? new CounterViewModel(apiResponse.counter)
      : null;
    this.createdAtDate = DateTime.fromISO(apiResponse.createdAt);
    this.deliveryDriver = apiResponse.deliveryDriver
      ? new DeliveryDriverViewModel(apiResponse.deliveryDriver)
      : null;
    this.recipient = apiResponse.recipient
      ? new RecipientViewModel(apiResponse.recipient)
      : null;
    this.status = apiResponse.status;
  }
}
