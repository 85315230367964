import {Image, SafeAreaView, ScrollView, View} from 'react-native';
import {Button, Text} from 'react-native-paper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {theme} from '@config/Theme';
import UserParcelsEmpty from '@assets/img/user-parcels-empty.png';

interface Props {
  error: any;
  resetErrorBoundary: () => void;
}
const translations = {
  errorFallback_message: 'Vaya, parece que algo ha ido mal: {{msg}}',
  errorFallback_description: 'Vaya, parece que algo ha ido mal',
  errorFallback_tryAgain: 'Intentar de nuevo',
}
export const ErrorFallback = ({error, resetErrorBoundary}: Props) => {
  //const {t} = useTranslation('common');
  const t = (key: keyof typeof translations, params?: any) => {
    let translation = translations[key];
    if (params) {
      Object.keys(params).forEach((paramKey) => {
        translation = translation.replace(`{{${paramKey}}}`, params[paramKey]);
      });
    }
    return translation;
  }
  if (__DEV__) {
    return (
      <SafeAreaView style={{flex: 1}}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}>
          <Text>{t('errorFallback_message', {msg: error.message})}</Text>
          <Text>{JSON.stringify(error).substring(0, 100)}</Text>
          {resetErrorBoundary && (
            <Button onPress={resetErrorBoundary}>
              {t('errorFallback_tryAgain')}
            </Button>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: 'space-between',
        paddingVertical: 20,
        backgroundColor: theme.colors.gray,
      }}>
      <View>
        <Text variant="bodyMedium" style={{paddingHorizontal: 20}}>
          {t('errorFallback_description')!}
        </Text>
      </View>
      <Image source={UserParcelsEmpty} style={{bottom: 0, width: '100%', height: '100%', resizeMode: 'contain'}} />
      {resetErrorBoundary && (
        <Button onPress={resetErrorBoundary}>
          {t('errorFallback_tryAgain')}
        </Button>
      )}
    </SafeAreaView>
  );
};
