import React, {useMemo, useState} from 'react';
import {
  Dimensions, Image,
  RefreshControl,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import {ProgressBar, Searchbar, Text} from 'react-native-paper';
import {HistoryResource} from './resources/History.resource';
import {useQuery} from 'react-query';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {theme} from '@config/Theme';
import {useEvent} from '@events/hooks/useEvent';
import {EventTypes} from '@events/Event.types';
import {ParcelCardHeader} from '@components/recipient-parcel/ParcelCardHeader';
import {Card} from '@components/Card';
import {useTranslation} from 'react-i18next';
import {CustomButtom} from '@components/CustomButton';
import UserParcelsEmpty from '@assets/img/user-parcels-empty.png';

interface Props {
  goToDelivery: (id: string, phone: string) => void;
}

const {width} = Dimensions.get('window');
export const HistoryFeature = ({goToDelivery}: Props) => {
  const {t} = useTranslation('recipient');
  const {
    data: parcels,
    isLoading,
    refetch,
  } = useQuery('history', HistoryResource.list);
  const [searchQuery, setSearchQuery] = useState('');

  useEvent(
    [
      EventTypes.PARCEL.COLLECT,
      EventTypes.PARCEL.CANCELLED,
      EventTypes.PARCEL.INVALID_COUNTER,
      EventTypes.PARCEL.VALID,
      EventTypes.PARCEL.UPDATED,
    ],
    () => {
      refetch();
    },
  );

  const groupedParcelsByDate = useMemo(() => {
    if (!parcels) {
      return;
    }
    return parcels
      .filter(p => p.recipient !== null)
      .filter(
        p =>
          p.EAN.includes(searchQuery) ||
          (p.recipient?.profile.name &&
            p.recipient?.profile.name.includes(searchQuery)) ||
          p.recipient?.profile.phone.includes(searchQuery),
      )
      .reduce((acc, delivery) => {
        const date = delivery.updatedAtDate.toFormat('dd LLL yyyy');
        // @ts-ignore
        if (!acc[date]) {
          // @ts-ignore
          acc[date] = [];
        }
        // @ts-ignore
        acc[date].push(delivery);
        return acc;
      }, {}) as unknown as {[key: string]: ParcelViewModel[]};
  }, [parcels, searchQuery]);

  const isEmpty = useMemo(() => {
    if (!groupedParcelsByDate) {
      return true;
    }
    return Object.keys(groupedParcelsByDate).length === 0;
  }, [groupedParcelsByDate]);

  if (isLoading) {
    return <ProgressBar indeterminate />;
  }

  return (
    <>
      <View style={{paddingHorizontal: 20, paddingVertical: 10}}>
        <Searchbar
          value={searchQuery}
          onChangeText={setSearchQuery}
          style={{
            borderRadius: 10,
            backgroundColor: 'white',
            borderWidth: 1,
            borderColor: theme.colors.gray,
          }}
          placeholder={t('history_feature_searchBar_placeHolder')!}
          placeholderTextColor={theme.colors.onBackground}
        />
      </View>
      {isEmpty || !groupedParcelsByDate ? (
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              paddingTop: 20,
            }}>
            <View>
              <Text variant="bodyMedium" style={{paddingHorizontal: 20}}>
                {t('history_feature_empty_content')!}
              </Text>
            </View>
            {searchQuery === '' && (
              <View>
                <CustomButtom
                  onPress={() => refetch()}
                  text={t('history_feature_empty_button')!}
                  loading={isLoading}
                />
              </View>
            )}
            <Image source={UserParcelsEmpty} style={{bottom: 0, width: '100%', height: '100%', resizeMode: 'contain'}} />
          </View>
        </View>
      ) : (
        <ScrollView
          style={{flex: 1}}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
          refreshControl={
            <RefreshControl refreshing={isLoading} onRefresh={refetch} />
          }>
          <View style={{flex: 1}}>
            {Object.keys(groupedParcelsByDate).map((date, i) => (
              <View key={date + i} style={{marginTop: 20}}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    paddingLeft: 20,
                    paddingBottom: 15,
                  }}>
                  {date}
                </Text>
                <View style={{paddingHorizontal: 20}}>
                  {groupedParcelsByDate[date].map(
                    (delivery: ParcelViewModel) => (
                      <TouchableOpacity
                        onPress={() =>
                          goToDelivery(
                            delivery.id,
                            delivery.recipient?.profile.phone!,
                          )
                        }
                        style={{marginBottom: 10}}
                        key={delivery.id}>
                        <Card>
                          <ParcelCardHeader
                            key={delivery.recipient!.id}
                            title={delivery.EAN}
                            parcelStatus={delivery.status}
                          />
                        </Card>
                      </TouchableOpacity>
                    ),
                  )}
                </View>
              </View>
            ))}
          </View>
        </ScrollView>
      )}
    </>
  );
};
