import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#000',
    maxWidth: '80%',
    marginBottom: 20,
  },
  textdesc: {
    color: '#9796A1',
  },
  textlink: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  entry: {
    width: '70%',
  },
  pre: {
    width: '30%',
  },
  elipse: {
    position: 'absolute',
  },
  keyboard: {
    paddingHorizontal: 30,
    paddingTop: 10,
    justifyContent: 'space-between',
    flex: 1,
  },
  form: {
    flex: 1,
  },
  incidenceButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
});
