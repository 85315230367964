import React, {useEffect, useRef, useState} from 'react';
import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {KanguroPointViewModel} from '@viewModels/KanguroPoint.viewModel';
import {Animated, ScrollView, View} from 'react-native';
import {theme} from '@config/Theme';
import {KanguroPointDirection} from '@components/kanguroPoint/KanguroPoint.direction';
import {Loading} from "@components/Loading";
import {useKpMainImage} from "@components/useKPMainImage";
import {ParcelList} from "@components/ParcelList";
import {KPImagesCarousel} from "@components/KPImagesCarousel";
import {KanguroPointSchedule} from "@components/KanguroPointSchedule";
import CustomHeader from "@components/CustomHeader";
import {useNavigation} from "@react-navigation/native";

interface Props {
  id: string;
  onLoad?: (kanguroPoint: KanguroPointViewModel) => void;
}

export const KanguroPointDetailsFeature = ({id, onLoad}: Props) => {
  const [kanguroPoint, setKanguroPoint] =
    useState<KanguroPointViewModel | null>(null);
  const navigation = useNavigation();
  const scrollViewRef = useRef<any>(new Animated.Value(0));
  const imageKp = useKpMainImage(kanguroPoint);

  useEffect(() => {
    const load = async () => {
      const res = await customAxios.get(Endpoints.kanguroPoints.getById(id) + '?address=Damascus%2C%20Kassa&pageNumber=0&pageSize=10');
      setKanguroPoint(new KanguroPointViewModel(res.data));
      onLoad?.(new KanguroPointViewModel(res.data));
    };
    load();
  }, [id, onLoad]);

  if (!kanguroPoint) {
    return <Loading />;
  }

  return (
    <View style={{
      flexGrow: 1,
      height: '100%',
      backgroundColor: theme.colors.background,
    }}>
      <CustomHeader
        title={
          kanguroPoint?.counter
            ? kanguroPoint.counter.name
            : kanguroPoint.locker?.name
        }
        scrollY={scrollViewRef.current}
        navigation={navigation}
        image={imageKp}
      />
      <ScrollView
        ref={scrollViewRef} // Set the ref for the ScrollView
        onScroll={Animated.event(
          [{nativeEvent: {contentOffset: {y: scrollViewRef.current}}}],
          {
            useNativeDriver: false,
          },
        )}
        scrollEventThrottle={16}
        // @ts-ignore
        style={{flex: 1, backgroundColor: theme.colors.background, gap: 10, paddingBottom: 400}}>
        <KanguroPointDirection
          latitude={kanguroPoint?.latitude}
          longitude={kanguroPoint?.longitude}
          address={kanguroPoint?.address}
          phone={kanguroPoint?.counter?.phone}
          //distance={kanguroPoint.distance}
        />
        <KanguroPointSchedule schedule={kanguroPoint.counter?.schedule} />
        <ParcelList kanguroPointId={id} />
        <KPImagesCarousel kanguroPoint={kanguroPoint} />
      </ScrollView>
    </View>
  );
};
