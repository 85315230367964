import {Text} from 'react-native-paper';
import React, {ReactNode} from 'react';

export const H1Text = ({
                         children,
                         props,
                       }: {
  children: ReactNode;
  [key: string]: any;
}) => {
  return (
    <Text
      style={{
        fontSize: 28,
        fontWeight: 'bold',
        fontFamily: 'Poppins-Bold',
        ...props,
      }}>
      {children}
    </Text>
  );
};
