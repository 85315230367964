import {Text, View} from 'react-native';
import {forwardRef, Ref, useImperativeHandle, useState} from 'react';
import {styles} from './CodeVerification.styles';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import {Card} from '@components/Card';

interface Props {
  numberOfInputs: number;
  onFilled: (value: string) => void;
}

const CodeVerificationComponent = (
  {numberOfInputs, onFilled}: Props,
  ref: Ref<unknown> | undefined,
) => {
  const [value, setValue] = useState('');
  const codeFieldRef = useBlurOnFulfill({value, cellCount: numberOfInputs});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  useImperativeHandle(ref, () => ({
    empty,
  }));
  const empty = () => {
    setValue('');
    codeFieldRef.current!.focus();
  };

  const onChange = (text: string) => {
    setValue(text);
    if (text.length === numberOfInputs) {
      onFilled(text);
    }
  };

  return (
    <View style={styles.container}>
      <CodeField
        autoFocus
        ref={codeFieldRef}
        {...props}
        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
        value={value}
        onChangeText={onChange}
        cellCount={numberOfInputs}
        rootStyle={styles.codeFieldRoot}
        keyboardType="number-pad"
        textContentType="none"
        textInputStyle={styles.input}
        renderCell={({index, symbol, isFocused}) => (
          <Card key={index} extraStyle={styles.cellCard}>
            <Text
              style={[styles.cell, isFocused && styles.focusCell]}
              onLayout={getCellOnLayoutHandler(index)}>
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          </Card>
        )}
      />
    </View>
  );
};

export const CodeVerification = forwardRef(CodeVerificationComponent);
