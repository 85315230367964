import { MultiAppConfig } from '@config/MultiAppConfig';
import mqtt from '@taoqf/react-native-mqtt';
import {MQTT_URL} from '../../config/Endpoints';
import {useEffect} from 'react';
import {useEventDispatch} from '../event-system/hooks/useEventDispatch';
/*
topics: /userType/phone

mqtt payloads:
when counter get a delivery by code, the following sent to the client:
{"type":"DELIVERY-VALIDATION","data":{"deliveryId":"002"}}

when counter confirm parcels, the following sent to the client:
{"type":"DELIVERY-CONFIRM-PARCELS","data":{"deliveryId":"001"}}

when client validate a delivery, the following sent to the counter:
{"type":"DELIVERY-VALID","data":{"deliveryId":"002"}}

when client validate a delivery (invalid-client), the following sent to the counter:
{"type":"DELIVERY-INVALID","data":{"deliveryId":"002"}}
 */
export const useMqtt = (role: string, id: string) => {
  const eventDispatch = useEventDispatch();
  useEffect(() => {
    if (!id) {
      return;
    }
    const client = mqtt.connect(MQTT_URL, {
      clientId: `${role}-${id}`,
      clean: true,
    });
    client.on('connect', function (conn) {
      client.subscribe(`/recipient/${id}`, function (err) {
        if (err) {
          console.log('Err subscribe', err);
          return;
        }
      });
    });

    client.on('message', function (topic, message) {
      //console.log('mqtt-message', topic, message.toString());
      const msg = JSON.parse(message.toString());
      eventDispatch(msg.type, msg.data);
      console.log('mqtt message', msg);
      //client.end();
    });
    client.on('error', function (err) {
      //console.log('mqttError', err);
    });
  }, [eventDispatch, id, role]);
};
