import {AttachmentViewModel} from './Attachment.viewModel';
import {CounterViewModel} from './Counter.viewModel';
import {CurrentKanguroPointApiResponse} from '@resources/CurrentKanguroPoint.apiResponse';
import {LockerViewModel} from '@viewModels/Locker.viewModel';
import {RecipientViewModel} from "@viewModels/Recipient.viewModel";
import {DistanceViewModel} from "@viewModels/Distance.viewModel";

export class CurrentKanguroPointViewModel {
  id: string;
  address: string;
  latitude: number;
  longitude: number;
  attachments: AttachmentViewModel[];
  distance: string | null;
  distanceObject: DistanceViewModel | null;

  isPrivate: boolean;
  code: string;

  counter: CounterViewModel | null;
  locker: LockerViewModel | null;
  recipient: RecipientViewModel | null;
  parcelsCount: number;

  //lockers: LockerViewModel[]; TODO

  constructor(apiResponse: CurrentKanguroPointApiResponse) {
    this.id = apiResponse.id;
    this.address = apiResponse.address;
    this.latitude = apiResponse.latitude;
    this.longitude = apiResponse.longitude;
    this.attachments = apiResponse.attachments.map(
      item => new AttachmentViewModel(item),
    );
    this.distance = apiResponse.distance ? apiResponse.distance : null;
    this.distanceObject = apiResponse.distanceObject ? new DistanceViewModel(apiResponse.distanceObject) : null;
    this.isPrivate = apiResponse.isPrivate;
    this.code = apiResponse.code;
    this.counter = apiResponse.counter
      ? new CounterViewModel(apiResponse.counter)
      : null;
    this.locker = apiResponse.locker
      ? new LockerViewModel(apiResponse.locker)
      : null;
    this.recipient = apiResponse.recipient
      ? new RecipientViewModel(apiResponse.recipient)
      : null;
    this.parcelsCount = apiResponse.parcelsCount;
  }
}
