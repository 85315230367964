import {theme} from '@config/Theme';
import {
  normalize,
  normalizeVertical,
} from '@utils/normalize';
import {StyleSheet} from 'react-native';

const {
  uikit: {dialog},
} = theme;

export const styles = StyleSheet.create({
  modalWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: dialog.background,
    borderRadius: normalize(16),
    minHeight: normalizeVertical(100),
    width: '90%',
    paddingHorizontal: normalize(18),
    paddingVertical: normalize(28),
  },
  buttonsContianer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: normalize(16),
  },
  title: {
    marginBottom: normalize(16),
  },
  divider: {
    marginTop: normalize(12),
  },
  header: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  closeMark: {
    padding: normalize(4),
  },
});
