import UserParcelsEmpty from '@assets/img/user-parcels-empty.png';
import { Header } from '@components/Header';
import { theme } from '@config/Theme';
import React from 'react';
import { Image, View } from 'react-native';
import { Text } from 'react-native-paper';
import {CustomButtom} from "@components/CustomButton";
import {setHomeURL} from "@utils/url";
import {useTranslation} from "react-i18next";

export const InvitationNotFoundFeature = () => {
  const { t } = useTranslation('invitation');
  return (
    <>
      <Header
        title={t('invitationNotFound_title')!}
        backgroundColor={theme.colors.gray}
      />
      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
          paddingTop: 20,
          backgroundColor: theme.colors.gray,
        }}>
        <View>
          <Text variant="bodyMedium" style={{paddingHorizontal: 20}}>
            {t('invitationNotFound_subtitle')}
          </Text>
        </View>
        <Image
          // @ts-ignore
          style={{width: '100%', height: 300}}
          source={UserParcelsEmpty}
          resizeMode="center"
        />
        <View style={{ paddingVertical: 20 }}>
          <CustomButtom onPress={() => setHomeURL()} text={t('invitationNotFound_submit')!} />
        </View>
      </View>
    </>
  )
}
