import {Divider as MDivider} from 'react-native-paper';

interface Props {
  height?: number;
}

export const Divider = ({height}: Props) => {
  const dashed = {
    borderWidth: 0.3,
    borderColor: '#EEEEEE',
    height: 0,
  };
  const style = height ? {height, backgroundColor: '#EEEEEE'} : dashed;
  return <MDivider style={style} />;
};
/*
style={{
          borderStyle: 'dashed',
          borderWidth: 0.3,
          borderColor: 'gray',
          height: 0,
        }}
 */
