import React from 'react';
import {Avatar, Text} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {Divider} from '@components/Divider';
import {useTranslation} from 'react-i18next';
import {useKpMainImage} from '@components/useKPMainImage';

export const Counter = ({parcel}: {parcel: ParcelViewModel}) => {
  const {t} = useTranslation('common');
  const mainImage = useKpMainImage(parcel.kanguroPoint);

  return (
    <>
      <Divider height={2} />
      <View style={{paddingHorizontal: 20}}>
        <Text style={styles.label}>{t('counter_deliveryPlace')}</Text>
        <View style={styles.icon}>
          {parcel.kanguroPoint?.attachments.length ? (
              <Avatar.Image source={mainImage} size={50} />
            ) : (
              <Avatar.Icon icon="store" size={50} />
            )}
        </View>
          <View
            style={{
              paddingLeft: 70,
              marginTop: 10,
            }}>
            <Text style={styles.text}>
              {parcel.kanguroPoint.counter!.name} - Kanguro
            </Text>
            <Text style={[styles.text]}>{parcel.kanguroPoint!.address}</Text>
          </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
  centeredRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
    top: 30
  }
});
