import { Mqtt } from '@infrastructure/mqtt/Mqtt';
import React, {Suspense} from 'react';
import {StatusBar, useColorScheme, View} from 'react-native';
import {AuthContextProvider} from '@infrastructure/auth/AuthContextProvider';
import {Provider as PaperProvider} from 'react-native-paper';
import {EventEmitter} from '@events/Event.emitter';
import Toast from 'react-native-toast-message';
import '@config/i18n';
import {QueryClient, QueryClientProvider} from 'react-query';
import {NavigationContainer} from '@react-navigation/native';
import {Router} from '@config/Router';
import {theme} from "@config/Theme";
import { useFonts } from 'expo-font';
import {Loading} from "@components/Loading";
import {ErrorFallback} from "@components/ErrorFallback";
import {KErrorBoundary} from "@components/KErrorBoundary";
import {GeoLocationProvider} from "@infrastructure/context/geolocation/GeoLocation.context";
import GeoLocationTracker from "@infrastructure/context/geolocation/GeoLocationTracker";

let customFonts = {
  'AntDesign': require('./assets/fonts/AntDesign.ttf'),
  'Entypo': require('./assets/fonts/Entypo.ttf'),
  'EvilIcons': require('./assets/fonts/EvilIcons.ttf'),
  'Feather': require('./assets/fonts/Feather.ttf'),
  'FontAwesome': require('./assets/fonts/FontAwesome.ttf'),
  'FontAwesome5_Brands': require('./assets/fonts/FontAwesome5_Brands.ttf'),
  'FontAwesome5_Regular': require('./assets/fonts/FontAwesome5_Regular.ttf'),
  'FontAwesome5_Solid': require('./assets/fonts/FontAwesome5_Solid.ttf'),
  'Fontisto': require('./assets/fonts/Fontisto.ttf'),
  'Foundation': require('./assets/fonts/Foundation.ttf'),
  'IonIcons': require('./assets/fonts/Ionicons.ttf'),
  'MaterialCommunityIcons': require('./assets/fonts/MaterialCommunityIcons.ttf'),
  'MaterialIcons': require('./assets/fonts/MaterialIcons.ttf'),
  'Montserrat-Bold': require('./assets/fonts/Montserrat-Bold.ttf'),
  'Montserrat-Light': require('./assets/fonts/Montserrat-Light.ttf'),
  'Montserrat-Medium': require('./assets/fonts/Montserrat-Medium.ttf'),
  'Montserrat-Regular': require('./assets/fonts/Montserrat-Regular.ttf'),
  'Obviously-Bold': require('./assets/fonts/Obviously-Bold.ttf'),
  'Obviously-Octicons': require('./assets/fonts/Octicons.ttf'),
  'Poppins-Bold': require('./assets/fonts/Poppins-Bold.ttf'),
  'Poppins-Medium': require('./assets/fonts/Poppins-Medium.ttf'),
  'Poppins-Regular': require('./assets/fonts/Poppins-Regular.ttf'),
  'Quicksand-Bold': require('./assets/fonts/Quicksand-Bold.ttf'),
  'Quicksand-Light': require('./assets/fonts/Quicksand-Light.ttf'),
  'Quicksand-Medium': require('./assets/fonts/Quicksand-Medium.ttf'),
  'Quicksand-Regular': require('./assets/fonts/Quicksand-Regular.ttf'),
  'Roboto-Black': require('./assets/fonts/Roboto-Black.ttf'),
  'Roboto-BlackItalic': require('./assets/fonts/Roboto-BlackItalic.ttf'),
  'Roboto-Bold': require('./assets/fonts/Roboto-Bold.ttf'),
  'Roboto-BoldItalic': require('./assets/fonts/Roboto-BoldItalic.ttf'),
  'Roboto-Italic': require('./assets/fonts/Roboto-Italic.ttf'),
  'Roboto-Light': require('./assets/fonts/Roboto-Light.ttf'),
  'Roboto-LightItalic': require('./assets/fonts/Roboto-LightItalic.ttf'),
  'Roboto-Medium': require('./assets/fonts/Roboto-Medium.ttf'),
  'Roboto-MediumItalic': require('./assets/fonts/Roboto-MediumItalic.ttf'),
  'Roboto-Regular': require('./assets/fonts/Roboto-Regular.ttf'),
  'Roboto-Thin': require('./assets/fonts/Roboto-Thin.ttf'),
  'Roboto-ThinItalic': require('./assets/fonts/Roboto-ThinItalic.ttf'),
  'SimpleLineIcons': require('./assets/fonts/SimpleLineIcons.ttf'),
  'Zocial': require('./assets/fonts/Zocial.ttf')
};

const App = () => {
  const isDarkMode = useColorScheme() === 'dark';
  const queryClient = new QueryClient();
  const [isLoaded] = useFonts(customFonts);

  if (!isLoaded) {
    return <Loading/>;
  }

  return (
    <KErrorBoundary FallbackComponent={ErrorFallback}>
      <PaperProvider theme={theme}>
        <EventEmitter>
          <View style={{flex: 1}}>
            <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'}/>

            <QueryClientProvider client={queryClient}>
              <>
                <AuthContextProvider>
                  <>
                    <Suspense fallback={<Loading/>}>
                      <GeoLocationProvider>
                        <NavigationContainer>
                          <GeoLocationTracker />
                          <Router/>
                        </NavigationContainer>
                      </GeoLocationProvider>
                    </Suspense>
                    <Mqtt/>
                  </>
                </AuthContextProvider>
                <Toast/>
              </>
            </QueryClientProvider>
          </View>
        </EventEmitter>
      </PaperProvider>
    </KErrorBoundary>
  );
};

export default App;
