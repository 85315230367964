import {AttachmentViewModel} from './Attachment.viewModel';
import {KanguroPointApiResponse} from '@resources/KanguroPoint.apiResponse';
import {CounterViewModel} from './Counter.viewModel';
import {LockerViewModel} from '@viewModels/Locker.viewModel';

export class KanguroPointViewModel {
  id: string;
  address: string;
  latitude: number;
  longitude: number;
  attachments: AttachmentViewModel[];

  isPrivate: boolean;
  code: string;

  counter: CounterViewModel | null;
  distance: string | null;

  locker: LockerViewModel | null;

  constructor(apiResponse: KanguroPointApiResponse) {
    this.id = apiResponse.id;
    this.address = apiResponse.address;
    this.latitude = apiResponse.latitude;
    this.longitude = apiResponse.longitude;
    this.attachments = apiResponse.attachments.map(
      item => new AttachmentViewModel(item),
    );
    this.isPrivate = apiResponse.isPrivate;
    this.code = apiResponse.code;
    this.counter = apiResponse.counter
      ? new CounterViewModel(apiResponse.counter)
      : null;
    //this.distance = parseFloat(apiResponse.distance).toFixed(2);
    this.distance = apiResponse.distance ? apiResponse.distance : null;
    this.locker = apiResponse.locker
      ? new LockerViewModel(apiResponse.locker)
      : null;
  }
}
