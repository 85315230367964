import {View} from 'react-native';
import {theme} from '@config/Theme';
import {BackTopBar} from '@components/BackTopBar';
import {EditProfileFeature} from '../features/edit-profile/EditProfile.feature';
import {useTranslation} from 'react-i18next';

interface Props {
  navigation: any;
}

export const EditProfilePage = ({navigation}: Props) => {
  const {t} = useTranslation('profile');
  const back = () => {
    navigation.goBack();
  };

  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <BackTopBar text={t('editProfile_page_title')!} back={back} />
      <EditProfileFeature />
    </View>
  );
};
