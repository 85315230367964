import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {theme} from '@config/Theme';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import React from 'react';
import {DistanceViewModel} from "@viewModels/Distance.viewModel";

interface Props {
  distance: DistanceViewModel | null;
  forDetails?: boolean;
}

export const Distance = ({distance = null , forDetails}: Props) => {
  const fontSize = forDetails ? 16 : 12;
  const iconSize = forDetails ? 16 : 14;
  const color = forDetails ? 'black' : theme.colors.info;

  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <MaterialCommunityIcons name="walk" size={iconSize} color={color} />
      <View>
        <Text style={[styles.title, {fontSize, color}]}>
          {distance?.value.toFixed(2)} {distance?.unitOfMeasure}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Poppins-Regular',
    fontSize: 12,
    color: theme.colors.info,
  },
});
