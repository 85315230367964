import {ScheduleApiResponse} from '../resources/Schedule.apiResponse';

export class ScheduleViewModel {
  id: string;
  open: string;
  close: string;
  dayOfWeek: number;
  interval: number;

  constructor(apiResponse: ScheduleApiResponse) {
    this.id = apiResponse.id;
    this.open =
      apiResponse.open.split(':')[0] + ':' + apiResponse.open.split(':')[1];
    this.close =
      apiResponse.close.split(':')[0] + ':' + apiResponse.close.split(':')[1];
    this.dayOfWeek = apiResponse.dayOfWeek;
    this.interval = apiResponse.interval;
  }
}
