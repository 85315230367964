import React, {useEffect, useRef} from 'react';
import {Animated, Dimensions, Easing, Image} from 'react-native';
import BigLogo from '@assets/img/logo-blanc-groc.png';
import SmallLogo from '@assets/img/smallLogo.png';
import {theme} from '@config/Theme';
import Parcel1 from '@assets/new/img/package.png';

interface Props {
  isExpanded: boolean;
}

const {height, width} = Dimensions.get('window');

export const AnimatedHeader = ({isExpanded}: Props) => {
  const animationValue = useRef(new Animated.Value(0)).current;

  const heightValue = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [height * 0.5, height * 0.15],
  });

  const opacityLargeImage = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  });

  const opacitySmallImage = animationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  useEffect(() => {
    Animated.timing(animationValue, {
      toValue: isExpanded ? 0 : 1,
      duration: 500,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: false,
    }).start();
  }, [isExpanded]);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          height: heightValue,
        },
      ]}>
      {/*<Image style={styles.image} source={SmallLogo} />*/}
      <Animated.Image
        source={SmallLogo}
        style={[styles.image, {opacity: opacityLargeImage}]}
        resizeMode="contain"
      />
      <Animated.Image
        source={BigLogo}
        style={[styles.image, {opacity: opacitySmallImage}]}
        resizeMode="contain"
      />
    </Animated.View>
  );
};
const styles = {
  container: {
    width: '100%',
    justifyContent: 'flex-end' as 'flex-end',
    alignItems: 'center' as 'center',
    overflow: 'hidden' as 'hidden',
    position: 'relative' as 'relative',
    zIndex: 0,
    backgroundColor: theme.colors.primary,
  },
  image: {
    width: 75,
    height: 75,
    top: '-20%',
  },
  semiCircle: {
    left: '50%',
    width: width, // Assuming your SVG takes up the full width
    transform: [{translateX: -(width / 2)}], // Shifts left by half the SVG width to center it
    zIndex: 1,
    position: 'absolute',
  },
};
