import {Stack} from '@components/Stack';
import {Avatar, ProgressBar, TextInput} from 'react-native-paper';
import {Linking, ScrollView, TouchableOpacity, View,} from 'react-native';

import {useAuth} from '@auth/useAuth';
import React, {useMemo, useState} from 'react';
import {EditProfileResource} from './resources/EditProfile.resource';
import {DeleteProfileFeature} from '../delete-profile/DeleteProfile.feature';
import Toast from 'react-native-toast-message';
import {useTranslation} from 'react-i18next';
import {BodyGray} from '@components/texts/BodyGray';
import {KTextInput} from '@components/KTextInput';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {theme} from '@config/Theme';
import {Formik, useFormik} from 'formik';
import {EditProfileRequest} from '@modules/profile/features/edit-profile/resources/EditProfile.request';
import {useNavigation} from '@react-navigation/native';
import {PhoneInput} from '@components/phone-input/PhoneInput';
import * as ImagePicker from 'expo-image-picker';
import {normalizeHorizontal} from '@utils/normalize';
import { Button } from '@components/UIKit';

export const EditProfileFeature = () => {
  const {t} = useTranslation('profile');
  const {user, signIn, userToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const form = useFormik({
    initialValues: EditProfileRequest.getInitialValues(
      user.profile.name,
      user.profile.email,
      user.profile.dni,
      user.profile.phone?.split('-')[0],
      user.profile.phone?.split('-')[1],
      user.profile.imageUrl || '',
    ),
    validationSchema: EditProfileRequest.getValidationSchema(),
    onSubmit<Values>(values: Values) {
      setLoading(true);
      submit(values);
    },
  });

  const profileGoingToUpdate = useMemo(() => {
    return (
      user.profile.name !== form.values.name ||
      user.profile.email !== form.values.email ||
      user.profile.dni !== form.values.dni ||
      user.profile.phone?.split('-')[0] !== form.values.countryCode ||
      user.profile.phone?.split('-')[1] !== form.values.phone ||
      user.profile.imageUrl !== form.values.imageUrl
    );
  }, [form.values, user.profile]);

  const submit = (values: any) => {
    setLoading(true);
    EditProfileResource.save({
      ...values,
      phone: `${values.countryCode}-${values.phone}`,
    })
      .then(() => {
        Toast.show({
          type: 'success',
          text1: t('editProfile_feature_feedback_title')!,
          text2: t('editProfile_feature_feedback_text')!,
        });
        signIn(userToken);
        // @ts-ignore
        navigation.goBack();
      })
      .catch(() => {
        Toast.show({
          type: 'error',
          text1: t('editProfile_feature_error_title')!,
          text2: t('editProfile_feature_feedback_text')!,
        });
      })
      .finally(() => setLoading(false));
  };

  function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  const pickImage = async () => {
    let permission = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (permission.status !== 'granted') {
      Toast.show({
        type: 'error',
        text1: t('editProfile_feature_camera_error_title')!,
        text2: t('editProfile_feature_camera_error_description')!,
      });
      setTimeout(() => {
        Linking.openSettings();
      }, 1000);
      return;
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      allowsMultipleSelection: false,
      base64: false,
      quality: 1,
    });

    if (!result.canceled) {
      const image = DataURIToBlob(result.assets[0].uri)
      const formData = new FormData();
      formData.append("file", image, 'profileImage');
      EditProfileResource.upload(formData).then(res => {
        form.setFieldValue('imageUrl', res.data.url);
      });
    }
  };

  return (
    <>
      {loading && <ProgressBar indeterminate />}
      <Formik
        initialValues={form}
        onSubmit={values => {
          setLoading(true);
          submit(values);
        }}>
        <ScrollView>
          <View style={{justifyContent: 'space-between'}}>
            <View
              style={{
                paddingVertical: normalizeHorizontal(16),
                paddingHorizontal:  normalizeHorizontal(16),
              }}>
              <Stack spacing={1}>
                <View style={{ alignSelf: 'center' }}>
                  <BodyGray>{t('editProfile_feature_description')!}</BodyGray>
                </View>
                <View style={{alignSelf: 'center'}}>
                  <TouchableOpacity onPress={pickImage}>
                    {form.values.imageUrl ? (
                      <Avatar.Image
                        source={{uri: form.values.imageUrl}}
                        size={80}
                      />
                    ) : (
                      <Avatar.Text
                        label={form.values.name ? form.values.name[0] : ''}
                      />
                    )}
                    <View
                      style={{
                        position: 'absolute',
                        bottom: 2,
                        right: 2,
                        backgroundColor: theme.colors.gray,
                        padding: 2,
                        borderRadius: 50,
                      }}>
                      <MaterialCommunityIcons
                        name="pencil"
                        size={20}
                        color="gray"
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <KTextInput
                  label={t('editProfile_feature_name_label')!}
                  left={<TextInput.Icon icon="account" color="gray" />}
                  name="name"
                  form={form}
                />
                <KTextInput
                  label={t('editProfile_feature_email_label')!}
                  name="email"
                  autoCapitalize="none"
                  textContentType="emailAddress"
                  inputMode="email"
                  left={<TextInput.Icon icon="email-outline" color="gray" />}
                  form={form}
                />
                <KTextInput
                  label={t('editProfile_feature_dni_label')!}
                  name="dni"
                  left={
                    <TextInput.Icon
                      icon="card-account-details-outline"
                      color="gray"
                    />
                  }
                  disabled={user.profile.dni}
                  form={form}
                />
                <PhoneInput
                  value={{
                    phone: form.values.phone,
                    countryCode: form.values.countryCode,
                  }}
                  onChange={({phone, countryCode}) => {
                    if (phone === '' || !isNaN(parseInt(phone, 10))) {
                      form.setFieldValue('phone', phone);
                    }
                    form.setFieldValue('countryCode', countryCode);
                  }}
                  name="phone"
                  disabled
                  form={form}
                />
              </Stack>
            </View>
            <View style={{marginBottom: 20, alignItems: 'center', justifyContent: 'center'}}>
              <Button
                size="medium"
                onPress={form.handleSubmit}
                text={t('editProfile_feature_submit')!}
                disabled={!profileGoingToUpdate}
              />
              <DeleteProfileFeature />
            </View>
          </View>
        </ScrollView>
      </Formik>
    </>
  );
};
