import {StyleSheet, View} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Text} from 'react-native-paper';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  code: string;
}

export const ParcelCode = ({code}: Props) => {
  const {t} = useTranslation('common');
  return (
    <View style={{paddingHorizontal: 20}}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          // @ts-ignore
          gap: 10,
        }}>
        <MaterialCommunityIcons name={'barcode'} size={20} />
        <Text style={styles.label}>
          {t('deliveryDetail_feature_parcelCode')!}
        </Text>
      </View>
      <Text style={styles.text}>{code}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
});
