import {styles} from '@modules/auth/features/login/Login.styles';
import {
  Linking,
  Pressable,
  useWindowDimensions,
  View,
} from 'react-native';
import {Text} from 'react-native-paper';
import React, {useMemo, useReducer} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Routes} from '@config/Routes';
import {LoginResource} from './resources/Login.resource';
import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';
import {BodyGray} from '@components/texts/BodyGray';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {PhoneInput} from '@components/phone-input/PhoneInput';
import {Shadow} from 'react-native-shadow-2';
import {AnimatedHeader} from '@components/AnimatedHeader';
import {H1Text} from '@components/texts/H1.text';
import { Button } from '@components/UIKit';

interface Props {}

const initialState = {
  phone: '',
  error: '',
  loading: false,
  countryCode: '+34',
};

const reducer = (state: any, action: any) => {
  const actions = {
    SET_PHONE: () => ({
      ...state,
      phone: action.payload.phone,
      countryCode: action.payload.countryCode,
    }),
    SET_ERROR: () => ({...state, error: action.payload}),
    SET_LOADING: () => ({...state, loading: action.payload}),
  };
  return actions[action.type as keyof typeof actions]();
};

export const LoginFeature = ({}: Props) => {
  const {t} = useTranslation('auth');
  const navigation = useNavigation();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {setPhone, setError, setLoading} = {
    setPhone: (value: {phone: string; countryCode: string}) =>
      dispatch({type: 'SET_PHONE', payload: value}),
    setError: (error: string) => dispatch({type: 'SET_ERROR', payload: error}),
    setLoading: (loading: boolean) =>
      dispatch({type: 'SET_LOADING', payload: loading}),
  };

  const openTermsAndConditions = () => {
    Linking.openURL('https://kanguro.com/terminos-y-condiciones/');
  };

  const submit = () => {
    if (state.phone < 9) {
      return;
    }
    setError('');
    setLoading(true);
    LoginResource.requestCode(`${state.countryCode}-${state.phone}`)
      .then((res: AxiosResponse<{requestId: string}>) => {
        AsyncStorage.setItem('requestId', res.data.requestId);
        //@ts-ignore
        navigation.navigate(Routes.auth.VERIFY, {
          requestId: res.data.requestId,
          phone: `${state.countryCode}-${state.phone}`,
        });
      })
      .catch(err => {
        console.log(err.response);
        if (err.response.data.error === "NotFoundException") {
          setError(t('login_user_not_found_error'));
        } else {
          setError(t('login_feature_error'));
        }
      })
      .finally(() => setLoading(false));
  };

  const buttonDisabled = useMemo(() => {
    return !(
      !state.loading &&
      state.phone.length >= 4 &&
      state.phone.length <= 15
    );
  }, [state.loading, state.phone]);

  const buttonText = useMemo(() => {
    return state.loading
      ? t('login_feature_loading')
      : t('login_feature_submit');
  }, [state.loading]);

  const {height} = useWindowDimensions();

  return (
    <View>
      <AnimatedHeader isExpanded={isExpanded} />
      <Pressable style={{height}}>
        <View style={styles.box}>
          <View style={{padding: 20, flex: 1}}>
            <H1Text style={{textAlign: 'center'}}>
              {t('login_page_title')!}
            </H1Text>
            <BodyGray>
              {t('login_feature_subtitle')}{' '}
              <BodyGray
                style={styles.textlink}
                onPress={openTermsAndConditions}>
                {' '}
                {t('login_feature_terms')}
              </BodyGray>{' '}
            </BodyGray>
            <View style={{paddingTop: 50}}>
              <Shadow style={{borderRadius: 10, alignSelf: 'auto'}}>
                <PhoneInput
                  value={{phone: state.phone, countryCode: state.countryCode}}
                  onChange={setPhone}
                  onSubmitEditing={submit}
                  onFocus={() => setIsExpanded(false)}
                />
              </Shadow>
            </View>
            <Text style={styles.error}>{state.error}</Text>
            <View style={styles.buttonContainer}>
              <Button
                onPress={submit}
                disabled={buttonDisabled}
                text={buttonText}
              />
            </View>
          </View>
        </View>
      </Pressable>
    </View>
  );
};
