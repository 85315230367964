import {StyleSheet, TouchableOpacity, View} from "react-native";
import {TodaySchedule} from "@components/TodaySchedule";
import {Arrow} from "@components/Arrow";
import {KanguroPointScheduleList} from "@components/kanguroPoint/KanguroPointScheduleList";
import React from "react";
import {Text} from "react-native-paper";
import {useTranslation} from "react-i18next";
import {ScheduleViewModel} from "@viewModels/Schedule.viewModel";

interface Props {
  schedule?: Array<ScheduleViewModel> | null
}

export const KanguroPointSchedule = ({schedule}: Props) => {
  const {t} = useTranslation('common')
  const [menuOpened, setMenuOpened] = React.useState(false)
  const arrowOpened = '-90deg'
  const arrowClosed = '90deg'

  if (!schedule) return null;

  return (
    <View style={styles.container}>
      <Text style={styles.title} variant="headlineSmall">
        {t('kanguropointSchedule_title')}
      </Text>
      <View style={styles.dropDownContainer}>
        <TouchableOpacity onPress={() => setMenuOpened(!menuOpened)}>
          <View style={styles.dropDownBar}>
            <TodaySchedule schedule={schedule} forDetails />
            <View style={{paddingRight: 8}}>
              <Arrow rotate={menuOpened ? arrowOpened : arrowClosed} />
            </View>
          </View>
        </TouchableOpacity>
        {
          menuOpened && (
            <View>
              <KanguroPointScheduleList schedule={schedule}/>
            </View>
          )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
  title: {
    marginBottom: 6,
    fontSize: 24,
    fontWeight: '600',
    fontFamily: 'Poppins-Regular',
  },
  dropDownContainer: {
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  dropDownBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
});
