import {Dialog, Paragraph, Portal} from 'react-native-paper';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DeleteProfileResource} from './resources/DeleteProfile.resource';
import Toast from 'react-native-toast-message';
import {useAuth} from '@auth/useAuth';
import {View} from "react-native";
import { Button } from '@components/UIKit';

export const DeleteProfileFeature = () => {
  const {t} = useTranslation('profile');
  const {signOut} = useAuth();
  const [visible, setVisible] = useState(false);
  const unsubscribe = () => {
    DeleteProfileResource.delete()
      .then(() => {
        Toast.show({
          type: 'success',
          text1: t('deleteProfile_feature_feedback_title')!,
          text2: t('deleteProfile_feature_feedback_text')!,
        });
        signOut();
      })
      .catch(err => {
        Toast.show({
          type: 'error',
          text1: t('deleteProfile_feature_error_title')!,
          text2: t('deleteProfile_feature_error_text')!,
        });
      });
  };
  return (
    <>
      <View style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          type="linkDanger"
          onPress={() => setVisible(true)}
          text={t('deleteProfile_feature_submit')!}
         />
      </View>
      <Portal>
        <Dialog visible={visible} onDismiss={() => setVisible(false)}>
          <Dialog.Title>Alert</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              {t('deleteProfile_feature_description')}
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              type="link"
              onPress={() => setVisible(false)}
              text={t('deleteProfile_feature_cancel')!}
            />
            <Button
              type="link"
              onPress={unsubscribe}
              text={t('deleteProfile_feature_submit')!}
            />
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
};
