import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {ParcelViewModel} from '@viewModels/Parcel.viewModel';
import {AxiosResponse} from 'axios';
import {ParcelApiResponse} from '@resources/Parcel.apiResponse';
import {ProgressBar, Text} from 'react-native-paper';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Status} from '@modules/recipient/features/delivery-detail/components/Status';
import {ParcelDates} from '@components/parcel/ParcelDates';
import {theme} from '@config/Theme';
import {Courier} from '@modules/common/features/delivery-detail/components/Courier.component';
import {DeliveryDetailResource} from '@modules/common/features/delivery-detail/resources/DeliveryDetail.resource';
import {Counter} from '@modules/common/features/delivery-detail/components/Counter.component';
import {Recipient} from '@modules/common/features/delivery-detail/components/Recipient.component';
import {ParcelCode} from "@components/ParcelCode";

interface Props {
  id: string;
}

export const DeliveryDetailFeature = ({id}: Props) => {
  const {t} = useTranslation('common');
  const [parcel, setParcel] = useState<ParcelViewModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    DeliveryDetailResource.get(id)
      .then((res: AxiosResponse<ParcelApiResponse>) => {
        setParcel(new ParcelViewModel(res.data));
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (loading) {
    return <ProgressBar indeterminate />;
  }
  if (!parcel) {
    return (
      <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
        <Text> {t('deliverydetail_feature_empty_content')!} </Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.stack}>
        <Status status={parcel.status} />
        <ParcelCode code={parcel.EAN} />
        <ParcelDates parcel={parcel} />
        {parcel.deliveryDriver && <Courier parcel={parcel} />}
        {parcel.kanguroPoint.counter && <Counter parcel={parcel} />}
        {parcel.recipient && <Recipient parcel={parcel} />}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.background,
    flex: 1,
  },
  stack: {
    gap: 15,
    paddingHorizontal: 10,
    paddingVertical: 20,
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  label: {
    fontFamily: 'Poppins-Regular',
    fontSize: 13,
    lineHeight: 19.5,
    fontWeight: '400',
    color: '#9796A1',
  },
  text: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
  },
});
