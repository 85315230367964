import {
  configureFonts,
  MD3LightTheme as DefaultTheme,
  useTheme,
} from 'react-native-paper';
import myColors from './colors.json';
import { normalizeFont } from '@utils/normalize';

const fontConfig = {
  default: {
    fontFamily: 'Poppins-Regular',
    fontWeight: 'normal',
  },
  headlineSmall: {
    //header 4
    fontSize: 16,
    fontWeight: '900',
    fontFamily: 'Poppins-Regular',
  },
  titleSmall: {
    //Header 3
    fontSize: 18,
    fontWeight: '900',
    lineHeight: 40,
    fontFamily: 'Poppins-Regular',
  },
  titleMedium: {
    //Header 2
    fontSize: 21,
    fontWeight: '900',
    fontFamily: 'Poppins-Regular',
  },
  titleLarge: {
    //Header 1
    fontSize: 30,
    fontWeight: '900',
    color: 'black',
    fontFamily: 'Poppins-Regular',
  },
  bodySmall: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    fontFamily: 'Poppins-Regular',
  },
  bodyMedium: {
    fontSize:  normalizeFont(16),
    fontFamily: 'Poppins-Regular',
    color: '#9796A1',
    lineHeight: normalizeFont(24),
    fontWeight: '400',
  },
  bodyLarge: {
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 24,
    fontFamily: 'Poppins-Regular',
  },
};

const appColors = {
  black: '#000000',
  white: '#FFFFFF',
  transparent: 'transparent',
  brand100: '#EBFBFF',
  brand200: '#D1F7FF',
  brand300: '#A3EEFF',
  brand400: '#75E6FF',
  brand500: '#4DDEFF',
  brand600: '#1DD6FF',
  brand700: '#00BBE6',
  brand800: '#00A2C7',
  gray100: '#F4F4F5',
  gray200: '#E4E4E7',
  gray300: '#D4D4D8',
  gray400: '#A1A1A1',
  gray500: '#71717A',
  gray600: '#52525B',
  gray700: '#3F3F46',
  gray800: '#27272A',
  gray900: '#18181B',
  coolGray100: '#F3F4F6',
  coolGray200: '#E5E7EB',
  coolGray300: '#D1D5DB',
  coolGray400: '#9CA3AF',
  coolGray500: '#6B7280',
  coolGray600: '#4B5563',
  coolGray700: '#374151',
  coolGray800: '#1F2937',
  coolGray900: '#111827',
  success100: '#EFFEF7',
  success200: '#DAFEEF',
  success300: '#B8FADD',
  success400: '#81F4C3',
  success500: '#43E5A0',
  success600: '#00C47D',
  success700: '#008D52',
  warning100: '#FFF4ED',
  warning200: '#FFEDD5',
  warning300: '#FECCAA',
  warning400: '#FDAC74',
  warning500: '#FB8A3C',
  warning600: '#F97316',
  warning700: '#EA580C',
  error100: '#FEF2F2',
  error200: '#FEE2E2',
  error300: '#FFC9C9',
  error400: '#FCA5A5',
  error500: '#F15757',
  error600: '#F13737',
  error700: '#DF1F1F',
};

export const theme = {
  ...DefaultTheme,
  // @ts-ignore
  fonts: configureFonts({config: fontConfig, isV3: true}),
  colors: {
    ...DefaultTheme.colors,
    ...myColors,
    primary: '#1DD6FF',
    secondary: '#7B91FF',
    yellow: '#FFDE01',
    success: '#00C48C',
    warning: '#FFA680',
    error: '#FF8087',
    background: '#F5F5F5',
    gray: '#F5F5F5',
    grayIntense: '#D3D1D8',
    info: '#9796A1',
    grayBackground: '#F6F6F6',
    switchActiveBackground: '#98ECFF',
    switchInactiveBackground: '#EAEAEA',
    contrast: '#FFFFFF',
  },
  uikit: {
    colors: appColors,
    button: {
      linkDanger: {
        pressOut: [appColors.transparent, appColors.transparent],
        pressIn: [appColors.transparent, appColors.transparent],
        text: appColors.error600,
        textHover: appColors.error600,
        disabled: {
          text: appColors.coolGray400,
          bgColor: [appColors.transparent, appColors.transparent],
        },
      },
      primary: {
        pressOut: ['#4EDFFF', '#16D1FA'],
        pressIn: ['#4EDFFF', '#01C0EA'],
        text: appColors.coolGray800,
        textHover: appColors.coolGray800,
        disabled: {
          text: appColors.coolGray400,
          bgColor: [appColors.gray300, appColors.gray300],
        },
      },
      primaryLight: {
        pressOut: [appColors.brand200, appColors.brand200],
        pressIn: [appColors.brand300, appColors.brand300],
        text: appColors.coolGray800,
        textHover: appColors.coolGray800,
        disabled: {
          text: appColors.coolGray400,
          bgColor: [appColors.coolGray200, appColors.coolGray200],
        },
      },
      secondary: {
        pressOut: [appColors.coolGray200, appColors.coolGray200],
        pressIn: [appColors.coolGray300, appColors.coolGray300],
        text: appColors.coolGray700,
        textHover: appColors.coolGray700,
        disabled: {
          text: appColors.coolGray400,
          bgColor: [appColors.coolGray200, appColors.coolGray200],
        },
      },
      link: {
        pressOut: [appColors.transparent, appColors.transparent],
        pressIn: [appColors.transparent, appColors.transparent],
        text: '#1890FF',
        textHover: '#0084FF',
        disabled: {
          text: appColors.coolGray400,
          bgColor: [appColors.transparent, appColors.transparent],
        },
      },
      text: {
        pressOut: [appColors.transparent, appColors.transparent],
        pressIn: [appColors.transparent, appColors.transparent],
        text: appColors.coolGray800,
        textHover: appColors.coolGray800,
        disabled: {
          text: appColors.coolGray400,
          bgColor: [appColors.transparent, appColors.transparent],
        },
      },
    },
    form: {
      checkbox: {
        color: appColors.brand600,
        uncheckedColor: appColors.coolGray500,
        disabled: appColors.coolGray500,
      },
      radio: {
        uncheckedColor: appColors.coolGray500,
        color: appColors.brand600,
      },
    },
    pill: {
      bg: {
        success: appColors.success500,
        danger: appColors.error500,
        warning: appColors.warning400,
        info: appColors.brand400
      },
      dark: {
        bgColor: appColors.coolGray100,
        text: appColors.coolGray700,
      },
      light: {
        bgColor: '#FE3348',
        text: appColors.white,
      },
    },
    tag: {
      primary: '#1D95FF',
      success: appColors.success600,
      warning: appColors.warning700,
      danger: appColors.error600,
    },
    avatar: {
      bgColor: '#DBEAFE',
    },
    spinner: {
      color: appColors.brand600,
    },
    dialog: {
      background: appColors.white,
    },
  },
};

export type AppTheme = typeof theme;

export const useAppTheme = () => useTheme<AppTheme>();

/*
old
import {configureFonts, DefaultTheme, useTheme} from 'react-native-paper';
import {responsiveHeight} from 'react-native-responsive-dimensions';


var appTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
    primary: '#F7B500',
    secondary: '#C9C8C7',
    shadedBox: '#efefef',
    navBar: 'white',
    colorTitleSection: '#555',
    colorParagraphItem: '#777',
    courierTextColor: '#76848b',
    text: '#141c27',
  },
  gradients: {
    main: ['black', 'black'],
  },
};
export const theme = {
  ...appTheme,
  fonts: configureFonts({config: fontConfig, isV3: undefined}),
  size: {
    s14: 14,
    s16: 16,
    s20: 20,
    s30: 30,
  },
  card: {
    shadowRadius: 5,
    shadowOpacity: 0.3,
    elevation: 5,
    borderRadius: 5,
  },
  colors: {
    ...appTheme.colors,
    background: 'white',
    primary: '#1DD6FF',
    secondary: '#7B91FF',
    yellow: '#FFDE01',
    green: '#00C48C',
    red: '#FF8087',
    shadedBox: '#efefef',
    navBar: 'white',
    colorTitleSection: '#555',
    colorParagraphItem: '#777',
    courierTextColor: '#76848b',
    text: '#4C4E51',
    textGrey: '#BEBEBE',
    textGrey2: '#86898D',
    buttonGrey: '#EAEAEA',
    backgroudGrey: '#F5F5F5',
    lightBlue: '#D2F7FF',
    softBlue: '#F5F8FF',
  },
  gradients: {
    ...appTheme.gradients,
    main: ['#1DD6FF', '#1DD6FF'],
    admin: ['#EFF5F5', '#CAF5FF'],
  },
  linearGradient: {
    flex: 1,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1DD6FF',
    borderRadius: responsiveHeight(4),
    width: '70%',
    maxWidth: 500,
    alignSelf: 'center',
    marginTop: responsiveHeight(8),
    elevation: responsiveHeight(0.5),
    shadowRadius: responsiveHeight(1),
    shadowOpacity: 0.2,
  },
  buttonOutline: {
    backgroundColor: 'white',
    borderWidth: 2,
    justifyContent: 'center',
    borderColor: '#1DD6FF',
    borderRadius: responsiveHeight(4),
    width: '70%',
    maxWidth: 500,
    alignSelf: 'center',
    marginTop: responsiveHeight(2),
    elevation: responsiveHeight(0.5),
    shadowRadius: responsiveHeight(1),
    shadowOpacity: 0.2,
  },
  labelButton: {
    color: '#4C4E51',
    fontSize: 18,
    fontFamily: 'Obviously-Bold',
    // textAlign: 'center'
  },
  body1: {
    textAlign: 'center',
    alignSelf: 'center',
    maxWidth: 400,
    marginVertical: responsiveHeight(2),
    fontSize: responsiveHeight(2.2),
    fontFamily: 'Poppins-Regular',
  },
  body2: {
    maxWidth: 400,
    fontSize: responsiveHeight(2),
    fontFamily: 'Poppins-Regular',
  },
  caption: {
    fontSize: responsiveHeight(1.5),
    fontFamily: 'Poppins-Regular',
  },
  title1: {
    fontFamily: 'Obviously-Bold',
    fontSize: responsiveHeight(2.2),
    color: '#4C4E51',
  },
  title2: {
    fontFamily: 'Poppins-Medium',
    fontSize: responsiveHeight(3),
    alignSelf: 'center',
    color: '#4C4E51',
  },
  subtitle2: {
    fontFamily: 'Poppins-Medium',
    fontSize: responsiveHeight(3),
    color: '#4C4E51',
  },
};

export type AppTheme = typeof theme;
export const useAppTheme = () => useTheme<AppTheme>();

 */
