import {Tag} from '@components/Tag';
import {useTranslation} from 'react-i18next';
import {theme} from '@config/Theme';
import {ParcelStatus} from '@config/Parcel.status';

const colorByStatus = {
  [ParcelStatus.Created]: theme.colors.primary,
  [ParcelStatus.Cancelled]: theme.colors.error,
  [ParcelStatus.Dropping]: theme.colors.yellow,
  [ParcelStatus.InvalidCounter]: theme.colors.error,
  [ParcelStatus.DroppedOff]: theme.colors.success,
  [ParcelStatus.Collecting]: theme.colors.warning,
  [ParcelStatus.CollectingInvalidCounter]: theme.colors.error,
  [ParcelStatus.Collected]: theme.colors.success,
  [ParcelStatus.Expired]: theme.colors.error,
  [ParcelStatus.ExpiredReturning]: theme.colors.warning,
  [ParcelStatus.ExpiredReturned]: theme.colors.success,
  [ParcelStatus.ExpiredInvalidCounter]: theme.colors.error,
  [ParcelStatus.Unclaimed]: theme.colors.error,
};
export const StatusTagByRole = ({status}: {status: string}) => {
  const {t} = useTranslation();
  const color = colorByStatus[status as ParcelStatus];
  return <Tag text={t(status)} color={color ?? theme.colors.primary} />;
};
