import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {getBundleId, getVersion} from 'react-native-device-info';
import {Platform} from 'react-native';

interface Props {
  children: React.ReactNode;
  FallbackComponent: React.ComponentType<any>;
}

export const KErrorBoundary = ({children, FallbackComponent}: Props) => {
  const bundleId = getBundleId();
  const version = getVersion();
  const onError = (error: Error) => {
    //if (!__DEV__) {
      customAxios.post(Endpoints.app.error, {
        message: error.message,
        version,
        app: bundleId,
        platform: Platform.OS,
      });
    //}
  };

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};
