import {View} from 'react-native';
import React, {useState} from 'react';
import {Checkbox} from 'react-native-paper';
import {styles, ICheckBoxProps, CheckBoxStatus} from './CheckBox.presets';
import Text from '../text/Text';
import {theme} from '@config/Theme';

const {
  uikit: {
    form: {checkbox},
  },
} = theme;

export default function CheckBox(props: ICheckBoxProps) {
  const {defaultState = 'unchecked', label, name, onValue, disabled} = props;
  const [checkState, setCheckState] = useState<CheckBoxStatus>(defaultState);

  const changeState = () => {
    const isUnChecked = checkState === 'unchecked';
    let newCheckBoxState: CheckBoxStatus = isUnChecked
      ? 'checked'
      : 'unchecked';
    setCheckState(newCheckBoxState);
    onValue({[name]: isUnChecked});
  };

  return (
    <View style={styles.container}>
      <Checkbox.Android
        status={checkState}
        onPress={changeState}
        uncheckedColor={checkbox.uncheckedColor}
        color={checkbox.color}
        disabled={disabled}
      />
      <Text
        type="body"
        weight="regular"
        text={label}
        {...(disabled && {color: checkbox.disabled})}
      />
    </View>
  );
}
