import {Image, Linking, StatusBar, StyleSheet, View} from 'react-native';
import {BackTopBar} from '@components/BackTopBar';
import {theme} from '@config/Theme';
import {Text} from 'react-native-paper';
import {CustomButtom} from '@components/CustomButton';
import {TextButton} from '@components/TextButton';
import {Stack} from '@components/Stack';
import {MultiAppConfig} from '@config/MultiAppConfig';
import MaSaluda from '@assets/img/masaluda.png';

export const HelpPage = ({navigation, route}: any) => {
  const back = () => {
    navigation.goBack();
  };
  const whatsapp = () => {
    Linking.openURL(`https://wa.me/${MultiAppConfig.HELP_WHATSAPP}`);
  };
  const callPhone = () => {
    Linking.openURL(`tel:${MultiAppConfig.HELP_PHONE}`);
  };
  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <StatusBar barStyle="dark-content" />
      <BackTopBar text="Sobre Kanguro" back={back} />
      <View style={{paddingHorizontal: 20, marginTop: 50}}>
        <Text style={styles.title}>¿Cómo te podemos ayudar?</Text>
        <Text style={styles.text}>
          Contacta con nuestro equipo de soporte para poder ayudarte a resolver
          cualquier problema{' '}
        </Text>
      </View>
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'flex-end'}}>
        <Image source={MaSaluda} />
      </View>
      <View style={{flex: 0.4}}>
        <Stack spacing={3}>
          <CustomButtom onPress={whatsapp} text="Contactar por whatsapp" />
          <TextButton
            text="Contactar por teléfono"
            onPress={callPhone}
            color="gray"
          />
        </Stack>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: '900',
    fontFamily: 'Poppins-Bold',
    lineHeight: 36,
    textAlign: 'left',
    marginBottom: 20,
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Poppins-Regular',
    lineHeight: 24,
    textAlign: 'left',
    color: '#9796A1',
  },
});
