import {normalizeFont} from '@utils/normalize';
import {ReactNode} from 'react';
import {StyleSheet, TextProps} from 'react-native';

export interface ITextProps extends TextProps {
  text: string;
  type?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body'
    | 'body2'
    | 'caption'
    | 'caption2'
    | 'overline'
    | 'over';
  color?: string;
  weight?: 'regular' | 'medium' | 'bold';
  align?: 'left' | 'center' | 'right' | 'justify';
}

export const styles = (color: string) => {
  return StyleSheet.create({
    colorText: {
      color,
    },
    h1: {
      fontSize: normalizeFont(46),
    },
    h2: {
      fontSize: normalizeFont(34),
    },
    h3: {
      fontSize: normalizeFont(28),
    },
    h4: {
      fontSize: normalizeFont(24),
    },
    body: {
      fontSize: normalizeFont(22),
    },
    body2: {
      fontSize: normalizeFont(16),
    },
    caption: {
      fontSize: normalizeFont(18),
    },
    caption2: {
      fontSize: normalizeFont(12),
    },
    overline: {
      fontSize: normalizeFont(14),
    },
    over: {
      fontSize: normalizeFont(12),
    },
    bold: {
      fontFamily: 'Poppins-Bold',
    },
    medium: {
      fontWeight: '500',
      fontFamily: 'Poppins-Medium',
    },
    regular: {
      fontWeight: '400',
      fontFamily: 'Poppins-Regular',
    },
    left: {
      textAlign: 'left',
    },
    center: {
      textAlign: 'center',
    },
    right: {
      textAlign: 'right',
    },
    justify: {
      textAlign: 'justify',
    },
  });
};
