import {createContext} from 'react';

interface IAuthContext {
  userToken: string | null;
  user: null | any;
  isLoading: boolean;
  isSignout: boolean;
  isProfileCompleted: boolean;
  signIn: (data: any) => void;
  signInWithProfile: (token: string, data: any) => void;
  signOut: () => void;
  signUp: (data: any) => void;
  updateProfile: (data: any) => void;
}

export const AuthContext = createContext<IAuthContext>({
  userToken: 'null',
  user: null,
  isLoading: false,
  isSignout: false,
  isProfileCompleted: false,
  signIn: (_data: any): Promise<void> => new Promise<void>(() => {}),
  signInWithProfile: (_token: string, _data: any): Promise<void> =>
    new Promise<void>(() => {}),
  signOut: () => {},
  signUp: (_data: any) => {},
  updateProfile: (_data: any) => {},
});
