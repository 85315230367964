import React from 'react';
import {ActivityIndicator} from 'react-native-paper';
import {theme} from '@config/Theme';
import {ISpinnerProps, sizes} from './Spinner.presets';
import Text from '../text/Text';
import {View} from 'react-native';
import Divider from '../divider/Divider';

const {
  uikit: {spinner},
} = theme;

export default function Spinner(props: ISpinnerProps) {
  const {size = 'small'} = props;
  return (
    <View>
      <ActivityIndicator
        animating={true}
        color={spinner.color}
        size={sizes[size]}
      />
      <Divider />
      <Text text="Validando información" type="body2" />
    </View>
  );
}
