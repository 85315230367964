import {Image, StyleSheet, View} from 'react-native';
import DeliveredParcel from '@assets/img/delivered-parcel.png';
import {StatusTagByRole} from '@components/StatusTagByRole';

export const Status = ({status}: {status: string}) => {
  return (
    <View style={styles.firstRow}>
      <View style={styles.centeredRow}>
        <Image
          source={DeliveredParcel}
          style={{width: 50, height: 50, marginRight: 15}}
        />
        <StatusTagByRole status={status} />
      </View>
      <View></View>
    </View>
  );
};

const styles = StyleSheet.create({
  firstRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  centeredRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
});
