import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import { CurrentKanguroPointApiResponse } from '@resources/CurrentKanguroPoint.apiResponse';
import { CurrentKanguroPointViewModel } from '@viewModels/CurrentKanguroPoint.viewModel';

export const ListCurrentKanguroPointsResource = {
  list:
    ({latitude, longitude}: {latitude: number; longitude: number}) =>
      async () => {
        const res = await customAxios.get<{
          count: number;
          items: Array<CurrentKanguroPointApiResponse>;
        }>(
          Endpoints.parcels.currentByKanguroPoint +
          '?pageNumber=0&pageSize=10' +
          `&latitude=${latitude}&longitude=${longitude}`,
        );
        return res.data.items.map(i => new CurrentKanguroPointViewModel(i));
      },
};
