import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';

interface RequestCodeData {
  phone: string;
}

interface VerifyData {
  requestId: string;
  verificationCode: string;
}

export const VerifyResource = {
  requestCode: (phone: string) => {
    const data: RequestCodeData = {
      phone,
    };
    return customAxios.post(Endpoints.auth.request_verify, data);
  },
  verify: (data: VerifyData) => {
    return customAxios.post(Endpoints.auth.verify, data);
  },
};
