import {Text} from 'react-native-paper';

export const BodyGray = ({children, ...rest}: any) => {
  return (
    <Text
      variant="bodyMedium"
      {...rest}
      style={{
        color: '#9B9B9B',
        ...rest.style,
        fontFamily: 'Poppins-Regular',
      }}>
      {children}
    </Text>
  );
};
