import {customAxios} from '@infrastructure/api/CustomAxios';
import {Endpoints} from '@config/Endpoints';
import {EditProfileApiRequest} from './EditProfile.apiRequest';

export const EditProfileResource = {
  save: (data: EditProfileApiRequest) => {
    return customAxios.put(Endpoints.profile.me, data);
  },
  upload: (data: FormData) => {
    return customAxios.post(Endpoints.upload.profile, data);
  },
};
