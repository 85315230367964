import {Appbar} from 'react-native-paper';
import {theme} from '../../config/Theme';
import Logo from '../../assets/img/logo-black.svg';
import {Dimensions, StatusBar, View} from 'react-native';

const {width} = Dimensions.get('window');
export const TopBar = () => {
  return (
    <>
      <StatusBar barStyle="dark-content" />
      <Appbar.Header
        style={{backgroundColor: theme.colors.background, elevation: 0}}>
        {/*<Appbar.BackAction onPress={() => {}} />*/}
        <Appbar.Content title="" />
        {/*<Appbar.Action icon="bell" onPress={() => {}} />*/}
        <View
          style={{
            justifyContent: 'center',
            flex: 1,
            width,
            transform: [{translateX: -65}],
          }}>
          <Logo width={130} />
        </View>
      </Appbar.Header>
    </>
  );
};
