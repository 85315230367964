import {InvitationFeature} from '@modules/auth/features/invitation/Invitation.feature';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {getInvitationId} from "@utils/url";

type Props = NativeStackScreenProps<any, 'Invitation'>;
export const InvitationPage = ({route}: Props) => {
  const invitationId = getInvitationId()!;
  return (
    <>
      <InvitationFeature invitationId={invitationId}/>
    </>
  );
};
