import React from 'react';
import {StatusBar, StyleSheet, View} from 'react-native';
import {H1Text} from '@components/texts/H1.text';
import DeviceInfo from 'react-native-device-info';
import {BodyGray} from '@components/texts/BodyGray';
import {theme} from '@config/Theme';
import {Greeting} from '@components/Greeting';
import {BackHeader} from '@components/BackHeader';

interface Props {
  back?: {
    onPress: () => void;
    title?: string;
  };
  title?: string;
  subtitle?: string;
  greeting?: boolean;
  backgroundColor?: string;
  children?: React.ReactNode;
}

export const Header = ({
                         back,
                         title,
                         subtitle,
                         greeting,
                         backgroundColor,
                         children,
                       }: Props) => {
  const hasNotch = DeviceInfo.hasNotch();
  return (
    <>
      <StatusBar backgroundColor={theme.colors.primary} />
      <View style={styles.topContainer}>
        <View
          style={[
            styles.backgroundContainer,
            {
              backgroundColor: backgroundColor ?? 'white',
              height: hasNotch ? 60 : 30,
            },
          ]}>
          <View style={styles.bottomBackground} />
        </View>
        <View
          style={[
            styles.middleContainer,
            {
              backgroundColor: backgroundColor ?? 'white',
              marginTop: hasNotch ? 60 : 30,
            },
          ]}>
          {!!back && <BackHeader title={back.title} back={back.onPress} />}
          <View style={{gap: 20, marginTop: 20}}>
            {!!greeting && <Greeting />}
            <View style={{gap: 20, paddingBottom: 10, paddingLeft: 20}}>
              {!!title && <H1Text>{title}</H1Text>}
              {!!subtitle && (
                <BodyGray style={styles.subtitle}>{subtitle}</BodyGray>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  topContainer: {
    backgroundColor: theme.colors.primary, //'#1DD6FF',
    margin: 0,
    borderBottomLeftRadius: 90,
  },
  middleContainer: {
    borderTopRightRadius: 90,
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  bottomBackground: {
    flex: 1,
    backgroundColor: theme.colors.primary, //'#1DD6FF',
    borderBottomLeftRadius: 90,
  },
  subtitle: {
    padding: 20,
  },
});
