import {View} from 'react-native';
import React, {useState, useEffect} from 'react';
import {RadioButton as PRadioButton} from 'react-native-paper';
import {styles, IRadioButtonProps} from './RadioButton.presets';
import Text from '../text/Text';
import {theme} from '@config/Theme';

const {
  uikit: {
    form: {radio},
  },
} = theme;

export default function RadioButton(props: IRadioButtonProps) {
  const {data, defaultValue, onValue, side = 'right', disabled = false} = props;
  const [valueSelected, setValue] = useState(defaultValue);

  const stylesRadio = styles(side);

  useEffect(() => {
    if (onValue) {
      onValue(valueSelected);
    }
  }, [valueSelected]);

  return (
    <PRadioButton.Group
      onValueChange={selectedValue => {
        setValue(selectedValue);
      }}
      value={valueSelected}>
      {data.map(({value, label}, index) => {
        return (
          <View style={stylesRadio.RadioContainer} key={index}>
            <Text type="body" weight="regular" text={label} />
            <PRadioButton.Android
              value={value}
              disabled={disabled}
              uncheckedColor={radio.uncheckedColor}
              color={radio.color}
            />
          </View>
        );
      })}
    </PRadioButton.Group>
  );
}
