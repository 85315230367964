import React, {createContext, useContext, useRef} from 'react';

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface LocationContextType {
  updateCoordinates: (latitude: number, longitude: number) => void;
  getCoordinates: () => Coordinates;
}

const LocationContext = createContext<LocationContextType | undefined>(
  undefined,
);

export const GeoLocationProvider = ({children}: any) => {
  const coordinatesRef = useRef<Coordinates>({latitude: 0, longitude: 0});

  const updateCoordinates = (latitude: number, longitude: number) => {
    coordinatesRef.current = {latitude, longitude};
  };

  const getCoordinates = () => {
    return coordinatesRef.current;
  };

  return (
    <LocationContext.Provider value={{updateCoordinates, getCoordinates}}>
      {children}
    </LocationContext.Provider>
  );
};

export const useGeoLocation = (): LocationContextType => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};
