import {DistanceApiResponse} from "@resources/Distance.apiResponse";

export class DistanceViewModel {
  unitOfMeasure: string;
  value: number;

  constructor(distance: DistanceApiResponse) {
    this.unitOfMeasure = distance.unitOfMeasure;
    this.value = distance.value;
  }
}
