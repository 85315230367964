import {SafeAreaView, StatusBar} from 'react-native';
import {theme} from '@config/Theme';
import React from 'react';
import {ErrorFallback} from '@components/ErrorFallback';
import {ErrorBoundary} from 'react-error-boundary';
import {DeliveryDetailFeature} from '@modules/common/features/delivery-detail/DeliveryDetail.feature';
import {useTranslation} from 'react-i18next';
import {BackHeader} from '@components/BackHeader';

export const DeliveryDetailPage = ({navigation, route}: any) => {
  const {t} = useTranslation('common');
  const back = () => {
    navigation.goBack();
  };
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: theme.colors.background}}>
      <StatusBar barStyle="dark-content" />
      <BackHeader title={t('deliveryDetail_page_title')!} back={back} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DeliveryDetailFeature id={route.params.id} />
      </ErrorBoundary>
    </SafeAreaView>
  );
};
