import {useMemo} from 'react';
import DefaultImage from '@assets/new/img/placeholder.png';
import {KanguroPointViewModel} from '@viewModels/KanguroPoint.viewModel';

export const useKpMainImage = (kanguroPoint?: KanguroPointViewModel | null) => {
  return useMemo(() => {
    if (!kanguroPoint) {
      return DefaultImage;
    }
    if (
      kanguroPoint.attachments.filter(attachment => attachment.type === 'MAIN')
        .length
    ) {
      return {
        uri: kanguroPoint.attachments.filter(
          attachment => attachment.type === 'MAIN',
        )[0].url,
      };
    }
    return DefaultImage;
  }, [kanguroPoint]);
};
