import {useEffect, useRef} from 'react';
import {Animated, View} from 'react-native';
import Onomatopeia from '@assets/img/high-five/onomatopeya.png';
import HighFiveLeft from '@assets/img/high-five/highFiveLeft.png';
import HighFiveRight from '@assets/img/high-five/highFiveRight.png';

export const Highfive = () => {
  const scaleValue = useRef(new Animated.Value(0)).current;
  const onomatopeiaScaleValue = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(scaleValue, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
    setTimeout(() => {
      Animated.timing(onomatopeiaScaleValue, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start();
    }, 500);
  }, []);

  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <Animated.Image
        source={Onomatopeia}
        style={{
          width: 200,
          top: 70,
          transform: [
            {scale: onomatopeiaScaleValue},
            {
              translateY: scaleValue.interpolate({
                inputRange: [0, 1],
                outputRange: [50, 0],
              }),
            },
          ],
        }}
        resizeMode="contain"
      />
      <Animated.Image
        source={HighFiveLeft}
        style={{
          width: 200,
          transform: [
            {
              translateX: scaleValue.interpolate({
                inputRange: [0, 1],
                outputRange: [-200, -100],
              }),
            },
            {translateY: 65},
          ],
        }}
      />
      <Animated.Image
        source={HighFiveRight}
        style={{
          width: 200,
          transform: [
            {
              translateX: scaleValue.interpolate({
                inputRange: [0, 1],
                outputRange: [250, 110],
              }),
            },
            {translateY: -60},
          ],
        }}
      />
    </View>
  );
};
