import React, {useEffect} from "react";

import {IconButton, Text} from "react-native-paper";
import {StyleSheet, View} from "react-native";
import {LinePoints, useSignature} from "@components/signature/Signature.provider";

interface Props {
  getSignatureFile: (file: File | null, signaturePoints: Array<LinePoints>) => void;
}

export function Signature({getSignatureFile}: Props) {

  const {
    canvasRef,
    prepareCanvas,
    startDrawingMobile,
    finishDrawing,
    drawMobile,
    clearCanvas,
    file,
    signaturePoints,
  } = useSignature();

  useEffect(() => {
    prepareCanvas();
  }, []);

  useEffect(() => {
    getSignatureFile(file, signaturePoints);
  }, [file])

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Firma con el dedo</Text>
        <IconButton icon="refresh" onPress={clearCanvas} size={20} />
      </View>
      <canvas
        onTouchStart={startDrawingMobile}
        onTouchEnd={finishDrawing}
        onTouchMove={drawMobile}
        ref={canvasRef}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    border: '1px solid #EEE',
    background: '#FCFCFF',
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    backgroundColor: '#F5F5F5',
  },
  title: {
    marginTop: 10,
    fontSize: 16,
  },
})
