import {View} from 'react-native';
import React from 'react';
import {IconsProps, getIcon, styles} from './Icons.presets';

export default function Icons(props: IconsProps) {
  const {position} = props;
  const {containerLeft, containerRight} = styles;
  let marginIcon = {};
  if (position) {
    marginIcon = position === 'left' ? containerRight : containerLeft;
  }

  return <View style={marginIcon}>{getIcon(props)}</View>;
}
